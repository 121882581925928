import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../components/Buttons/DefaultButton';
import AvatarRenderer from '../../components/AvatarRenderer';
import getContrastColor from '../../utils/colors/getContrastColor';

import { changeAvatarHorns, changeAvatarWings, changeAvatarAppearanceTypeSimple } from '../../store/actions/createAvatar';
import { openModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		appearance: s.createAvatarManager.appearance,
		wearable: s.createAvatarManager.wearable,
		gender: s.createAvatarManager.gender,
		race: s.createAvatarManager.race,
	}),
	{
		openModal,
		changeAvatarHorns,
		changeAvatarWings,
		changeAvatarAppearanceTypeSimple,
	}
)(({ appearance, wearable, gender, race, openModal, changeAvatarHorns, changeAvatarWings, changeAvatarAppearanceTypeSimple }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={classNames({ container: true, 'create-avatar-horns-wings-tail': true })}>
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'avatar-wrapper': true })}>
					<AvatarRenderer
						appearance={appearance}
						withUnderwear={true}
						wearable={wearable}
						gender={gender}
						race={race}
					/>
				</div>
				<div className={classNames({ 'settings-wrapper': true })}>
					<div className={classNames({ 'pick-part': true })}>
						<span className={classNames({ title: true })}>{t('create-avatar-horns-wings-tail.pick-horns-color')}</span>
						<div className={classNames({ type: true })}>
							<DefaultButton
								fontSize={12}
								background={appearance.horns.color}
								color={getContrastColor(appearance.horns.color)}
								text={appearance.horns.color}
								onClick={() =>
									openModal({
										name: 'color-picker',
										payload: {
											Color: appearance.horns.color,
											SetColor: (color) => changeAvatarHorns({ name: 'color', value: color }),
											withoutBackground: true,
											withoutPadding: true,
										},
									})
								}
							/>
						</div>
					</div>
					<div className={classNames({ 'pick-part': true })}>
						<span className={classNames({ title: true })}>{t('create-avatar-horns-wings-tail.pick-wings-color')}</span>
						<div className={classNames({ type: true })}>
							<DefaultButton
								fontSize={12}
								background={appearance.wings.color}
								color={getContrastColor(appearance.wings.color)}
								text={appearance.wings.color}
								onClick={() =>
									openModal({
										name: 'color-picker',
										payload: {
											Color: appearance.wings.color,
											SetColor: (color) => changeAvatarWings({ name: 'color', value: color }),
											withoutBackground: true,
											withoutPadding: true,
										},
									})
								}
							/>
						</div>
					</div>
					<div className={classNames({ 'pick-part': true })}>
						<span className={classNames({ title: true })}>{t('create-avatar-horns-wings-tail.pick-tail')}</span>
						<div className={classNames({ type: true })}>
							<DefaultButton
								fontSize={12}
								type={'primary'}
								text={t('create-avatar-horns-wings-tail.type', { type: appearance.tail + 1 })}
								onClick={() =>
									openModal({
										name: 'type-picker',
										payload: {
											title: t('create-avatar-horns-wings-tail.modals.tail'),
											types: [0, 1, 2].map((type) => ({
												name: t('create-avatar-horns-wings-tail.type', { type: type + 1 }),
												value: type,
											})),
											type: appearance.tail,
											setType: (type) => changeAvatarAppearanceTypeSimple({ name: 'tail', value: type }),
											withoutBackground: true,
											withoutPadding: true,
										},
									})
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={classNames({ 'bottom-buttons': true })}>
				<DefaultButton
					type={'secondary'}
					text={t('create-avatar-horns-wings-tail.buttons.back')}
					onClick={() =>
						gender === 'male' || race === 'dwarve'
							? navigate('/create-avatar-beard')
							: navigate('/create-avatar-eyebrows-eyes-mouth')
					}
				/>
				<DefaultButton
					text={t('create-avatar-horns-wings-tail.buttons.next')}
					onClick={() => navigate('/create-avatar-wearable-body')}
				/>
			</div>
		</div>
	);
});
