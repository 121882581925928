import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultProgressBar from '../../components/ProgressBars/DefaultProgressBar';
import LevelProgressBar from '../../components/ProgressBars/LevelProgressBar';
import DefaultButton from '../../components/Buttons/DefaultButton';
import AvatarRenderer from '../../components/AvatarRenderer';
import PlusIcon from '../../components/Icons/PlusIcon';
import MainHeader from '../../components/MainHeader';
import MainNavbar from '../../components/MainNavbar';

import { openModal, closeModal } from '../../store/actions/modals';
import { upgradeAvatarLevel } from '../../store/actions/avatar';

export default connect(
	(s) => ({
		experience: s.avatarManager.state.experience,
		appearance: s.avatarManager.state.appearance,
		wearable: s.avatarManager.state.wearable,
		stamina: s.avatarManager.state.stamina,
		gender: s.avatarManager.state.gender,
		health: s.avatarManager.state.health,
		level: s.avatarManager.state.level,
		mana: s.avatarManager.state.mana,
		race: s.avatarManager.state.race,
		modalName: s.modals.name,
	}),
	{ openModal, closeModal, upgradeAvatarLevel }
)(
	({
		experience,
		appearance,
		modalName,
		wearable,
		stamina,
		gender,
		health,
		level,
		mana,
		race,
		openModal,
		closeModal,
		upgradeAvatarLevel,
	}) => {
		const { t } = useTranslation();
		const navigate = useNavigate();

		React.useEffect(() => {
			if (modalName == 'confirm-level-upgrade') closeModal();
		}, [level, experience]);

		return (
			<div className={classNames({ container: true, 'avatar-info': true })}>
				<MainHeader title={t('avatar-info.header.title')} />
				<div className={classNames({ content: true })}>
					<div className={classNames({ 'avatar-wrapper': true })}>
						<AvatarRenderer
							appearance={appearance}
							withWearable={true}
							wearable={wearable}
							gender={gender}
							race={race}
						/>
					</div>
					<div className={classNames({ 'level-progress-wrapper': true })}>
						<LevelProgressBar
							name={t('avatar-info.level')}
							maximum={experience.maximum}
							current={experience.current}
							frontColor={'#FF9900'}
							backColor={'#965A00'}
							level={level}
							fontSize={14}
						/>
						{experience.current >= experience.maximum ? (
							<div
								className={classNames({ plus: true })}
								onClick={() =>
									openModal({
										name: 'confirm-level-upgrade',
										payload: {
											type: 'center',
											skill: 'avatar',
											price: Math.round(experience.maximum * 0.2),
											onConfirm: () => upgradeAvatarLevel(),
										},
									})
								}
							>
								<PlusIcon
									width={24}
									height={24}
									fill={'#FF9900'}
								/>
							</div>
						) : null}
					</div>
					<div className={classNames({ 'stats-wrapper': true })}>
						<DefaultProgressBar
							name={t('avatar-info.health')}
							maximum={health.maximum}
							current={health.current}
							frontColor={'#009933'}
							backColor={'#00551C'}
							fontSize={14}
						/>
						<DefaultProgressBar
							name={t('avatar-info.stamina')}
							maximum={stamina.maximum}
							current={stamina.current}
							frontColor={'#3399FF'}
							backColor={'#0058AF'}
							fontSize={14}
						/>
						<DefaultProgressBar
							name={t('avatar-info.mana')}
							maximum={mana.maximum}
							current={mana.current}
							frontColor={'#FF0066'}
							backColor={'#8D0038'}
							fontSize={14}
						/>
					</div>
					<div className={classNames({ 'skills-button-wrapper': true })}>
						<DefaultButton
							onClick={() => navigate('/avatar-skills')}
							text={t('avatar-info.buttons.skills')}
							type={'primary'}
							fontSize={20}
							height={40}
						/>
					</div>
				</div>
				<MainNavbar openModal={openModal} />
			</div>
		);
	}
);
