import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import {
	getBankDeposits,
	getBankDepositsAsync,
	bankDepositFunds,
	bankDepositFundsAsync,
	bankWithdrawFunds,
	bankWithdrawFundsAsync,
	clearBankError,
	clearBankErrorAsync,
} from '../../actions/bank';

import bankApi from '../../../services/api/bank';

function clearErrorWorker() {
	return {};
}

export function* bankSaga() {
	yield takeEvery(getBankDeposits, bindAsyncActions(getBankDepositsAsync)(bankApi.getBankDepositsEndpoint));
	yield takeEvery(bankDepositFunds, bindAsyncActions(bankDepositFundsAsync)(bankApi.bankDepositFundsEndpoint));
	yield takeEvery(bankWithdrawFunds, bindAsyncActions(bankWithdrawFundsAsync)(bankApi.bankWithdrawFundsEndpoint));
	yield takeEvery(clearBankError, bindAsyncActions(clearBankErrorAsync)(clearErrorWorker));
}
