import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../components/Buttons/DefaultButton';
import MainHeader from '../../components/MainHeader';
import MainNavbar from '../../components/MainNavbar';

import { makeGuesthouseRest } from '../../store/actions/guesthouse';
import { openModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		restEndsAt: s.guesthouseManager.restEndsAt,
	}),
	{
		openModal,
		makeGuesthouseRest,
	}
)(({ restEndsAt, openModal, makeGuesthouseRest }) => {
	const [RestEndsAt, SetRestEndsAt] = React.useState('00:00');
	const RestEndsAtInterval = React.useRef();
	const { t } = useTranslation();

	const updateRestEndsAt = () => {
		const timeDifferenceInMinutes = (new Date(restEndsAt).getTime() - new Date().getTime()) / 1000 / 60;

		let hours = Math.floor(timeDifferenceInMinutes / 60);

		if (hours < 10) hours = `0${hours}`;

		let minutes = Math.floor(timeDifferenceInMinutes % 60);

		if (minutes < 10) minutes = `0${minutes}`;

		SetRestEndsAt(`${hours}:${minutes}`);
	};

	React.useEffect(() => {
		if (restEndsAt) {
			console.log(restEndsAt);

			updateRestEndsAt();

			RestEndsAtInterval.current = setInterval(() => {
				updateRestEndsAt();
			}, 60 * 1000);
		}

		return () => {
			clearInterval(RestEndsAtInterval.current);
		};
	}, [restEndsAt]);

	return (
		<div className={classNames({ container: true, guesthouse: true })}>
			<MainHeader title={t('guesthouse.header.title')} />
			<div className={classNames({ content: true })}>
				<img
					className={classNames({ 'guesthouse-image': true })}
					src={'https://public.synergy-hero.com/v2/buildings/inn.png'}
				/>
				<DefaultButton
					onClick={() => openModal({ name: 'guesthouse-chest', payload: { type: 'full' } })}
					text={t('guesthouse.buttons.open-chest')}
					fontSize={20}
					height={50}
				/>
				<DefaultButton
					text={
						restEndsAt && restEndsAt > new Date()
							? t('guesthouse.buttons.resting', { time: RestEndsAt })
							: t('guesthouse.buttons.rest')
					}
					onClick={() =>
						restEndsAt && restEndsAt > new Date()
							? null
							: openModal({ name: 'confirm-rest', payload: { type: 'center', makeGuesthouseRest } })
					}
					disabled={restEndsAt > new Date()}
					fontSize={20}
					height={50}
				/>
			</div>
			<MainNavbar openModal={openModal} />
		</div>
	);
});
