import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import { changeAppLanguage, changeAppLanguageAsync } from '../../actions/settings';

function changeAppLanguageWorker(language) {
	return { language };
}

export function* settingsSaga() {
	yield takeEvery(changeAppLanguage, bindAsyncActions(changeAppLanguageAsync)(changeAppLanguageWorker));
}
