import axios from 'axios';

import { api_url } from '../../../common/config';

axios.defaults.withCredentials = true;

export default class {
	static getAvatarGuestHouseEndpoint = () => axios.get(`${api_url}guesthouse`);
	static makeGuesthouseRestEndpoint = (data) => axios.post(`${api_url}guesthouse/rest`, data);
	static guesthouseChestMoveEndpoint = (data) => axios.post(`${api_url}guesthouse/chest/move`, data);
}
