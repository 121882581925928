import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			preserveAspectRatio='xMidYMid meet'
		>
			<g
				transform='translate(0, 512) scale(0.100000,-0.100000)'
				fill={fill}
				stroke='none'
			>
				<path
					d='M3302 4857 c-83 -84 -152 -157 -152 -162 0 -13 92 -105 105 -105 6 0
			80 70 165 155 l155 155 -55 55 c-30 30 -57 55 -60 55 -3 0 -74 -69 -158 -153z'
				/>
				<path
					d='M2300 4949 c-65 -29 -1050 -1012 -1085 -1083 -34 -68 -34 -144 0
			-212 36 -73 541 -575 609 -605 l49 -21 -56 -56 -57 -56 -143 142 c-178 176
			-178 176 -277 74 -89 -91 -114 -144 -116 -251 l-2 -84 -35 -12 c-54 -19 -80
			-41 -293 -257 -252 -255 -249 -250 -249 -448 0 -162 10 -195 82 -280 l36 -41
			-39 -40 -38 -39 -117 0 c-97 0 -120 -3 -137 -17 -24 -21 -272 -597 -272 -632
			0 -36 196 -231 232 -231 36 0 609 247 631 272 14 17 17 40 17 137 l0 117 39
			38 40 39 39 -32 c22 -17 67 -43 99 -58 51 -24 73 -28 183 -31 l125 -4 587
			-586 588 -587 57 58 58 57 -610 610 -610 610 -135 0 c-190 0 -184 -4 -457 271
			-122 123 -227 237 -233 252 -15 42 -13 202 4 240 8 18 106 123 218 235 271
			268 229 266 478 17 102 -102 190 -185 195 -185 13 0 105 92 105 105 0 5 -94
			104 -209 220 -178 178 -210 216 -216 248 -10 51 1 102 30 134 l24 28 361 -360
			360 -361 0 -279 0 -280 810 -810 810 -810 57 58 58 57 -787 788 -787 787 -3
			265 c-3 250 -4 267 -24 301 l-22 36 104 104 c64 63 114 123 129 153 34 68 34
			144 0 212 -34 68 -225 256 -290 285 l-48 21 76 76 77 77 242 -240 243 -240
			-191 -195 c-203 -207 -224 -238 -224 -330 0 -106 75 -207 177 -237 l59 -17 17
			-59 c24 -80 84 -140 164 -164 l59 -17 17 -59 c34 -115 144 -186 267 -174 56 6
			58 6 64 -19 10 -39 89 -110 143 -128 55 -19 101 -20 155 -4 l39 12 675 -675
			674 -674 57 58 58 57 -670 670 -669 669 110 113 c117 119 144 167 144 248 0
			106 -75 207 -177 237 l-59 17 -17 59 c-24 80 -84 140 -164 164 l-59 17 -17 59
			c-24 80 -84 140 -164 164 -59 17 -59 17 -77 72 -9 31 -19 61 -22 66 -2 6 15
			20 38 32 24 11 64 39 89 61 l45 40 93 -154 c51 -85 95 -153 99 -151 4 2 35 21
			69 41 l61 38 -120 201 c-66 111 -129 210 -140 220 -42 36 -72 23 -171 -71 -94
			-89 -138 -113 -203 -111 -37 1 -97 22 -101 36 -6 17 315 342 359 364 44 23 87
			24 131 5 18 -8 172 -154 342 -325 l310 -311 5 -298 c4 -263 7 -301 23 -332 11
			-19 195 -212 410 -427 l392 -393 52 52 c29 29 53 57 53 63 0 5 -172 182 -382
			392 l-383 383 -5 298 -5 297 -27 45 c-14 25 -170 187 -345 361 -362 359 -366
			362 -498 363 -68 1 -88 -3 -135 -26 -30 -15 -68 -37 -83 -50 l-28 -22 -182
			182 -182 182 167 167 c184 185 208 221 208 313 0 98 -18 122 -308 414 -294
			295 -321 316 -422 316 -34 0 -73 -8 -100 -21z m402 -406 c324 -324 312 -295
			183 -423 l-85 -85 -302 303 -303 302 85 85 c128 129 99 141 422 -182z m-317
			-323 l300 -300 -223 -222 -222 -223 -302 302 -303 303 220 220 c121 121 222
			220 225 220 3 0 140 -135 305 -300z m-560 -560 l300 -300 -85 -85 c-128 -129
			-99 -141 -422 182 -323 323 -311 295 -186 420 46 46 85 83 88 83 3 0 140 -135
			305 -300z m1003 -203 l182 -182 -103 -103 -102 -102 -185 185 -185 185 100
			100 c55 55 102 100 105 100 3 0 87 -82 188 -183z m-566 -554 c145 -144 145
			-142 3 -283 l-105 -105 -142 143 -143 142 105 105 c141 142 138 142 282 -2z
			m759 -202 c20 -21 29 -39 29 -62 0 -30 -20 -53 -188 -221 -168 -168 -191 -188
			-221 -188 -45 0 -91 46 -91 91 0 30 20 53 188 221 168 168 191 188 221 188 23
			0 41 -9 62 -29z m240 -240 c20 -21 29 -39 29 -62 0 -30 -20 -53 -188 -221
			-168 -168 -191 -188 -221 -188 -45 0 -91 46 -91 91 0 30 20 53 188 221 168
			168 191 188 221 188 23 0 41 -9 62 -29z m240 -240 c20 -21 29 -39 29 -62 0
			-30 -20 -53 -188 -221 -168 -168 -191 -188 -221 -188 -45 0 -91 46 -91 91 0
			30 20 53 188 221 168 168 191 188 221 188 23 0 41 -9 62 -29z m240 -240 c20
			-21 29 -39 29 -62 0 -29 -17 -51 -148 -181 -130 -131 -152 -148 -181 -148 -45
			0 -91 46 -91 91 0 29 17 51 148 181 130 131 152 148 181 148 23 0 41 -9 62
			-29z m-2796 -401 l59 -59 -62 -65 -62 -64 0 -110 0 -110 -224 -96 c-123 -53
			-228 -96 -232 -96 -11 0 -84 72 -84 83 0 5 43 110 96 233 l96 224 110 0 110 0
			61 60 c34 33 64 60 67 60 3 0 32 -27 65 -60z'
				/>
				<path
					d='M3542 4537 c-127 -127 -232 -237 -232 -242 0 -13 92 -105 105 -105 6
			0 116 106 245 235 l235 235 -55 55 c-30 30 -57 55 -60 55 -3 0 -110 -105 -238
			-233z'
				/>
				<path
					d='M3622 4057 c-127 -127 -232 -237 -232 -242 0 -13 92 -105 105 -105 6
			0 116 106 245 235 l235 235 -55 55 c-30 30 -57 55 -60 55 -3 0 -110 -105 -238
			-233z'
				/>
				<path
					d='M718 3920 l-67 -40 120 -201 c117 -194 122 -200 142 -186 12 7 44 26
			70 42 45 26 47 29 34 49 -7 11 -58 95 -112 186 -54 91 -103 171 -109 177 -9
			10 -26 4 -78 -27z'
				/>
				<path
					d='M257 3513 c-8 -14 -30 -144 -25 -149 3 -2 91 -22 197 -43 133 -27
			194 -35 197 -27 5 16 32 146 29 146 -3 0 -350 70 -372 75 -12 3 -24 2 -26 -2z'
				/>
				<path
					d='M4640 3282 l0 -80 37 -7 c48 -9 109 -71 119 -120 l6 -35 80 0 80 0
			-7 48 c-8 59 -59 155 -98 188 -45 38 -117 71 -170 79 l-47 7 0 -80z'
				/>
				<path
					d='M4320 3044 l0 -79 51 -12 c90 -22 160 -92 182 -182 l12 -51 79 0 79
			0 -7 53 c-9 74 -66 186 -122 236 -55 52 -151 98 -221 107 l-53 7 0 -79z'
				/>
				<path
					d='M566 2995 c-103 -62 -192 -117 -196 -122 -5 -4 10 -38 31 -75 l40
			-66 200 120 199 119 -27 50 c-57 100 -33 103 -247 -26z'
				/>
				<path
					d='M1281 1039 c-12 -39 -21 -72 -19 -73 5 -5 222 -76 230 -76 13 0 52
			140 42 149 -11 11 -193 71 -215 71 -10 0 -23 -23 -38 -71z'
				/>
				<path
					d='M1357 722 c-26 -26 -47 -52 -47 -58 0 -5 34 -43 75 -84 l75 -75 57
			58 58 57 -75 75 c-41 41 -79 75 -85 75 -6 0 -32 -22 -58 -48z'
				/>
				<path
					d='M346 704 c-70 -22 -149 -103 -170 -174 -34 -113 -11 -206 68 -286 89
			-88 45 -84 918 -84 l758 0 0 80 0 80 -765 2 -765 3 -32 33 c-28 28 -33 39 -33
			82 0 43 5 54 33 82 l32 33 125 3 125 4 0 79 0 79 -122 -1 c-79 0 -140 -6 -172
			-15z'
				/>
				<path
					d='M957 642 c-26 -26 -47 -52 -47 -58 0 -5 34 -43 75 -84 l75 -75 57 58
			58 57 -75 75 c-41 41 -79 75 -85 75 -6 0 -32 -22 -58 -48z'
				/>
			</g>
		</svg>
	);
};
