import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import InverseLogoIcon from '../../components/Icons/InverseLogoIcon';

export default connect(
	(s) => ({
		user: s.userManager.state,
		avatar: s.avatarManager.state,
		validated: s.userManager.validated,
		whitelisted: s.userManager.whitelisted,
	}),
	{}
)(({ user, avatar, validated, whitelisted }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (validated === true && whitelisted === false) {
			navigate('/not-white-listed', { replace: true });
		} else if (validated === true && avatar) {
			navigate('/avatar-info', { replace: true });
		} else if (validated === true && user) {
			navigate('/welcome', { replace: true });
		}
	}, [whitelisted, validated, avatar, user]);

	return (
		<div className={classNames({ container: true, 'something-went-wrong': true })}>
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'icon-wrapper': true })}>
					<InverseLogoIcon
						width={220}
						height={220}
					/>
				</div>
				<span className={classNames({ title: true })}>{t('something-went-wrong-page.title')}</span>
				<span className={classNames({ description: true })}>{t('something-went-wrong-page.description')}</span>
			</div>
		</div>
	);
});
