import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			preserveAspectRatio='xMidYMid meet'
		>
			<g
				transform='translate(0,512) scale(0.100000,-0.100000)'
				fill={fill}
				stroke='none'
			>
				<path
					d='M460 5104 c-140 -30 -271 -121 -357 -246 -121 -178 -134 -399 -36
-598 35 -72 71 -110 847 -887 l811 -813 -811 -812 c-776 -778 -812 -816 -847
-888 -135 -274 -54 -587 195 -757 178 -121 399 -134 598 -36 72 35 110 71 888
847 l812 811 813 -811 c777 -776 815 -812 887 -847 196 -97 417 -85 594 33
253 170 335 484 199 760 -35 72 -71 110 -847 888 l-811 812 811 813 c776 777
812 815 847 887 135 274 54 587 -195 757 -178 120 -397 134 -598 37 -72 -35
-105 -66 -887 -848 l-813 -811 -812 811 c-778 776 -816 812 -888 847 -128 63
-264 81 -400 51z'
				/>
			</g>
		</svg>
	);
};
