import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			style={{
				shapeRendering: 'geometricPrecision',
				textRendering: 'geometricPrecision',
				imageRendering: 'optimizeQuality',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			}}
		>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.971 }}
					d='M 233.5,-0.5 C 248.167,-0.5 262.833,-0.5 277.5,-0.5C 369.213,10.5899 437.713,56.2566 483,136.5C 498.58,167.171 508.08,199.505 511.5,233.5C 511.5,248.167 511.5,262.833 511.5,277.5C 498.959,377.766 446.959,449.266 355.5,492C 330.316,502.296 304.316,508.796 277.5,511.5C 262.833,511.5 248.167,511.5 233.5,511.5C 133.234,498.959 61.734,446.959 19,355.5C 8.70393,330.316 2.20393,304.316 -0.5,277.5C -0.5,262.833 -0.5,248.167 -0.5,233.5C 12.0408,133.234 64.0408,61.734 155.5,19C 180.684,8.70393 206.684,2.20393 233.5,-0.5 Z M 243.5,39.5 C 331.713,38.6742 398.546,75.6742 444,150.5C 481.775,223.566 480.441,295.9 440,367.5C 388.696,444.229 316.863,478.062 224.5,469C 155.179,456.947 102.679,420.78 67,360.5C 29.2252,287.434 30.5586,215.1 71,143.5C 111.454,80.8446 168.954,46.1779 243.5,39.5 Z'
				/>
			</g>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.977 }}
					d='M 250.5,134.5 C 262.251,133.12 270.418,137.787 275,148.5C 275.5,176.831 275.667,205.165 275.5,233.5C 303.502,233.333 331.502,233.5 359.5,234C 373.991,239.786 378.157,249.952 372,264.5C 369.404,268.39 365.904,271.223 361.5,273C 332.87,274.45 304.203,274.95 275.5,274.5C 275.667,302.502 275.5,330.502 275,358.5C 270.086,370.541 261.253,375.041 248.5,372C 241.903,369.737 237.736,365.237 236,358.5C 235.5,330.502 235.333,302.502 235.5,274.5C 206.797,274.95 178.13,274.45 149.5,273C 136.071,265.707 132.905,255.207 140,241.5C 142.928,237.619 146.761,235.119 151.5,234C 179.498,233.5 207.498,233.333 235.5,233.5C 235.333,205.165 235.5,176.831 236,148.5C 238.749,141.583 243.582,136.917 250.5,134.5 Z'
				/>
			</g>
		</svg>
	);
};
