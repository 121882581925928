import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import RankProgressBar from '../../components/ProgressBars/RankProgressBar';
import DefaultButton from '../../components/Buttons/DefaultButton';
import RightArrowIcon from '../../components/Icons/RightArrowIcon';
import MainHeader from '../../components/MainHeader';
import MainNavbar from '../../components/MainNavbar';

import { getGuildTasks } from '../../store/actions/guild';
import { openModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		tasks: s.guildManager.tasks,
		avatar: s.avatarManager.state,
		validated: s.userManager.validated,
		whitelisted: s.userManager.whitelisted,
	}),
	{
		getGuildTasks,
		openModal,
	}
)(({ avatar, tasks, validated, whitelisted, getGuildTasks, openModal }) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (validated && whitelisted) {
			getGuildTasks();
		}
	}, [validated, whitelisted]);

	return (
		<div className={classNames({ container: true, tasks: true })}>
			<MainHeader title={t('guild.header.title')} />
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'rank-wrapper': true })}>
					<img
						className={classNames({ 'rank-image': true })}
						src={`https://public.synergy-hero.com/v2/guild-ranks/${avatar.guildRank.level}.png`}
					/>
					<RankProgressBar
						name={t(`guild.ranks.${avatar.guildRank.level}`)}
						current={parseInt(avatar.guildRank.experience.current)}
						maximum={parseInt(avatar.guildRank.experience.maximum)}
						frontColor={'#FF9900'}
						backColor={'#965A00'}
					/>
				</div>
				<div className={classNames({ 'actions-button': true })}>
					<DefaultButton
						onClick={() => navigate('/check-in')}
						text={t('guild.buttons.check-in')}
						type={'primary'}
						fontSize={20}
						height={50}
					/>
					<DefaultButton
						onClick={() => navigate('/referrals')}
						text={t('guild.buttons.invite')}
						type={'primary'}
						fontSize={20}
						height={50}
					/>
				</div>
				<div className={classNames({ tasks: true })}>
					<span className={classNames({ title: true })}>{t('guild.tasks')}</span>
					{tasks.map((v, i) => (
						<div
							onClick={() => navigate('/guild-task-info', { state: { task: v } })}
							className={classNames({ task: true })}
							key={i}
						>
							<img
								className={classNames({ 'task-image': true })}
								src={v.image}
							/>
							<span className={classNames({ 'task-name': true })}>{v.name[i18n.languages[0]]}</span>
							<RightArrowIcon
								stroke={'#ffffff'}
								height={17}
								width={19}
							/>
						</div>
					))}
				</div>
			</div>
			<MainNavbar openModal={openModal} />
		</div>
	);
});
