import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			style={{
				shapeRendering: 'geometricPrecision',
				textRendering: 'geometricPrecision',
				imageRendering: 'optimizeQuality',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			}}
		>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.972 }}
					d='M 234.5,-0.5 C 248.5,-0.5 262.5,-0.5 276.5,-0.5C 374.222,11.0886 445.055,60.7552 489,148.5C 501.062,175.753 508.562,204.086 511.5,233.5C 511.5,248.167 511.5,262.833 511.5,277.5C 502.353,358.412 464.686,422.245 398.5,469C 361.29,493.236 320.623,507.403 276.5,511.5C 262.5,511.5 248.5,511.5 234.5,511.5C 133.657,499.479 61.8235,447.479 19,355.5C 8.70332,330.313 2.20332,304.313 -0.5,277.5C -0.5,262.833 -0.5,248.167 -0.5,233.5C 11.9821,133.195 63.9821,61.6947 155.5,19C 180.988,8.54416 207.322,2.04416 234.5,-0.5 Z M 249.5,39.5 C 333.406,40.518 397.572,76.1847 442,146.5C 482.054,221.401 481.054,295.734 439,369.5C 395.66,434.771 334.493,468.771 255.5,471.5C 162.945,467.179 96.7779,423.512 57,340.5C 28.436,267.469 35.436,198.135 78,132.5C 120.424,74.6985 177.591,43.6985 249.5,39.5 Z'
				/>
			</g>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.968 }}
					d='M 184.5,163.5 C 189.897,163.679 194.897,165.179 199.5,168C 218.667,187.167 237.833,206.333 257,225.5C 276.5,206 296,186.5 315.5,167C 332.942,160.053 343.609,165.72 347.5,184C 347.2,189.43 345.367,194.264 342,198.5C 323.319,217.014 304.819,235.681 286.5,254.5C 305.486,273.986 324.653,293.32 344,312.5C 350.292,325.284 347.458,335.451 335.5,343C 327.628,345.65 320.294,344.65 313.5,340C 294.653,320.986 275.653,302.153 256.5,283.5C 237.014,302.486 217.68,321.653 198.5,341C 187.811,346.75 178.311,345.25 170,336.5C 164.834,327.006 165.5,318.006 172,309.5C 190.985,291.681 209.485,273.348 227.5,254.5C 208.514,235.014 189.347,215.68 170,196.5C 162.406,179.729 167.24,168.729 184.5,163.5 Z'
				/>
			</g>
		</svg>
	);
};
