import './style.sass';

import classNames from 'classnames';
import React from 'react';

export default ({
	type = 'default',
	placeholder = '',
	value = '',
	onChange = () => {},
	height = 21,
	width = 'calc(100% - 26px)',
	leftIcon = null,
	leftIconClick = () => {},
	rightIcon = null,
	rightIconClick = () => {},
}) => {
	return (
		<div
			style={{ height, width }}
			className={classNames({
				'input-wrapper': true,
				default: type == 'default',
				alter: type == 'alter',
			})}
		>
			{leftIcon && (
				<div
					onClick={leftIconClick}
					className={classNames({
						'icon-wrapper': true,
						default: type == 'default',
						alter: type == 'alter',
					})}
				>
					{leftIcon}
				</div>
			)}
			<input
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				className={classNames({ input: true, default: type == 'default', alter: type == 'alter' })}
			/>
			{rightIcon && (
				<div
					onClick={rightIconClick}
					className={classNames({
						'icon-wrapper': true,
						default: type == 'default',
						alter: type == 'alter',
					})}
				>
					{rightIcon}
				</div>
			)}
		</div>
	);
};
