import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import {
	loginTelegram,
	loginTelegramAsync,
	getUserInfo,
	getUserInfoAsync,
	clearUserError,
	clearUserErrorAsync,
} from '../../actions/user';

import userApi from '../../../services/api/user';

function clearErrorWorker(language) {
	return { language };
}

export function* userSaga() {
	yield takeEvery(loginTelegram, bindAsyncActions(loginTelegramAsync)(userApi.loginTelegramEndpoint));
	yield takeEvery(getUserInfo, bindAsyncActions(getUserInfoAsync)(userApi.getUserInfoEndpoint));
	yield takeEvery(clearUserError, bindAsyncActions(clearUserErrorAsync)(clearErrorWorker));
}
