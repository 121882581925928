import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			style={{
				shapeRendering: 'geometricPrecision',
				textRendering: 'geometricPrecision',
				imageRendering: 'optimizeQuality',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			}}
		>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.987 }}
					d='M 85.5,47.5 C 124.168,47.3333 162.835,47.5 201.5,48C 221.333,53.1667 233.833,65.6667 239,85.5C 239.667,124.167 239.667,162.833 239,201.5C 233.833,221.333 221.333,233.833 201.5,239C 162.833,239.667 124.167,239.667 85.5,239C 65.6667,233.833 53.1667,221.333 48,201.5C 47.3333,162.833 47.3333,124.167 48,85.5C 53.3488,65.6518 65.8488,52.9852 85.5,47.5 Z M 95.5,79.5 C 127.502,79.3334 159.502,79.5 191.5,80C 200.667,81.1667 205.833,86.3333 207,95.5C 207.667,127.5 207.667,159.5 207,191.5C 205.833,200.667 200.667,205.833 191.5,207C 159.5,207.667 127.5,207.667 95.5,207C 86.3333,205.833 81.1667,200.667 80,191.5C 79.3333,159.5 79.3333,127.5 80,95.5C 81.3459,86.3224 86.5125,80.9891 95.5,79.5 Z'
				/>
			</g>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.987 }}
					d='M 309.5,47.5 C 348.168,47.3333 386.835,47.5 425.5,48C 445.333,53.1667 457.833,65.6667 463,85.5C 463.667,124.167 463.667,162.833 463,201.5C 457.833,221.333 445.333,233.833 425.5,239C 386.833,239.667 348.167,239.667 309.5,239C 289.667,233.833 277.167,221.333 272,201.5C 271.333,162.833 271.333,124.167 272,85.5C 277.349,65.6518 289.849,52.9852 309.5,47.5 Z M 319.5,79.5 C 351.502,79.3334 383.502,79.5 415.5,80C 424.667,81.1667 429.833,86.3333 431,95.5C 431.667,127.5 431.667,159.5 431,191.5C 429.833,200.667 424.667,205.833 415.5,207C 383.5,207.667 351.5,207.667 319.5,207C 310.333,205.833 305.167,200.667 304,191.5C 303.333,159.5 303.333,127.5 304,95.5C 305.346,86.3224 310.513,80.9891 319.5,79.5 Z'
				/>
			</g>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.987 }}
					d='M 85.5,271.5 C 124.168,271.333 162.835,271.5 201.5,272C 221.333,277.167 233.833,289.667 239,309.5C 239.667,348.167 239.667,386.833 239,425.5C 233.833,445.333 221.333,457.833 201.5,463C 162.833,463.667 124.167,463.667 85.5,463C 65.6667,457.833 53.1667,445.333 48,425.5C 47.3333,386.833 47.3333,348.167 48,309.5C 53.3488,289.652 65.8488,276.985 85.5,271.5 Z M 95.5,303.5 C 127.502,303.333 159.502,303.5 191.5,304C 200.667,305.167 205.833,310.333 207,319.5C 207.667,351.5 207.667,383.5 207,415.5C 205.833,424.667 200.667,429.833 191.5,431C 159.5,431.667 127.5,431.667 95.5,431C 86.3333,429.833 81.1667,424.667 80,415.5C 79.3333,383.5 79.3333,351.5 80,319.5C 81.3459,310.322 86.5125,304.989 95.5,303.5 Z'
				/>
			</g>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.963 }}
					d='M 353.5,271.5 C 403,267.898 437.834,288.565 458,333.5C 472.531,382.916 458.698,422.083 416.5,451C 394.177,463.387 370.51,466.887 345.5,461.5C 304.495,449.995 279.995,423.328 272,381.5C 267.912,331.975 288.412,297.142 333.5,277C 340.208,274.826 346.875,272.993 353.5,271.5 Z M 367.5,303.5 C 401.028,306.194 421.861,323.861 430,356.5C 433.593,387.484 421.76,410.317 394.5,425C 357.801,437.992 329.634,427.826 310,394.5C 297.008,357.801 307.174,329.634 340.5,310C 349.295,306.383 358.295,304.216 367.5,303.5 Z'
				/>
			</g>
		</svg>
	);
};
