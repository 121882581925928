import { handleActions } from 'redux-actions';

import {
	changeAvatarNameAsync,
	changeAvatarGenderAsync,
	changeAvatarRaceAsync,
	changeAvatarAppearanceTypeSimpleAsync,
	changeAvatarHairsAsync,
	changeAvatarEyesAsync,
	changeAvatarBeardAsync,
	changeAvatarWingsAsync,
	changeAvatarHornsAsync,
	changeWearableBodyAsync,
	changeWearableLeggsAsync,
	changeWearableBootsAsync,
} from '../../actions/createAvatar';

const initialState = {
	name: '',
	gender: 'male',
	race: 'human',
	appearance: {
		horns: {
			type: 0,
			color: '#b88989',
		},
		hairs: {
			front: 0,
			back: 0,
			color: '#9C7171',
		},
		beard: {
			type: false,
			color: '#9C7171',
		},
		eyes: {
			type: 0,
			color: '#627F85',
		},
		eyebrows: 0,
		mouth: 0,
		head: 0,
		body: 0,
		wings: {
			type: 0,
			color: '#B88989',
		},
		tail: 0,
		skin: '#EDDDD8',
	},
	wearable: {
		body: {
			type: 0,
			color: '#123123',
		},
		leggs: {
			type: 0,
			color: '#123123',
		},
		boots: {
			type: 0,
			color: '#123123',
		},
	},
};

export default handleActions(
	{
		[changeAvatarNameAsync.success]: (s, { payload: { name } } = {}) => ({ ...s, name }),
		[changeAvatarGenderAsync.success]: (s, { payload: { gender } } = {}) => ({ ...s, gender }),
		[changeAvatarRaceAsync.success]: (s, { payload: { race } } = {}) => ({ ...s, race }),
		[changeAvatarAppearanceTypeSimpleAsync.success]: (s, { payload: { name, value } } = {}) => ({
			...s,
			appearance: { ...s.appearance, [name]: value },
		}),
		[changeAvatarHairsAsync.success]: (s, { payload: { name, value } } = {}) => ({
			...s,
			appearance: { ...s.appearance, hairs: { ...s.appearance.hairs, [name]: value } },
		}),
		[changeAvatarEyesAsync.success]: (s, { payload: { name, value } } = {}) => ({
			...s,
			appearance: { ...s.appearance, eyes: { ...s.appearance.eyes, [name]: value } },
		}),
		[changeAvatarBeardAsync.success]: (s, { payload: { name, value } } = {}) => ({
			...s,
			appearance: { ...s.appearance, beard: { ...s.appearance.beard, [name]: value } },
		}),
		[changeAvatarWingsAsync.success]: (s, { payload: { name, value } } = {}) => ({
			...s,
			appearance: { ...s.appearance, wings: { ...s.appearance.wings, [name]: value } },
		}),
		[changeAvatarHornsAsync.success]: (s, { payload: { name, value } } = {}) => ({
			...s,
			appearance: { ...s.appearance, horns: { ...s.appearance.horns, [name]: value } },
		}),
		[changeWearableBodyAsync.success]: (s, { payload: { name, value } } = {}) => ({
			...s,
			wearable: { ...s.wearable, body: { ...s.wearable.body, [name]: value } },
		}),
		[changeWearableLeggsAsync.success]: (s, { payload: { name, value } } = {}) => ({
			...s,
			wearable: { ...s.wearable, leggs: { ...s.wearable.leggs, [name]: value } },
		}),
		[changeWearableBootsAsync.success]: (s, { payload: { name, value } } = {}) => ({
			...s,
			wearable: { ...s.wearable, boots: { ...s.wearable.boots, [name]: value } },
		}),
	},
	initialState
);
