import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			preserveAspectRatio='xMidYMid meet'
		>
			<g
				transform='translate(0, 512) scale(0.100000,-0.100000)'
				fill={fill}
				stroke='none'
			>
				<path
					d='M1861 4889 c-235 -46 -411 -297 -372 -531 6 -38 18 -85 26 -104 l14
-33 -426 -3 -425 -3 -19 -24 c-19 -23 -19 -63 -19 -1602 l0 -1579 -299 0 -300
0 -21 -27 c-20 -25 -22 -36 -17 -103 13 -172 81 -315 212 -445 99 -100 175
-146 305 -187 l75 -23 1965 0 1965 0 75 23 c130 41 206 87 305 187 97 97 143
170 187 300 l23 70 3 1683 2 1683 -24 24 -25 25 -306 0 -306 0 -24 -25 c-32
-31 -33 -74 -2 -103 23 -21 30 -22 280 -22 l257 0 0 -1592 c0 -1328 -3 -1604
-14 -1659 -22 -101 -78 -203 -155 -280 -218 -218 -558 -224 -779 -14 -110 104
-164 213 -181 367 -7 66 -14 86 -31 100 -21 17 -75 18 -947 18 -632 0 -931 -3
-944 -11 -48 -25 -44 -105 7 -128 18 -8 269 -11 894 -11 l869 0 11 -57 c29
-142 99 -272 203 -375 l57 -58 -1647 0 c-1816 0 -1718 -3 -1850 64 -150 75
-275 236 -299 385 l-7 41 730 0 c724 0 731 0 757 21 33 26 36 79 6 109 -19 19
-33 20 -440 20 l-420 0 0 1530 0 1530 425 0 425 0 48 -34 c136 -98 321 -113
480 -38 29 13 55 29 58 34 4 6 108 -92 233 -216 198 -197 231 -226 258 -226
43 0 74 28 75 68 1 31 -15 50 -180 215 -100 100 -182 186 -182 190 0 4 389 7
865 7 852 0 865 0 885 20 30 30 27 83 -6 109 -27 21 -32 21 -904 21 l-878 0
20 63 c15 49 19 83 16 162 -3 91 -7 107 -40 178 -96 202 -308 309 -527 266z
m245 -179 c120 -59 180 -167 172 -309 -5 -86 -27 -138 -84 -201 -82 -90 -231
-124 -349 -79 -81 30 -165 113 -191 189 -53 155 9 319 147 395 70 37 101 45
177 41 46 -3 81 -13 128 -36z'
				/>
				<path
					d='M3020 3013 c-8 -3 -23 -12 -32 -21 -17 -14 -18 -46 -18 -412 0 -468
-8 -437 113 -432 170 6 339 73 425 167 64 69 87 133 86 235 -1 126 -61 231
-179 315 -74 53 -162 95 -260 123 -90 26 -117 31 -135 25z m205 -212 c146 -68
218 -155 218 -263 0 -98 -60 -164 -190 -206 -32 -11 -75 -23 -95 -26 l-38 -7
0 271 c0 190 3 270 11 270 6 0 48 -18 94 -39z'
				/>
				<path
					d='M1414 2930 c-26 -10 -54 -48 -54 -71 0 -9 9 -28 21 -43 20 -25 24
-26 120 -26 l99 0 0 -294 c0 -295 0 -295 23 -322 28 -32 78 -37 105 -10 15 15
18 52 22 319 l5 302 103 5 c88 4 106 8 118 24 21 29 18 80 -7 102 -19 18 -41
19 -278 21 -141 1 -266 -2 -277 -7z'
				/>
				<path
					d='M2273 2929 c-155 -29 -266 -190 -266 -389 0 -222 136 -392 315 -392
114 0 210 71 272 201 30 63 31 72 31 191 0 119 -1 128 -31 191 -70 147 -191
222 -321 198z m123 -175 c73 -54 106 -211 69 -325 -20 -59 -78 -123 -120 -131
-102 -20 -188 91 -188 242 0 54 6 89 20 121 54 120 136 155 219 93z'
				/>
				<path
					d='M4033 2929 c-151 -28 -263 -193 -263 -389 0 -219 136 -391 310 -393
152 -1 279 130 312 321 44 258 -145 501 -359 461z m148 -199 c71 -77 86 -208
39 -318 -39 -91 -104 -131 -173 -107 -49 17 -59 26 -93 87 -26 48 -29 62 -29
148 0 86 3 100 29 148 34 61 44 70 93 87 50 17 90 4 134 -45z'
				/>
				<path
					d='M2105 1870 c-25 -28 -23 -76 4 -103 l22 -22 825 -3 825 -2 30 29 c34
35 37 59 8 95 l-20 26 -838 0 c-827 0 -838 0 -856 -20z'
				/>
				<path
					d='M2271 1472 c-33 -26 -31 -79 3 -109 l27 -23 540 0 541 0 30 30 c24
25 29 36 24 58 -4 15 -16 35 -28 45 -19 16 -61 17 -568 17 -508 0 -549 -1
-569 -18z'
				/>
			</g>
		</svg>
	);
};
