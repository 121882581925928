import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import InverseLogoIcon from '../../components/Icons/InverseLogoIcon';
import DefaultButton from '../../components/Buttons/DefaultButton';

import useTelegram from '../../hooks/useTelegram';

export default connect(
	(s) => ({
		user: s.userManager.state,
		avatar: s.avatarManager.state,
		userError: s.userManager.error,
		validated: s.userManager.validated,
		whitelisted: s.userManager.whitelisted,
	}),
	{}
)(({ user, avatar, userError, validated, whitelisted }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { tg } = useTelegram();

	React.useEffect(() => {
		if (validated === true && whitelisted === true && avatar) {
			navigate('/avatar-info', { replace: true });
		} else if (validated === true && whitelisted === true && user) {
			navigate('/welcome', { replace: true });
		} else if (userError) {
			navigate('/something-went-wrong', { replace: true });
		}
	}, [whitelisted, validated, userError, avatar, user]);

	return (
		<div className={classNames({ container: true, 'not-white-listed': true })}>
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'icon-wrapper': true })}>
					<InverseLogoIcon
						width={220}
						height={220}
					/>
				</div>
				<span className={classNames({ title: true })}>{t('not-white-listed-page.title')}</span>
				<span className={classNames({ description: true })}>{t('not-white-listed-page.description')}</span>
			</div>
			<div className={classNames({ 'bottom-buttons': true })}>
				<DefaultButton
					onClick={() => tg.openTelegramLink(`https://t.me/synergyproject`)}
					text={t('not-white-listed-page.buttons.to-channel')}
				/>
			</div>
		</div>
	);
});
