import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('INVENTORY');

export const getAvatarInventory = factory.create('GET_AVATAR_INVENTORY');
export const getAvatarInventoryAsync = factory.createAsync('GET_AVATAR_INVENTORY_ASYNC');

export const getAvatarEquipment = factory.create('GET_AVATAR_EQUIPMENT');
export const getAvatarEquipmentAsync = factory.createAsync('GET_AVATAR_EQUIPMENT_ASYNC');

export const useInventoryItem = factory.create('USE_INVENTORY_ITEM');
export const useInventoryItemAsync = factory.createAsync('USE_INVENTORY_ITEM_ASYNC');

export const updateAvatarInventory = factory.create('UPDATE_AVATAR_INVENTORY');
export const updateAvatarInventoryAsync = factory.createAsync('UPDATE_AVATAR_INVENTORY_ASYNC');

export const clearInventoryError = factory.create('CLEAR_LOCATIONS_ERROR');
export const clearInventoryErrorAsync = factory.createAsync('CLEAR_LOCATIONS_ERROR_ASYNC');
