import { rgbToHsv } from './../converters';

export default (color) => {
	const { s, v } = rgbToHsv(color.rgb);

	const x = s;
	const y = 100 - v;

	return [x, y];
};
