import './style.sass';

import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import ConfirmDepositEarlyClosingModal from '../ConfirmDepositEarlyClosing';
import ConfirmLocationActionModal from '../ConfirmLocationAction';
import ConfirmLevelUpgradeModal from '../ConfirmLevelUpgrade';
import EquipmentInventoryModal from '../EquipmentInventory';
import LocationInventoryModal from '../LocationInventory';
import InventoryItemInfoModal from '../InventoryItemInfo';
import GuesthouseChestModal from '../GuesthouseChest';
import WithdrawalFundsModal from '../WithdrawalFunds';
import DonateAmountModal from '../DonateAmount';
import LocationInfoModal from '../LocationInfo';
import LootReceivedModal from '../LootReceived';
import DepositFundsModal from '../DepositFunds';
import ConfirmRestModal from '../ConfirmRest';
import CraftedItemModal from '../CraftedItem';
import ColorPickerModal from '../ColorPicker';
import TypePickerModal from '../TypePicker';
import MainMenuModal from '../MainMenu';

import { openModal, closeModal } from '../../../store/actions/modals';

export default connect(
	(s) => ({
		modalPayload: s.modals.payload,
		modalName: s.modals.name,
	}),
	{ openModal, closeModal }
)(({ modalName, modalPayload = {}, openModal, closeModal, customTypeStyle = {} }) => {
	return modalName ? (
		<div
			className={classNames({
				'modals-wrapper': true,
				center: modalPayload?.type == 'center',
				full: modalPayload?.type == 'full',
				part: modalPayload?.type == 'part',
				custom: modalPayload?.type == 'custom',
			})}
			style={{ ...customTypeStyle, ...(modalPayload.withoutPadding ? { padding: 0, width: '100%', height: '100%' } : {}) }}
		>
			{!modalPayload.withoutBackground && (
				<div
					className={classNames({ 'modals-background': true })}
					onClick={closeModal}
				/>
			)}
			{modalName == 'type-picker' ? (
				<TypePickerModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'color-picker' ? (
				<ColorPickerModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'main-menu' ? (
				<MainMenuModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'location-info' ? (
				<LocationInfoModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'location-inventory' ? (
				<LocationInventoryModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'confirm-location-action' ? (
				<ConfirmLocationActionModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'loot-received' ? (
				<LootReceivedModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'crafted-item' ? (
				<CraftedItemModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'equipment-inventory' ? (
				<EquipmentInventoryModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'inventory-item-info' ? (
				<InventoryItemInfoModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'guesthouse-chest' ? (
				<GuesthouseChestModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'confirm-rest' ? (
				<ConfirmRestModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'donate-amount' ? (
				<DonateAmountModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'confirm-level-upgrade' ? (
				<ConfirmLevelUpgradeModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'confirm-deposit-early-closing' ? (
				<ConfirmDepositEarlyClosingModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'deposit-funds' ? (
				<DepositFundsModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : modalName == 'withdrawal-funds' ? (
				<WithdrawalFundsModal
					{...modalPayload}
					closeModal={closeModal}
				/>
			) : null}
		</div>
	) : null;
});
