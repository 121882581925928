import colorConvert from 'color-convert';

import { defaultEyesColor } from '../../../common/avatar';

export default (rgbColorForReplace) => [
	{
		target: colorConvert.hex.rgb(defaultEyesColor[0]),
		replace: rgbColorForReplace,
	},
	{
		target: colorConvert.hex.rgb(defaultEyesColor[1]),
		replace: [rgbColorForReplace[0] + 52, rgbColorForReplace[1] + 73, rgbColorForReplace[2] + 71],
	},
	{
		target: colorConvert.hex.rgb(defaultEyesColor[2]),
		replace: [rgbColorForReplace[0] + 43, rgbColorForReplace[1] + 96, rgbColorForReplace[2] + 96],
	},
];
