import { useTonConnectModal, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { beginCell, Cell, toNano } from '@ton/ton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import classNames from 'classnames';
import React from 'react';

import IncompletedIcon from '../../components/Icons/IncompletedIcon';
import DefaultButton from '../../components/Buttons/DefaultButton';
import CompletedIcon from '../../components/Icons/CompletedIcon';
import CloseIcon from '../../components/Icons/CloseIcon';

import { getDailyCheckInManger, updateDailyCheckInWallet, confirmDailyCheckIn } from '../../store/actions/guild';

export default connect(
	(s) => ({
		validated: s.userManager.validated,
		whitelisted: s.userManager.whitelisted,
		dailyCheckInManger: s.guildManager.dailyCheckInManager,
	}),
	{ confirmDailyCheckIn, getDailyCheckInManger, updateDailyCheckInWallet }
)(({ validated, whitelisted, dailyCheckInManger, confirmDailyCheckIn, getDailyCheckInManger, updateDailyCheckInWallet }) => {
	const tonConnectModal = useTonConnectModal();
	const [tonConnectUI] = useTonConnectUI();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const wallet = useTonWallet();

	const checkIn = () => {
		const comment = beginCell().storeUint(0, 32).storeStringTail(dailyCheckInManger._id).endCell();

		const checkInTransaction = {
			validUntil: Math.floor(Date.now() / 1000) + 360,
			messages: [
				{
					address: 'EQB2HxkTxEO4IWEHz89kfmLbJYov6io6Iaex2mm577IrYQfI',
					payload: comment.toBoc().toString('base64'),
					amount: toNano('0.05').toString(),
				},
			],
		};

		tonConnectUI
			.sendTransaction(checkInTransaction)
			.then((fullfilled) => {
				let hash = Cell.fromBase64(fullfilled.boc).hash().toString('hex');

				confirmDailyCheckIn({ hash });
			})
			.catch(() => {});
	};

	React.useEffect(() => {
		if (validated && whitelisted) {
			getDailyCheckInManger();
		}
	}, [validated, whitelisted]);

	React.useEffect(() => {
		if (wallet && wallet.account.address != dailyCheckInManger.wallet.address) {
			updateDailyCheckInWallet({ address: wallet.account.address });
		}
	}, [wallet]);

	return (
		<div className={classNames({ container: true, 'check-in': true })}>
			<div className={classNames({ 'task-info-header': true })}>
				<div
					className={classNames({ 'task-info-header-close': true })}
					onClick={() => navigate(-1)}
				>
					<CloseIcon
						width={16}
						height={16}
						fill={'#ffffff'}
					/>
				</div>
				<span className={classNames({ 'task-info-header-title': true })}>{t(`check-in.header.title`)}</span>
			</div>
			<div className={classNames({ content: true })}>
				<div className={classNames({ cover: true })} />
				<div className={classNames({ info: true })}>
					<div className={classNames({ 'check-in-streak-wrapper': true })}>
						<span className={classNames({ 'check-in-streak-title': true })}>{t(`check-in.days-streak`)}</span>
						<span className={classNames({ 'check-in-streak-text': true })}>
							{t(`check-in.days-count`, { count: dailyCheckInManger.streak })}
						</span>
					</div>
					<div className={classNames({ 'description-wrapper': true })}>
						<div className={classNames({ 'task-description': true })}>
							<img
								className={classNames({ 'task-description-image': true })}
								src={'https://i.imgur.com/F5TEIUU.png'}
							/>
							<div className={classNames({ 'task-description-info': true })}>
								<span className={classNames({ 'task-description-info-title': true })}>{t('check-in.description-title')}</span>
								<span className={classNames({ 'task-description-info-text': true })}>{t('check-in.description-text')}</span>
							</div>
						</div>
						<div className={classNames({ divider: true })} />
						<div className={classNames({ 'ranks-rewards': true })}>
							<div className={classNames({ 'ranks-reward': true })}>
								{dailyCheckInManger.streak >= 1 ? (
									<CompletedIcon
										fill={'#009933'}
										height={20}
										width={20}
									/>
								) : (
									<IncompletedIcon
										fill={'#FF3300'}
										height={20}
										width={20}
									/>
								)}
								<span className={classNames({ 'ranks-reward-level': true })}>{t('check-in.periods.1-3', { level: 'II' })}</span>
								<span className={classNames({ 'ranks-reward-amount': true })}>{t('check-in.reward', { count: 50 })}</span>
							</div>
							<div className={classNames({ 'ranks-reward': true })}>
								{dailyCheckInManger.streak > 3 ? (
									<CompletedIcon
										fill={'#009933'}
										height={20}
										width={20}
									/>
								) : (
									<IncompletedIcon
										fill={'#FF3300'}
										height={20}
										width={20}
									/>
								)}
								<span className={classNames({ 'ranks-reward-level': true })}>{t('check-in.periods.4-6', { level: 'III' })}</span>
								<span className={classNames({ 'ranks-reward-amount': true })}>{t('check-in.reward', { count: 75 })}</span>
							</div>
							<div className={classNames({ 'ranks-reward': true })}>
								{dailyCheckInManger.streak > 6 ? (
									<CompletedIcon
										fill={'#009933'}
										height={20}
										width={20}
									/>
								) : (
									<IncompletedIcon
										fill={'#FF3300'}
										height={20}
										width={20}
									/>
								)}
								<span className={classNames({ 'ranks-reward-level': true })}>{t('check-in.periods.7-9', { level: 'IV' })}</span>
								<span className={classNames({ 'ranks-reward-amount': true })}>{t('check-in.reward', { count: 100 })}</span>
							</div>
							<div className={classNames({ 'ranks-reward': true })}>
								{dailyCheckInManger.streak > 10 ? (
									<CompletedIcon
										fill={'#009933'}
										height={20}
										width={20}
									/>
								) : (
									<IncompletedIcon
										fill={'#FF3300'}
										height={20}
										width={20}
									/>
								)}
								<span className={classNames({ 'ranks-reward-level': true })}>{t('check-in.periods.10+', { level: 'V' })}</span>
								<span className={classNames({ 'ranks-reward-amount': true })}>{t('check-in.reward', { count: 125 })}</span>
							</div>
						</div>
					</div>
				</div>
				<div className={classNames({ 'action-buttons': true })}>
					<DefaultButton
						text={t(
							`check-in.buttons.${
								wallet && dailyCheckInManger.lastCheckInTransaction
									? 'in-progress'
									: wallet &&
									  dailyCheckInManger.lastCheckInAt &&
									  moment(dailyCheckInManger.lastCheckInAt)
											.locale('ru')
											.tz('Europe/Moscow')
											.isBetween(
												moment(new Date()).locale('ru').tz('Europe/Moscow').set('hours', 0).set('minutes', 0).set('seconds', 0),
												moment(new Date()).locale('ru').tz('Europe/Moscow').set('hours', 23).set('minutes', 59).set('seconds', 59)
											)
									? 'completed'
									: wallet
									? 'check-in'
									: 'connect-wallet'
							}`
						)}
						onClick={wallet ? checkIn : tonConnectModal.open}
						disabled={
							wallet &&
							(!!dailyCheckInManger.lastCheckInTransaction ||
								(dailyCheckInManger.lastCheckInAt &&
									moment(dailyCheckInManger.lastCheckInAt)
										.locale('ru')
										.tz('Europe/Moscow')
										.isBetween(
											moment(new Date()).locale('ru').tz('Europe/Moscow').set('hours', 0).set('minutes', 0).set('seconds', 0),
											moment(new Date()).locale('ru').tz('Europe/Moscow').set('hours', 23).set('minutes', 59).set('seconds', 59)
										)))
						}
						type={'primary'}
						fontSize={20}
						height={50}
						flex={1}
					/>
					{wallet ? (
						<div
							className={classNames({ 'copy-button': true })}
							onClick={() => tonConnectUI.disconnect()}
						>
							<CloseIcon
								width={24}
								height={24}
								fill={'#ffffff'}
							/>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
});
