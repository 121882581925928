export default (hex) => {
	// check on hex
	if (typeof hex !== 'string' || !hex.startsWith('#')) return false;

	// remove # symbol
	const hexWithoutHash = hex.slice(1);

	// check color length
	const isValidLength = [3, 4, 6, 8].includes(hexWithoutHash.length);

	if (!isValidLength) return false;

	// test on hex color symbols
	const isHex = /^[0-9A-Fa-f]+$/.test(hexWithoutHash);

	return isHex;
};
