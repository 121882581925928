import colorConvert from 'color-convert';

import { defaultBeardColor } from '../../../common/avatar';

export default (rgbColorForReplace) => [
	{
		target: colorConvert.hex.rgb(defaultBeardColor[0]),
		replace: rgbColorForReplace,
	},
	{
		target: colorConvert.hex.rgb(defaultBeardColor[1]),
		replace: [rgbColorForReplace[0] - 31, rgbColorForReplace[1] - 17, rgbColorForReplace[2] - 17],
	},
	{
		target: colorConvert.hex.rgb(defaultBeardColor[2]),
		replace: [rgbColorForReplace[0] + 23, rgbColorForReplace[1] + 17, rgbColorForReplace[2] + 16],
	},
];
