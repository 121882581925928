import { handleActions } from 'redux-actions';

import { getPotionsAsync, brewPotionAsync, changePotionsFieldAsync, clearPotionsErrorAsync } from '../../actions/potions';

import { defaultError } from '../../../common/errors';

const initialState = {
	search: '',
	potions: [],
	error: null,
};

export default handleActions(
	{
		[getPotionsAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			potions: response.success ? response.result.recipes : null,
		}),
		[brewPotionAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
		}),
		[changePotionsFieldAsync.success]: (s, { payload: { name, value } }) => ({ ...s, [name]: value }),
		[clearPotionsErrorAsync.success]: (s, p) => ({ ...s, error: null }),

		[getPotionsAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			potions: [],
		}),
		[brewPotionAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			potions: [],
		}),
	},
	initialState
);
