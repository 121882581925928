import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			preserveAspectRatio='xMidYMid meet'
		>
			<g
				fill={fill}
				stroke='none'
				transform='translate(0,512) scale(0.100000,-0.100000)'
			>
				<path
					d='M2105 5095 c-25 -24 -25 -27 -25 -200 l0 -175 -96 0 c-53 0 -115 -5
-138 -12 -55 -16 -138 -99 -154 -154 -28 -94 -8 -169 63 -239 56 -57 102 -75
189 -75 l56 0 0 -324 0 -323 -77 -28 c-43 -15 -126 -51 -184 -80 -746 -374
-1148 -1204 -979 -2020 111 -536 469 -1006 960 -1259 915 -471 2025 -104 2490
823 348 696 206 1551 -351 2110 -188 188 -420 338 -661 427 l-78 29 0 322 0
323 58 0 c87 0 144 23 195 80 90 100 90 220 0 320 -57 63 -106 80 -235 80
l-98 0 0 175 c0 173 0 176 -25 200 l-24 25 -431 0 -431 0 -24 -25z m775 -255
l0 -120 -320 0 -320 0 0 120 0 120 320 0 320 0 0 -120z m375 -305 c33 -32 33
-78 0 -110 l-24 -25 -671 0 -671 0 -24 25 c-16 15 -25 36 -25 55 0 19 9 40 25
55 l24 25 671 0 671 0 24 -25z m-964 -432 c48 -194 32 -183 269 -183 237 0
221 -11 269 183 l33 137 49 0 49 0 0 -364 c0 -432 -11 -396 140 -446 690 -226
1171 -927 1137 -1655 -10 -211 -47 -382 -119 -559 -374 -913 -1427 -1315
-2303 -879 -772 385 -1128 1282 -828 2088 68 183 201 400 338 552 179 198 446
371 699 454 147 49 136 14 136 445 l0 364 49 0 49 0 33 -137z m387 57 l-20
-80 -98 0 -98 0 -20 80 -20 80 138 0 138 0 -20 -80z'
				/>
				<path
					d='M2345 3735 c-50 -49 -15 -135 55 -135 41 0 80 39 80 80 0 41 -39 80
-80 80 -19 0 -40 -9 -55 -25z'
				/>
				<path
					d='M2350 3502 c-13 -11 -25 -36 -31 -67 -14 -74 -35 -89 -183 -136 -148
-46 -352 -141 -410 -192 -31 -26 -39 -40 -39 -67 0 -37 39 -80 74 -80 12 0 64
25 117 55 102 58 220 107 364 149 53 16 103 39 129 59 55 42 99 121 106 191 5
49 3 57 -20 80 -31 31 -75 34 -107 8z'
				/>
				<path
					d='M1695 2670 c-180 -28 -365 -119 -508 -250 -63 -58 -78 -92 -119 -284
-31 -146 -32 -436 0 -582 68 -316 204 -567 426 -790 221 -221 460 -350 770
-416 82 -17 136 -21 286 -22 212 -1 339 19 508 79 135 48 171 95 121 159 -28
35 -53 34 -195 -13 -65 -21 -153 -45 -194 -52 -110 -20 -374 -18 -480 4 -280
58 -502 178 -705 382 -220 219 -345 469 -391 777 -26 179 -9 411 42 570 37
114 257 245 467 278 97 16 227 8 327 -20 107 -30 266 -111 479 -244 286 -180
420 -240 606 -273 230 -40 523 24 688 151 26 20 52 34 56 31 35 -21 48 -416
18 -565 -60 -303 -223 -587 -447 -775 -43 -37 -81 -74 -84 -82 -18 -45 10 -97
58 -109 39 -10 85 22 212 150 182 183 290 358 368 598 56 170 71 278 70 483
-1 185 -9 245 -55 418 -21 75 -27 87 -80 139 -109 107 -271 198 -426 240 -108
29 -324 31 -438 4 -137 -32 -323 -117 -492 -226 -20 -13 -26 -13 -45 0 -170
108 -342 189 -473 221 -93 23 -281 32 -370 19z m1728 -164 c103 -20 211 -64
296 -121 39 -26 75 -53 78 -58 10 -16 -68 -80 -145 -120 -115 -61 -206 -81
-362 -82 -111 0 -149 4 -215 23 -73 20 -293 123 -339 157 -20 15 -20 15 0 29
35 27 208 114 269 135 141 50 282 63 418 37z'
				/>
				<path
					d='M1465 2215 c-50 -49 -15 -135 55 -135 19 0 40 9 55 25 16 15 25 36
25 55 0 19 -9 40 -25 55 -15 16 -36 25 -55 25 -19 0 -40 -9 -55 -25z'
				/>
				<path
					d='M1786 1984 c-70 -22 -149 -103 -170 -174 -34 -113 -11 -206 69 -286
41 -41 65 -56 110 -70 219 -63 414 132 351 351 -14 45 -29 69 -70 110 -80 80
-178 103 -290 69z m178 -183 c26 -27 31 -39 31 -81 0 -43 -5 -54 -33 -82 -28
-28 -39 -33 -82 -33 -43 0 -54 5 -82 33 -28 28 -33 39 -33 81 0 52 25 92 70
112 37 16 96 3 129 -30z'
				/>
				<path
					d='M2505 1815 c-50 -49 -15 -135 55 -135 41 0 80 39 80 80 0 41 -39 80
-80 80 -19 0 -40 -9 -55 -25z'
				/>
				<path
					d='M3625 1815 c-50 -49 -15 -135 55 -135 41 0 80 39 80 80 0 41 -39 80
-80 80 -19 0 -40 -9 -55 -25z'
				/>
				<path
					d='M3120 1747 c-49 -16 -133 -102 -148 -153 -28 -94 -8 -169 63 -239
101 -102 229 -102 330 0 98 97 101 222 8 325 -65 72 -158 97 -253 67z m135
-172 c50 -49 15 -135 -55 -135 -41 0 -80 39 -80 80 0 41 39 80 80 80 19 0 40
-9 55 -25z'
				/>
				<path
					d='M2405 1351 c-92 -24 -173 -90 -215 -176 -19 -41 -24 -66 -24 -135 -1
-78 2 -90 33 -148 38 -70 70 -100 145 -140 43 -22 64 -26 136 -26 72 0 93 4
136 26 75 40 107 70 145 140 31 58 34 70 33 148 0 72 -4 93 -26 136 -39 75
-70 107 -137 143 -65 34 -164 49 -226 32z m138 -165 c103 -43 128 -177 48
-257 -65 -65 -157 -65 -222 0 -124 123 13 325 174 257z'
				/>
				<path
					d='M1835 1271 c-45 -20 -70 -60 -70 -112 0 -42 5 -53 33 -81 28 -28 39
-33 82 -33 43 0 54 5 82 33 28 28 33 39 33 82 0 42 -5 54 -31 81 -33 33 -92
46 -129 30z'
				/>
				<path
					d='M3035 1111 c-45 -20 -70 -60 -70 -112 0 -42 5 -53 33 -81 28 -28 39
-33 82 -33 43 0 54 5 82 33 28 28 33 39 33 82 0 42 -5 54 -31 81 -33 33 -92
46 -129 30z'
				/>
			</g>
		</svg>
	);
};
