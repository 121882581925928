import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('TOWNHALL');

export const getTownhallNews = factory.create('GET_TOWNHALL_NEWS');
export const getTownhallNewsAsync = factory.createAsync('GET_TOWNHALL_NEWS_ASYNC');

export const getTownhallFundraisers = factory.create('GET_TOWNHALL_FUNDRAISERS');
export const getTownhallFundraisersAsync = factory.createAsync('GET_TOWNHALL_FUNDRAISERS_ASYNC');

export const townhallFundraisersDonate = factory.create('TOWNHALL_FUNDRAISER_DONATE');
export const townhallFundraisersDonateAsync = factory.createAsync('TOWNHALL_FUNDRAISER_DONATE_ASYNC');

export const clearTownhallError = factory.create('CLEAR_TOWNHALL_ERROR');
export const clearTownhallErrorAsync = factory.createAsync('CLEAR_TOWNHALL_ERROR_ASYNC');
