import './style.sass';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';

import CloseIcon from '../../Icons/CloseIcon';

export default ({ item, count, onClose, closeModal }) => {
	const { t, i18n } = useTranslation();

	const closeModalWrapper = () => {
		closeModal();

		onClose();
	};

	return (
		<div
			onClick={(e) => (e.target.classList.contains('crafted-item-wrapper') ? closeModalWrapper() : null)}
			className={classNames({ modal: true, 'crafted-item-wrapper': true })}
		>
			<div className={classNames({ modal: true, 'crafted-item': true })}>
				<div className={classNames({ header: true })}>
					<span className={classNames({ 'header-title': true })}>{t('crafted-item-modal.header.title')}</span>
					<div
						className={classNames({ 'header-close': true })}
						onClick={closeModalWrapper}
					>
						<CloseIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					</div>
				</div>
				<div className={classNames({ content: true })}>
					<div className={classNames({ item: true })}>
						<div className={classNames({ 'item-image-wrapper': true })}>
							<img
								className={classNames({ 'item-image': true })}
								src={item.image}
							/>
							<span className={classNames({ 'item-count': true })}>x{count}</span>
						</div>
						<span className={classNames({ 'item-name': true })}>{item.name[i18n.languages[0]]}</span>
					</div>
				</div>
			</div>
		</div>
	);
};
