import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('BANK');

export const getBankDeposits = factory.create('GET_BANK_DEPOSITS');
export const getBankDepositsAsync = factory.createAsync('GET_BANK_DEPOSITS_ASYNC');

export const bankDepositFunds = factory.create('BANK_DEPOSIT_FUNDS');
export const bankDepositFundsAsync = factory.createAsync('BANK_DEPOSIT_FUNDS_ASYNC');

export const bankWithdrawFunds = factory.create('BANK_WITHDRAW_FUNDS');
export const bankWithdrawFundsAsync = factory.createAsync('BANK_WITHDRAW_FUNDS_ASYNC');

export const clearBankError = factory.create('CLEAR_BANK_ERROR');
export const clearBankErrorAsync = factory.createAsync('CLEAR_BANK_ERROR_ASYNC');
