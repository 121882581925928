import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../components/Buttons/DefaultButton';
import AvatarRenderer from '../../components/AvatarRenderer';
import getContrastColor from '../../utils/colors/getContrastColor';

import { changeAvatarBeard } from '../../store/actions/createAvatar';
import { openModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		appearance: s.createAvatarManager.appearance,
		wearable: s.createAvatarManager.wearable,
		gender: s.createAvatarManager.gender,
		race: s.createAvatarManager.race,
	}),
	{
		openModal,
		changeAvatarBeard,
	}
)(({ appearance, wearable, gender, race, openModal, changeAvatarBeard }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={classNames({ container: true, 'create-avatar-beard': true })}>
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'avatar-wrapper': true })}>
					<AvatarRenderer
						appearance={appearance}
						withUnderwear={true}
						wearable={wearable}
						gender={gender}
						race={race}
					/>
				</div>
				<div className={classNames({ 'settings-wrapper': true })}>
					<div className={classNames({ 'pick-part': true })}>
						<span className={classNames({ title: true })}>{t('create-avatar-beard.pick-beard')}</span>
						<div className={classNames({ type: true })}>
							<DefaultButton
								fontSize={12}
								type={'primary'}
								text={
									appearance.beard.type != false
										? t('create-avatar-beard.type', { type: appearance.beard.type + 1 })
										: t('create-avatar-beard.none')
								}
								onClick={() =>
									openModal({
										name: 'type-picker',
										payload: {
											title: t('create-avatar-beard.modals.beard'),
											types: [false, 0, 1, 2].map((type) => ({
												name: type !== false ? t('create-avatar-beard.type', { type: type + 1 }) : t('create-avatar-beard.none'),
												value: type,
											})),
											type: appearance.beard.type,
											setType: (type) => changeAvatarBeard({ name: 'type', value: type }),
											withoutBackground: true,
											withoutPadding: true,
										},
									})
								}
							/>
						</div>
					</div>
					<div className={classNames({ 'pick-part': true })}>
						<span className={classNames({ title: true })}>{t('create-avatar-beard.pick-beard-color')}</span>
						<div className={classNames({ type: true })}>
							<DefaultButton
								fontSize={12}
								background={appearance.beard.color}
								color={getContrastColor(appearance.beard.color)}
								text={appearance.beard.color}
								onClick={() =>
									openModal({
										name: 'color-picker',
										payload: {
											Color: appearance.beard.color,
											SetColor: (color) => changeAvatarBeard({ name: 'color', value: color }),
											withoutBackground: true,
											withoutPadding: true,
										},
									})
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={classNames({ 'bottom-buttons': true })}>
				<DefaultButton
					type={'secondary'}
					text={t('create-avatar-beard.buttons.back')}
					onClick={() => navigate('/create-avatar-eyebrows-eyes-mouth')}
				/>
				<DefaultButton
					text={t('create-avatar-beard.buttons.next')}
					onClick={() =>
						race === 'demon' ? navigate('/create-avatar-horns-wings-tail') : navigate('/create-avatar-wearable-body')
					}
				/>
			</div>
		</div>
	);
});
