import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			preserveAspectRatio='xMidYMid meet'
		>
			<g
				transform='translate(0, 512) scale(0.100000,-0.100000)'
				fill={fill}
				stroke='none'
			>
				<path
					d='M1210 4809 c-577 -69 -966 -373 -1128 -879 -58 -181 -76 -325 -77
-595 0 -223 2 -243 21 -286 26 -57 78 -97 135 -107 89 -15 352 26 506 79 31
10 60 16 63 12 4 -5 15 -57 24 -118 37 -239 107 -514 217 -854 111 -344 188
-724 215 -1059 l7 -82 -129 -193 -129 -192 0 -65 c0 -77 24 -119 85 -150 39
-20 65 -20 1540 -20 1475 0 1501 0 1540 20 53 27 83 72 88 135 5 64 3 69 -146
292 l-116 174 13 132 c33 362 99 661 245 1121 85 268 171 629 192 810 4 31 10
56 14 56 4 0 32 -9 64 -20 109 -37 290 -72 401 -77 100 -5 115 -3 153 16 66
34 93 84 104 193 23 214 -7 546 -67 753 -100 343 -313 604 -620 758 -213 106
-492 163 -767 155 -178 -5 -219 -28 -236 -131 -7 -42 -8 -42 -127 -104 -474
-246 -995 -246 -1470 0 -119 62 -120 62 -127 104 -9 53 -32 89 -72 111 -25 13
-62 17 -186 19 -85 0 -189 -3 -230 -8z m315 -246 c-28 -388 -113 -666 -278
-913 -27 -41 -98 -122 -157 -180 -155 -153 -310 -243 -525 -305 -108 -31 -250
-55 -327 -55 l-56 0 -7 98 c-21 274 26 587 119 803 141 325 449 551 836 613
136 22 142 23 278 25 l124 1 -7 -87z m2470 62 c108 -19 270 -72 357 -116 267
-134 453 -369 537 -679 48 -177 71 -430 56 -624 l-7 -99 -97 6 c-394 22 -768
230 -974 538 -160 242 -241 514 -273 918 l-6 84 158 -6 c88 -3 200 -13 249
-22z m-2005 -300 c134 -46 304 -82 448 -95 268 -24 597 39 875 168 58 27 87
36 87 27 0 -37 -56 -199 -96 -276 -127 -245 -307 -392 -594 -484 -112 -37
-188 -37 -300 0 -293 94 -476 246 -605 503 -31 62 -85 225 -85 257 0 9 29 0
88 -27 48 -22 130 -55 182 -73z m-315 -273 c88 -168 249 -337 415 -436 94 -56
166 -86 287 -121 48 -14 88 -27 90 -29 2 -2 2 -126 1 -276 l-3 -273 -120 93
c-240 186 -571 477 -757 667 l-78 78 40 102 c22 55 50 137 62 182 13 44 24 81
25 81 1 0 18 -31 38 -68z m1896 -197 l39 -100 -83 -83 c-192 -194 -500 -465
-752 -662 l-120 -93 -3 273 c-1 151 1 277 5 280 5 4 46 17 93 29 287 75 567
300 702 566 l30 59 25 -84 c13 -47 42 -130 64 -185z m-1788 -598 c151 -135
328 -282 489 -405 76 -58 135 -108 132 -113 -4 -7 -250 -180 -358 -252 l-38
-25 -92 70 c-220 171 -468 402 -667 623 l-108 119 98 101 c53 55 116 128 140
163 l43 64 126 -124 c70 -68 176 -167 235 -221z m1959 281 c23 -35 86 -108
139 -163 l98 -101 -108 -119 c-309 -343 -641 -625 -1146 -973 -369 -254 -946
-597 -1313 -781 -85 -43 -102 -44 -102 -10 0 18 -60 309 -76 365 -5 19 17 35
193 140 503 298 1063 675 1430 961 244 189 604 506 773 677 35 36 66 66 67 66
1 0 21 -28 45 -62z m-2639 -475 c189 -214 482 -490 685 -646 34 -27 62 -50 62
-53 0 -10 -660 -418 -666 -411 -10 11 -154 499 -185 622 -39 160 -83 389 -95
489 l-6 53 43 26 c24 14 49 26 55 27 6 0 54 -48 107 -107z m3074 80 l45 -26
-6 -53 c-29 -252 -113 -589 -258 -1041 l-23 -73 -245 148 c-258 156 -420 258
-420 266 0 3 28 26 63 53 198 152 512 448 689 651 50 56 95 102 100 102 5 0
30 -12 55 -27z m-916 -981 c68 -44 234 -146 369 -226 135 -81 248 -151 252
-155 4 -3 -8 -76 -27 -161 -19 -85 -38 -176 -41 -203 -4 -26 -11 -50 -16 -53
-21 -13 -703 366 -1011 563 l-69 44 99 68 c54 37 143 100 198 139 55 40 105
70 111 68 6 -2 67 -40 135 -84z m-791 -767 l0 -415 -554 0 -554 0 -7 88 c-4
48 -7 91 -7 96 0 5 85 54 189 108 228 120 609 337 793 452 74 46 136 85 138
85 1 1 2 -186 2 -414z m644 136 c83 -48 230 -129 326 -181 96 -51 211 -112
255 -136 l80 -43 -9 -95 -9 -96 -554 0 -553 0 0 415 0 414 157 -96 c86 -52
224 -134 307 -182z m794 -891 l112 -170 -1460 0 -1460 0 112 170 113 169 1235
0 1235 0 113 -169z'
				/>
				<path
					d='M974 4411 c-144 -50 -288 -142 -378 -242 -90 -99 -181 -275 -193
-372 -5 -41 -2 -51 17 -72 35 -37 90 -35 124 6 14 17 26 38 26 47 0 10 23 64
51 121 40 82 65 117 118 171 80 80 193 149 294 180 111 34 127 41 139 63 17
33 11 77 -13 103 -31 33 -83 31 -185 -5z'
				/>
			</g>
		</svg>
	);
};
