import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			style={{
				shapeRendering: 'geometricPrecision',
				textRendering: 'geometricPrecision',
				imageRendering: 'optimizeQuality',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			}}
		>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.989 }}
					d='M 249.5,31.5 C 253.514,31.3345 257.514,31.5012 261.5,32C 329.167,68.3333 396.833,104.667 464.5,141C 471.635,145.763 476.469,152.263 479,160.5C 479.667,173.5 479.667,186.5 479,199.5C 475.833,212 468,219.833 455.5,223C 447.507,223.5 439.507,223.666 431.5,223.5C 431.5,276.833 431.5,330.167 431.5,383.5C 439.507,383.334 447.507,383.5 455.5,384C 468,387.167 475.833,395 479,407.5C 479.667,423.5 479.667,439.5 479,455.5C 475.833,468 468,475.833 455.5,479C 322.167,479.667 188.833,479.667 55.5,479C 43,475.833 35.1667,468 32,455.5C 31.3333,439.5 31.3333,423.5 32,407.5C 35.1667,395 43,387.167 55.5,384C 63.4931,383.5 71.4931,383.334 79.5,383.5C 79.5,330.167 79.5,276.833 79.5,223.5C 71.4931,223.666 63.4931,223.5 55.5,223C 43,219.833 35.1667,212 32,199.5C 31.3333,186.5 31.3333,173.5 32,160.5C 34.5314,152.263 39.3648,145.763 46.5,141C 114.331,104.636 181.998,68.1359 249.5,31.5 Z M 254.5,66.5 C 318.605,100.387 382.605,134.554 446.5,169C 447.481,176.413 447.814,183.913 447.5,191.5C 319.5,191.5 191.5,191.5 63.5,191.5C 63.1858,183.913 63.5192,176.413 64.5,169C 128.055,135.059 191.388,100.892 254.5,66.5 Z M 111.5,223.5 C 127.5,223.5 143.5,223.5 159.5,223.5C 159.5,276.833 159.5,330.167 159.5,383.5C 143.5,383.5 127.5,383.5 111.5,383.5C 111.5,330.167 111.5,276.833 111.5,223.5 Z M 191.5,223.5 C 207.5,223.5 223.5,223.5 239.5,223.5C 239.5,276.833 239.5,330.167 239.5,383.5C 223.5,383.5 207.5,383.5 191.5,383.5C 191.5,330.167 191.5,276.833 191.5,223.5 Z M 271.5,223.5 C 287.5,223.5 303.5,223.5 319.5,223.5C 319.5,276.833 319.5,330.167 319.5,383.5C 303.5,383.5 287.5,383.5 271.5,383.5C 271.5,330.167 271.5,276.833 271.5,223.5 Z M 352.5,223.5 C 368.167,223.5 383.833,223.5 399.5,223.5C 399.5,276.833 399.5,330.167 399.5,383.5C 383.833,383.5 368.167,383.5 352.5,383.5C 352.5,330.167 352.5,276.833 352.5,223.5 Z M 63.5,415.5 C 191.5,415.5 319.5,415.5 447.5,415.5C 447.5,426.167 447.5,436.833 447.5,447.5C 319.5,447.5 191.5,447.5 63.5,447.5C 63.5,436.833 63.5,426.167 63.5,415.5 Z'
				/>
			</g>
		</svg>
	);
};
