import React from 'react';

export default ({ width = 19, height = 17, stroke = '#ffffff' }) => {
	return (
		<svg
			fill='none'
			width={width}
			height={height}
			viewBox='0 0 19 17'
			style={{ minWidth: width, minHeight: height, rotate: '-90deg' }}
		>
			<line
				y1='-1'
				x2='12.207'
				y2='-1'
				transform='matrix(0.718897 0.695117 -0.718897 0.695117 0.576904 8.48529)'
				stroke={stroke}
				strokeWidth='2'
			/>
			<line
				y1='-1'
				x2='12.207'
				y2='-1'
				transform='matrix(0.718897 -0.695117 0.718897 0.695117 9.35254 16.9706)'
				stroke={stroke}
				strokeWidth='2'
			/>
		</svg>
	);
};
