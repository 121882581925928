import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('LOCATIONS');

export const getLocations = factory.create('GET_LOCATIONS');
export const getLocationsAsync = factory.createAsync('GET_LOCATIONS_ASYNC');

export const getAvatarLocation = factory.create('GET_AVATAR_LOCATIONS');
export const getAvatarLocationAsync = factory.createAsync('GET_AVATAR_LOCATIONS_ASYNC');

export const takeJourney = factory.create('TAKE_JOURNEY');
export const takeJourneyAsync = factory.createAsync('TAKE_JOURNEY_ASYNC');

export const updateLocationTarget = factory.create('UPDATE_LOCATION_TARGET');
export const updateLocationTargetAsync = factory.createAsync('UPDATE_LOCATION_TARGET_ASYNC');

export const clearLocation = factory.create('CLEAR_LOCATION');
export const clearLocationAsync = factory.createAsync('CLEAR_LOCATION_ASYNC');

export const clearLocationsError = factory.create('CLEAR_LOCATIONS_ERROR');
export const clearLocationsErrorAsync = factory.createAsync('CLEAR_LOCATIONS_ERROR_ASYNC');
