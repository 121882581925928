import { handleActions } from 'redux-actions';

import {
	getGuildTasksAsync,
	getReferralsCountAsync,
	takeGuildTaskAsync,
	getDailyCheckInMangerAsync,
	updateDailyCheckInWalletAsync,
	confirmDailyCheckInAsync,
	completeDailyCheckInAsync,
	clearGuildErrorAsync,
} from '../../actions/guild';

import { defaultError } from '../../../common/errors';

const initialState = {
	referrals: {
		count: 0,
	},
	dailyCheckInManager: {
		streak: 0,
		wallet: { address: '', connectedAt: null },
		lastCheckInAt: null,
	},
	tasks: [],
	error: null,
};

export default handleActions(
	{
		[getGuildTasksAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			tasks: responseData.success ? responseData.result.tasks : [],
		}),
		[getReferralsCountAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			referrals: { ...s.referrals, count: responseData.success ? responseData.result.referrals : 0 },
		}),
		[takeGuildTaskAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			tasks: responseData.success ? s.tasks.filter((v) => v._id != responseData.guildTaskId) : [],
		}),
		[getDailyCheckInMangerAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			dailyCheckInManager: responseData.success
				? responseData.result.manager
				: { streak: 0, wallet: { address: '', connectedAt: null }, lastCheckInAt: null },
		}),
		[updateDailyCheckInWalletAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			dailyCheckInManager: responseData.success
				? { ...s.dailyCheckInManager, ...responseData.result.manager }
				: s.dailyCheckInManager,
		}),
		[confirmDailyCheckInAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			dailyCheckInManager: responseData.success
				? { ...s.dailyCheckInManager, lastCheckInTransaction: responseData.result.lastCheckInTransaction }
				: s.dailyCheckInManager,
		}),
		[completeDailyCheckInAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			dailyCheckInManager: {
				...s.dailyCheckInManager,
				lastCheckInAt: new Date(),
				lastCheckInTransaction: null,
				streak: s.dailyCheckInManager.streak,
			},
		}),
		[clearGuildErrorAsync.success]: (s, p) => ({ ...s, error: null }),

		[getGuildTasksAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			tasks: [],
		}),
		[takeGuildTaskAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
		[getDailyCheckInMangerAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			dailyCheckInManager: { streak: 0, wallet: { address: '', connectedAt: null }, lastCheckInAt: null },
		}),
		[updateDailyCheckInWalletAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
		[confirmDailyCheckInAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
	},
	initialState
);
