import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

export default connect(
	(s) => ({
		user: s.userManager.state,
		avatar: s.avatarManager.state,
		userError: s.userManager.error,
		validated: s.userManager.validated,
		whitelisted: s.userManager.whitelisted,
	}),
	{}
)(({ user, avatar, userError, validated, whitelisted }) => {
	const navigate = useNavigate();

	React.useEffect(() => {
		if (validated === true && whitelisted === false) {
			navigate('/not-white-listed', { replace: true });
		} else if (validated === true && avatar) {
			navigate('/avatar-info', { replace: true });
		} else if (validated === true && user) {
			navigate('/welcome', { replace: true });
		} else if (userError) {
			navigate('/something-went-wrong', { replace: true });
		}
	}, [whitelisted, validated, userError, avatar, user]);

	return (
		<div className={classNames({ container: true, loading: true })}>
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'loader-wrapper': true })}>
					<span className={classNames({ loader: true })} />
				</div>
			</div>
		</div>
	);
});
