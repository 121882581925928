import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../components/Buttons/DefaultButton';
import AvatarRenderer from '../../components/AvatarRenderer';

import { changeAvatarGender, changeAvatarRace } from './../../store/actions/createAvatar';

export default connect(
	(s) => ({
		appearance: s.createAvatarManager.appearance,
		wearable: s.createAvatarManager.wearable,
		gender: s.createAvatarManager.gender,
		race: s.createAvatarManager.race,
	}),
	{
		changeAvatarGender,
		changeAvatarRace,
	}
)(({ appearance, wearable, gender, race, changeAvatarGender, changeAvatarRace }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={classNames({ container: true, 'create-avatar-gender-race': true })}>
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'avatar-wrapper': true })}>
					<AvatarRenderer
						appearance={appearance}
						withUnderwear={true}
						wearable={wearable}
						gender={gender}
						race={race}
					/>
				</div>
				<div className={classNames({ 'settings-wrapper': true })}>
					<div className={classNames({ 'pick-gender': true })}>
						<span className={classNames({ title: true })}>{t('create-avatar-gender-race.pick-gender')}</span>
						<div className={classNames({ genders: true })}>
							<DefaultButton
								fontSize={12}
								type={gender == 'male' ? 'primary' : 'secondary'}
								text={t('create-avatar-gender-race.genders.male')}
								onClick={() => changeAvatarGender('male')}
								flex={1}
							/>
							<DefaultButton
								fontSize={12}
								type={gender == 'female' ? 'primary' : 'secondary'}
								text={t('create-avatar-gender-race.genders.female')}
								onClick={() => changeAvatarGender('female')}
								flex={1}
							/>
						</div>
					</div>
					<div className={classNames({ 'pick-race': true })}>
						<span className={classNames({ title: true })}>{t('create-avatar-gender-race.pick-race')}</span>
						<div className={classNames({ races: true })}>
							<DefaultButton
								fontSize={12}
								type={race == 'human' ? 'primary' : 'secondary'}
								text={t('create-avatar-gender-race.races.human')}
								onClick={() => changeAvatarRace('human')}
								flex={1}
							/>
							<DefaultButton
								fontSize={12}
								type={race == 'elve' ? 'primary' : 'secondary'}
								text={t('create-avatar-gender-race.races.elve')}
								onClick={() => changeAvatarRace('elve')}
								flex={1}
							/>
						</div>
						<div className={classNames({ races: true })}>
							<DefaultButton
								fontSize={12}
								type={race == 'dwarve' ? 'primary' : 'secondary'}
								text={t('create-avatar-gender-race.races.dwarve')}
								onClick={() => changeAvatarRace('dwarve')}
								flex={1}
							/>
							<DefaultButton
								fontSize={12}
								type={race == 'demon' ? 'primary' : 'secondary'}
								text={t('create-avatar-gender-race.races.demon')}
								onClick={() => changeAvatarRace('demon')}
								flex={1}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={classNames({ 'bottom-buttons': true })}>
				<DefaultButton
					type={'secondary'}
					text={t('create-avatar-gender-race.buttons.back')}
					onClick={() => navigate('/welcome')}
				/>
				<DefaultButton
					text={t('create-avatar-gender-race.buttons.next')}
					onClick={() => navigate('/create-avatar-head-body')}
				/>
			</div>
		</div>
	);
});
