import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../components/Buttons/DefaultButton';
import AvatarRenderer from '../../components/AvatarRenderer';
import getContrastColor from '../../utils/colors/getContrastColor';

import { changeAvatarAppearanceTypeSimple } from './../../store/actions/createAvatar';
import { openModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		appearance: s.createAvatarManager.appearance,
		wearable: s.createAvatarManager.wearable,
		gender: s.createAvatarManager.gender,
		race: s.createAvatarManager.race,
	}),
	{
		openModal,
		changeAvatarAppearanceTypeSimple,
	}
)(({ appearance, wearable, gender, race, openModal, changeAvatarAppearanceTypeSimple }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={classNames({ container: true, 'create-avatar-head-body': true })}>
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'avatar-wrapper': true })}>
					<AvatarRenderer
						appearance={appearance}
						withUnderwear={true}
						wearable={wearable}
						gender={gender}
						race={race}
					/>
				</div>
				<div className={classNames({ 'settings-wrapper': true })}>
					<div className={classNames({ 'pick-head': true })}>
						<span className={classNames({ title: true })}>{t('create-avatar-head-body.pick-head')}</span>
						<div className={classNames({ heads: true })}>
							<DefaultButton
								fontSize={12}
								type={appearance.head == 0 ? 'primary' : 'secondary'}
								text={t('create-avatar-head-body.heads.small')}
								onClick={() => changeAvatarAppearanceTypeSimple({ name: 'head', value: 0 })}
								flex={1}
							/>
							<DefaultButton
								fontSize={12}
								type={appearance.head == 1 ? 'primary' : 'secondary'}
								text={t('create-avatar-head-body.heads.middle')}
								onClick={() => changeAvatarAppearanceTypeSimple({ name: 'head', value: 1 })}
								flex={1}
							/>
							<DefaultButton
								fontSize={12}
								type={appearance.head == 2 ? 'primary' : 'secondary'}
								text={t('create-avatar-head-body.heads.big')}
								onClick={() => changeAvatarAppearanceTypeSimple({ name: 'head', value: 2 })}
								flex={1}
							/>
						</div>
					</div>
					<div className={classNames({ 'pick-body': true })}>
						<span className={classNames({ title: true })}>{t('create-avatar-head-body.pick-body')}</span>
						<div className={classNames({ bodies: true })}>
							<DefaultButton
								fontSize={12}
								type={appearance.body == (gender == 'male' ? 0 : 2) ? 'primary' : 'secondary'}
								text={t('create-avatar-head-body.bodies.small')}
								onClick={() => changeAvatarAppearanceTypeSimple({ name: 'body', value: gender == 'male' ? 0 : 2 })}
								flex={1}
							/>
							<DefaultButton
								fontSize={12}
								type={appearance.body == 1 ? 'primary' : 'secondary'}
								text={t('create-avatar-head-body.bodies.middle')}
								onClick={() => changeAvatarAppearanceTypeSimple({ name: 'body', value: 1 })}
								flex={1}
							/>
							<DefaultButton
								fontSize={12}
								type={appearance.body == (gender == 'male' ? 2 : 0) ? 'primary' : 'secondary'}
								text={t('create-avatar-head-body.bodies.big')}
								onClick={() => changeAvatarAppearanceTypeSimple({ name: 'body', value: gender == 'male' ? 2 : 0 })}
								flex={1}
							/>
						</div>
					</div>
					<div className={classNames({ 'pick-skin': true })}>
						<span className={classNames({ title: true })}>{t('create-avatar-head-body.pick-skin-color')}</span>
						<div className={classNames({ color: true })}>
							<DefaultButton
								fontSize={12}
								background={appearance.skin}
								color={getContrastColor(appearance.skin)}
								text={appearance.skin}
								onClick={() =>
									openModal({
										name: 'color-picker',
										payload: {
											Color: appearance.skin,
											SetColor: (color) => changeAvatarAppearanceTypeSimple({ name: 'skin', value: color }),
											withoutBackground: true,
											withoutPadding: true,
										},
									})
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={classNames({ 'bottom-buttons': true })}>
				<DefaultButton
					type={'secondary'}
					text={t('create-avatar-head-body.buttons.back')}
					onClick={() => navigate('/create-avatar-gender-race')}
				/>
				<DefaultButton
					text={t('create-avatar-head-body.buttons.next')}
					onClick={() => navigate('/create-avatar-hairs')}
				/>
			</div>
		</div>
	);
});
