import './style.sass';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../Buttons/DefaultButton';
import CloseIcon from '../../Icons/CloseIcon';

export default ({ skill, price, onConfirm, closeModal }) => {
	const { t } = useTranslation();

	return (
		<div className={classNames({ modal: true, 'confirm-level-upgrade': true })}>
			<div className={classNames({ 'modal-header': true })}>
				<span className={classNames({ 'modal-header-title': true })}>{t('confirm-level-upgrade-modal.header.title')}</span>
				<div
					className={classNames({ 'modal-header-close': true })}
					onClick={closeModal}
				>
					<CloseIcon
						width={16}
						height={16}
						fill={'#ffffff'}
					/>
				</div>
			</div>
			<span className={classNames({ description: true })}>
				{t('confirm-level-upgrade-modal.description', { type: t(`confirm-level-upgrade-modal.types.${skill}`), price })}{' '}
			</span>
			<DefaultButton
				text={t('confirm-level-upgrade-modal.buttons.confirm')}
				onClick={onConfirm}
				type={'danger'}
				fontSize={20}
				height={50}
			/>
		</div>
	);
};
