import './style.sass';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import CloseIcon from '../../Icons/CloseIcon';

import { guesthouseChestMove } from '../../../store/actions/guesthouse';

export default connect(
	(s) => ({
		inventory: s.inventoryManager.avatar,
		chest: s.guesthouseManager.chest,
	}),
	{ guesthouseChestMove }
)(({ inventory, chest, closeModal, guesthouseChestMove }) => {
	const [InventoryItems, SetInventoryItems] = React.useState([]);
	const [ChestItems, SetChestItems] = React.useState([]);
	const { t } = useTranslation();

	React.useEffect(() => {
		const formattedItems = [];
		const itemMap = new Map();

		for (const item of inventory.items) {
			if (item.stackable) {
				if (itemMap.has(item.id)) {
					itemMap.get(item.id).count += 1;
					itemMap.get(item.id).ids.push(item._id);
				} else {
					itemMap.set(item.id, { ids: [item._id], item: item, count: 1 });
				}
			} else {
				formattedItems.push({ ids: [item._id], item: item, count: 1 });
			}
		}

		for (const formattedItem of itemMap.values()) {
			formattedItems.push(formattedItem);
		}

		SetInventoryItems(formattedItems);
	}, [inventory]);

	React.useEffect(() => {
		const formattedItems = [];
		const itemMap = new Map();

		for (const item of chest) {
			if (item.stackable) {
				if (itemMap.has(item.id)) {
					itemMap.get(item.id).count += 1;
					itemMap.get(item.id).ids.push(item._id);
				} else {
					itemMap.set(item.id, { ids: [item._id], item: item, count: 1 });
				}
			} else {
				formattedItems.push({ ids: [item._id], item: item, count: 1 });
			}
		}

		for (const formattedItem of itemMap.values()) {
			formattedItems.push(formattedItem);
		}

		SetChestItems(formattedItems);
	}, [chest]);

	return (
		<div className={classNames({ modal: true, 'guesthouse-chest': true })}>
			<div className={classNames({ 'modal-header': true })}>
				<div
					className={classNames({ 'modal-header-close': true })}
					onClick={closeModal}
				>
					<CloseIcon
						width={16}
						height={16}
						fill={'#ffffff'}
					/>
				</div>
				<span className={classNames({ 'modal-header-title': true })}>{t(`guesthouse-chest-modal.header.title`)}</span>
			</div>
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'items-wrapper': true })}>
					<span className={classNames({ 'items-title': true })}>{t(`guesthouse-chest-modal.chest`)}</span>
					<div className={classNames({ items: true })}>
						{ChestItems.map((v, i) => (
							<div
								onClick={() => guesthouseChestMove({ to: 'inventory', itemId: v.ids[0] })}
								className={classNames({ item: true })}
								key={i}
							>
								<img
									className={classNames({ 'item-image': true })}
									src={v.item.image}
								/>
								<span className={classNames({ 'item-count': true })}>x{v.count}</span>
							</div>
						))}
					</div>
				</div>
				<div className={classNames({ 'items-wrapper': true })}>
					<span className={classNames({ 'items-title': true })}>
						{t(`guesthouse-chest-modal.inventory`, {
							current: (inventory.capacity.current / 1000).toFixed(1),
							maximum: (inventory.capacity.maximum / 1000).toFixed(1),
						})}
					</span>
					<div className={classNames({ items: true })}>
						{InventoryItems.map((v, i) => (
							<div
								onClick={() => guesthouseChestMove({ to: 'chest', itemId: v.ids[0] })}
								className={classNames({ item: true })}
								key={i}
							>
								<img
									className={classNames({ 'item-image': true })}
									src={v.item.image}
								/>
								<span className={classNames({ 'item-count': true })}>x{v.count}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
});
