import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import {
	changeAvatarName,
	changeAvatarNameAsync,
	changeAvatarGender,
	changeAvatarGenderAsync,
	changeAvatarRace,
	changeAvatarRaceAsync,
	changeAvatarAppearanceTypeSimple,
	changeAvatarAppearanceTypeSimpleAsync,
	changeAvatarHairs,
	changeAvatarHairsAsync,
	changeAvatarEyes,
	changeAvatarEyesAsync,
	changeAvatarBeard,
	changeAvatarBeardAsync,
	changeAvatarWings,
	changeAvatarWingsAsync,
	changeAvatarHorns,
	changeAvatarHornsAsync,
	changeWearableBody,
	changeWearableBodyAsync,
	changeWearableLeggs,
	changeWearableLeggsAsync,
	changeWearableBoots,
	changeWearableBootsAsync,
} from '../../actions/createAvatar';

function changeAvatarNameWorker(name) {
	return { name };
}

function changeAvatarGenderWorker(gender) {
	return { gender };
}

function changeAvatarRaceWorker(race) {
	return { race };
}

function changeAvatarAppearanceTypeSimpleWorker({ name, value }) {
	return { name, value };
}

function changeAvatarHairsWorker({ name, value }) {
	return { name, value };
}

function changeAvatarEyesWorker({ name, value }) {
	return { name, value };
}

function changeAvatarBeardWorker({ name, value }) {
	return { name, value };
}

function changeAvatarWingsWorker({ name, value }) {
	return { name, value };
}

function changeAvatarHornsWorker({ name, value }) {
	return { name, value };
}

function changeWearableBodyWorker({ name, value }) {
	return { name, value };
}

function changeWearableLeggsWorker({ name, value }) {
	return { name, value };
}

function changeWearableBootsWorker({ name, value }) {
	return { name, value };
}

export function* createAvatarSaga() {
	yield takeEvery(changeAvatarName, bindAsyncActions(changeAvatarNameAsync)(changeAvatarNameWorker));
	yield takeEvery(changeAvatarGender, bindAsyncActions(changeAvatarGenderAsync)(changeAvatarGenderWorker));
	yield takeEvery(changeAvatarRace, bindAsyncActions(changeAvatarRaceAsync)(changeAvatarRaceWorker));
	yield takeEvery(
		changeAvatarAppearanceTypeSimple,
		bindAsyncActions(changeAvatarAppearanceTypeSimpleAsync)(changeAvatarAppearanceTypeSimpleWorker)
	);
	yield takeEvery(changeAvatarHairs, bindAsyncActions(changeAvatarHairsAsync)(changeAvatarHairsWorker));
	yield takeEvery(changeAvatarEyes, bindAsyncActions(changeAvatarEyesAsync)(changeAvatarEyesWorker));
	yield takeEvery(changeAvatarBeard, bindAsyncActions(changeAvatarBeardAsync)(changeAvatarBeardWorker));
	yield takeEvery(changeAvatarWings, bindAsyncActions(changeAvatarWingsAsync)(changeAvatarWingsWorker));
	yield takeEvery(changeAvatarHorns, bindAsyncActions(changeAvatarHornsAsync)(changeAvatarHornsWorker));
	yield takeEvery(changeWearableBody, bindAsyncActions(changeWearableBodyAsync)(changeWearableBodyWorker));
	yield takeEvery(changeWearableLeggs, bindAsyncActions(changeWearableLeggsAsync)(changeWearableLeggsWorker));
	yield takeEvery(changeWearableBoots, bindAsyncActions(changeWearableBootsAsync)(changeWearableBootsWorker));
}
