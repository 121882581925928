import { handleActions } from 'redux-actions';

import { openModalAsync, closeModalAsync } from '../../actions/modals';

const initialState = {
	name: '',
	payload: null,
};

export default handleActions(
	{
		[openModalAsync.success]: (s, { payload: { name, payload } } = {}) => ({ ...s, name, payload }),
		[closeModalAsync.success]: (s, a) => ({ ...s, name: '', payload: null }),
	},
	initialState
);
