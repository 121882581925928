import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../components/Buttons/DefaultButton';
import CloseIcon from '../../components/Icons/CloseIcon';

import { takeGuildTask } from '../../store/actions/guild';
import { openModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		guildTasks: s.avatarManager.tasks.guild,
	}),
	{
		takeGuildTask,
		openModal,
	}
)(({ guildTasks, takeGuildTask, openModal }) => {
	const [GuildTasksCount, SetGuildTasksCount] = React.useState(guildTasks.length);
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const {
		state: { task },
	} = useLocation();

	const takeGuildTaskWrapper = (taskId) => {
		takeGuildTask({ taskId });
	};

	React.useEffect(() => {
		if (GuildTasksCount < guildTasks.length) {
			navigate('/tasks', { replace: true });
			navigate('/task-info', { state: { task: guildTasks[0], taskType: 'guild' } });
		} else {
			SetGuildTasksCount(guildTasks.length);
		}
	}, [guildTasks]);

	return (
		<div className={classNames({ container: true, 'guild-task-info': true })}>
			<div className={classNames({ 'task-info-header': true })}>
				<div
					className={classNames({ 'task-info-header-close': true })}
					onClick={() => navigate(-1)}
				>
					<CloseIcon
						width={16}
						height={16}
						fill={'#ffffff'}
					/>
				</div>
				<span className={classNames({ 'task-info-header-title': true })}>{t(`guild-task-info.header.title`)}</span>
			</div>
			<div className={classNames({ content: true })}>
				<div
					style={{ backgroundImage: `url(${task.cover})` }}
					className={classNames({ cover: true })}
				></div>
				<div className={classNames({ info: true })}>
					<span className={classNames({ 'task-name': true })}>{task.name[i18n.languages[0]]}</span>
					<div className={classNames({ steps: true })}>
						<div className={classNames({ 'task-description': true })}>
							<img
								className={classNames({ 'task-description-image': true })}
								src={task.image}
							/>
							<div className={classNames({ 'task-description-info': true })}>
								<span className={classNames({ 'task-description-info-title': true })}>
									{t('guild-task-info.description-title')}
								</span>
								<span className={classNames({ 'task-description-info-text': true })}>{task.description[i18n.languages[0]]}</span>
								<div className={classNames({ 'task-description-reward': true })}>
									{task.reward.map((v, i) => (
										<div
											className={classNames({ item: true })}
											key={i}
										>
											<img
												className={classNames({ 'item-image': true })}
												src={v.type == 'currency' ? 'https://public.synergy-hero.com/v2/other/nanits.png' : v.item.image}
											/>
											<span className={classNames({ 'item-count': true })}>x{v.count}</span>
										</div>
									))}
								</div>
							</div>
						</div>
						{task.steps.map((v, i) => (
							<div
								className={classNames({ step: true })}
								onClick={() => (v.type == 'link' ? window.open(v.href, '_blank') : null)}
								key={i}
							>
								<img
									className={classNames({ 'step-image': true })}
									src={v.image}
								/>
								<span className={classNames({ 'step-description': true })}>{v.description[i18n.languages[0]]}</span>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className={classNames({ 'action-button': true })}>
				<DefaultButton
					text={t('guild-task-info.buttons.take')}
					onClick={() => takeGuildTaskWrapper(task._id)}
					// onClick={() => openModal({ name: 'guild-tasks-limit-reached', payload: { type: 'center' } })}
					type={'primary'}
					fontSize={20}
					height={50}
				/>
			</div>
		</div>
	);
});
