import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('CREATE_AVATAR');

export const changeAvatarName = factory.create('CHANGE_NAME');
export const changeAvatarNameAsync = factory.createAsync('CHANGE_NAME_ASYNC');

export const changeAvatarGender = factory.create('CHANGE_GENDER');
export const changeAvatarGenderAsync = factory.createAsync('CHANGE_GENDER_ASYNC');

export const changeAvatarRace = factory.create('CHANGE_RACE');
export const changeAvatarRaceAsync = factory.createAsync('CHANGE_RACE_ASYNC');

export const changeAvatarAppearanceTypeSimple = factory.create('CHANGE_APPEARANCE_TYPE_SIMPLE');
export const changeAvatarAppearanceTypeSimpleAsync = factory.createAsync('CHANGE_APPEARANCE_TYPE_SIMPLE_ASYNC');

export const changeAvatarHairs = factory.create('CHANGE_HAIRS');
export const changeAvatarHairsAsync = factory.createAsync('CHANGE_HAIRS_ASYNC');

export const changeAvatarEyes = factory.create('CHANGE_EYES');
export const changeAvatarEyesAsync = factory.createAsync('CHANGE_EYES_ASYNC');

export const changeAvatarBeard = factory.create('CHANGE_BEARD');
export const changeAvatarBeardAsync = factory.createAsync('CHANGE_BEARD_ASYNC');

export const changeAvatarWings = factory.create('CHANGE_WINGS');
export const changeAvatarWingsAsync = factory.createAsync('CHANGE_WINGS_ASYNC');

export const changeAvatarHorns = factory.create('CHANGE_HORNS');
export const changeAvatarHornsAsync = factory.createAsync('CHANGE_HORNS_ASYNC');

export const changeWearableBody = factory.create('CHANGE_WEARABLE_BODY');
export const changeWearableBodyAsync = factory.createAsync('CHANGE_WEARABLE_BODY_ASYNC');

export const changeWearableLeggs = factory.create('CHANGE_WEARABLE_LEGGS');
export const changeWearableLeggsAsync = factory.createAsync('CHANGE_WEARABLE_LEGGS_ASYNC');

export const changeWearableBoots = factory.create('CHANGE_WEARABLE_BOOTS');
export const changeWearableBootsAsync = factory.createAsync('CHANGE_WEARABLE_BOOTS_ASYNC');
