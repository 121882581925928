import axios from 'axios';

import { api_url } from '../../../common/config';

axios.defaults.withCredentials = true;

export default class {
	static getLocationsEndpoint = () => axios.get(`${api_url}locations`);
	static getAvatarLocationEndpoint = () => axios.get(`${api_url}locations/current`);
	static takeJourneyEndpoint = (data) => axios.post(`${api_url}locations/take-journey`, data);
}
