import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import RightArrowIcon from '../../components/Icons/RightArrowIcon';
import MainHeader from '../../components/MainHeader';
import MainNavbar from '../../components/MainNavbar';

import { openModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		bankDeposits: s.bankManager.bank,
		avatarDeposits: s.bankManager.avatar,
		avatarBalance: s.avatarManager.state.nanits,
	}),
	{ openModal }
)(({ bankDeposits, avatarBalance, avatarDeposits, openModal }) => {
	const [BankBalance, SetBankBalance] = React.useState(avatarDeposits.reduce((result, value) => result + value.store, 0));
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	React.useEffect(() => {
		SetBankBalance(avatarDeposits.reduce((result, value) => result + value.store, 0));
	}, [avatarDeposits]);

	return (
		<div className={classNames({ container: true, bank: true })}>
			<MainHeader title={t('bank.header.title')} />
			<div className={classNames({ content: true })}>
				<span className={classNames({ balance: true, strong: true })}>
					{t('bank.your-balance')} <span className={classNames({ balance: true })}>{parseInt(avatarBalance)} n</span>
				</span>
				<span className={classNames({ balance: true, strong: true })}>
					{t('bank.bank-balance')} <span className={classNames({ balance: true })}>{parseInt(BankBalance)} n</span>
				</span>
				<div className={classNames({ deposits: true })}>
					<span className={classNames({ title: true })}>{t('bank.deposits')}</span>
					{bankDeposits.map((v, i) => (
						<div
							onClick={() => navigate('/deposit', { state: { deposit: v } })}
							className={classNames({ deposit: true })}
							key={i}
						>
							<img
								className={classNames({ 'deposit-image': true })}
								src={v.image}
							/>
							<span className={classNames({ 'deposit-name': true })}>
								{v.name[i18n.languages[0]]}
								{v.deadline ? t('bank.days', { count: v.deadline }) : ''}
							</span>
							<RightArrowIcon
								stroke={'#ffffff'}
								height={17}
								width={19}
							/>
						</div>
					))}
				</div>
			</div>
			<MainNavbar openModal={openModal} />
		</div>
	);
});
