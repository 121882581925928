import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			preserveAspectRatio='xMidYMid meet'
		>
			<g
				transform='translate(0,512) scale(0.100000,-0.100000)'
				fill={fill}
				stroke='none'
			>
				<path
					d='M1803 4945 c-85 -23 -153 -63 -219 -129 -50 -50 -114 -146 -114 -170
0 -3 420 -7 933 -8 1011 -4 945 0 1099 -58 78 -29 194 -106 263 -175 100 -100
164 -213 213 -375 15 -50 17 -193 20 -1642 l2 -1588 99 0 c128 0 190 12 277
55 125 61 228 196 254 334 14 74 14 3308 0 3382 -26 138 -129 273 -254 334
-39 19 -95 40 -125 45 -34 6 -485 10 -1225 9 -975 0 -1180 -3 -1223 -14z'
				/>
				<path
					d='M841 4304 c-169 -45 -301 -180 -346 -351 -23 -86 -23 -3340 0 -3426
45 -173 178 -307 350 -352 52 -13 211 -15 1235 -15 1024 0 1183 2 1235 15 172
45 305 179 350 352 23 86 23 3340 0 3426 -45 173 -178 307 -350 352 -86 22
-2391 22 -2474 -1z'
				/>
			</g>
		</svg>
	);
};
