import './style.sass';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../Buttons/DefaultButton';
import CloseIcon from '../../Icons/CloseIcon';

import { useInventoryItem } from '../../../store/actions/inventory';

import { itemStats } from '../../../common/items';

export default connect(
	(s) => ({
		inventory: s.inventoryManager.avatar,
		equipment: s.avatarManager.state.equipment,
	}),
	{ useInventoryItem }
)(({ equipment, inventory, useInventoryItem, closeModal }) => {
	const [PickedMenu, SetPickedMenu] = React.useState('inventory');
	const [PickedItem, SetPickedItem] = React.useState();
	const inventoryWrapperRef = React.useRef(null);
	const itemInfoWrapperRef = React.useRef(null);
	const [Items, SetItems] = React.useState([]);
	const { t, i18n } = useTranslation();

	React.useEffect(() => {
		if (PickedItem && inventory.items.findIndex((v) => v._id == PickedItem._id) == -1) closeModal();

		const formattedItems = [];
		const itemMap = new Map();

		for (const item of inventory.items) {
			if (item.stackable) {
				if (itemMap.has(item.id)) {
					itemMap.get(item.id).count += 1;
				} else {
					itemMap.set(item.id, { item: item, count: 1 });
				}
			} else {
				formattedItems.push({ item: item, count: 1 });
			}
		}

		for (const formattedItem of itemMap.values()) {
			formattedItems.push(formattedItem);
		}

		SetItems(formattedItems);
	}, [inventory]);

	React.useEffect(() => {
		if (PickedItem && itemInfoWrapperRef.current && inventoryWrapperRef.current) {
			// up item info wrapper
			itemInfoWrapperRef.current.style.zIndex = 1;

			// down item info wrapper
			inventoryWrapperRef.current.style.zIndex = -1;
		} else if (itemInfoWrapperRef.current && inventoryWrapperRef.current) {
			// down item info wrapper
			itemInfoWrapperRef.current.style.zIndex = -1;

			// up item info wrapper
			inventoryWrapperRef.current.style.zIndex = 1;
		}
	}, [PickedItem]);

	return (
		<div className={classNames({ modal: true, 'location-inventory': true })}>
			<div
				className={classNames({ 'item-info-wrapper': true })}
				ref={(ref) => (itemInfoWrapperRef.current = ref)}
			>
				<div className={classNames({ 'modal-header': true })}>
					<div
						className={classNames({ 'modal-header-close': true })}
						onClick={() => SetPickedItem(null)}
					>
						<CloseIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					</div>
					<span className={classNames({ 'modal-header-title': true })}>{t('location-inventory-modal.header.title-2')}</span>
				</div>
				<div className={classNames({ content: true })}>
					<div className={classNames({ 'item-info': true })}>
						<img
							className={classNames({ 'item-image': true })}
							src={PickedItem?.image}
						/>
						<div className={classNames({ 'item-stats': true })}>
							<span className={classNames({ 'item-stats-text': true, strong: true, center: true })}>
								{PickedItem?.name[i18n.languages[0]]}
							</span>
							<span className={classNames({ 'item-stats-text': true })}>
								<span className={classNames({ 'item-stats-text': true, strong: true })}>
									{t(`location-inventory-modal.stats-names.description`)}
								</span>
								{PickedItem?.description[i18n.languages[0]]}
							</span>
							{itemStats[PickedItem?.type]?.map((v, i) => (
								<span
									className={classNames({ 'item-stats-text': true })}
									key={i}
								>
									<span className={classNames({ 'item-stats-text': true, strong: true })}>
										{t(`location-inventory-modal.stats-names.${v}`)}
									</span>
									{t(
										`location-inventory-modal.stats.${v}`,
										typeof PickedItem?.[v.split('-')[0]] == 'object'
											? { ...PickedItem?.[v.split('-')[0]] }
											: {
													value:
														v == 'rarity'
															? t(`location-inventory-modal.rarities.${PickedItem?.[v]}`)
															: v == 'weight' || v == 'capacity'
															? PickedItem?.[v] / 1000
															: PickedItem?.[v],
											  }
									)}
								</span>
							))}
							{PickedItem?.requires.length ? (
								<span className={classNames({ 'item-stats-text': true })}>
									<span className={classNames({ 'item-stats-text': true, strong: true })}>
										{t(`location-inventory-modal.stats-names.requirements`)}
									</span>
									{PickedItem?.requires
										.map((v) => t(`location-inventory-modal.requirements.${v.name}`, { value: v.value }))
										.join(', ')}
								</span>
							) : null}
						</div>
					</div>
				</div>

				{['potion', 'food'].includes(PickedItem?.type) ? (
					<div className={classNames({ 'action-button': true })}>
						<DefaultButton
							text={t('location-inventory-modal.buttons.use-item')}
							onClick={() => useInventoryItem({ itemId: PickedItem?._id })}
							type={'primary'}
							fontSize={20}
							height={50}
						/>
					</div>
				) : null}
			</div>
			<div
				className={classNames({ 'inventory-wrapper': true })}
				ref={(ref) => (inventoryWrapperRef.current = ref)}
			>
				<div className={classNames({ 'modal-header': true })}>
					<div
						className={classNames({ 'modal-header-close': true })}
						onClick={closeModal}
					>
						<CloseIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					</div>
					<span className={classNames({ 'modal-header-title': true })}>
						{PickedMenu == 'inventory'
							? t('location-inventory-modal.header.title-1', {
									current: (inventory.capacity.current / 1000).toFixed(1),
									maximum: (inventory.capacity.maximum / 1000).toFixed(1),
							  })
							: t('location-inventory-modal.header.title-3')}
					</span>
				</div>
				{PickedMenu == 'inventory' ? (
					<div className={classNames({ content: true })}>
						<div className={classNames({ items: true })}>
							{Items.map((v, i) => (
								<div
									className={classNames({ item: true })}
									onClick={() => SetPickedItem(v.item)}
									key={i}
								>
									<img
										className={classNames({ 'item-image': true })}
										src={v.item.image}
									/>
									<span className={classNames({ 'item-count': true })}>x{v.count}</span>
								</div>
							))}
						</div>
					</div>
				) : (
					<div className={classNames({ content: true })}>
						<div className={classNames({ equipments: true })}>
							<div className={classNames({ 'equipment-row': true })}>
								<div
									onClick={() => (equipment.body ? SetPickedItem(equipment.body) : null)}
									className={classNames({ equipment: true })}
								>
									{equipment.body ? (
										<img
											className={classNames({ 'equipment-image': true })}
											src={equipment.body.image}
										/>
									) : (
										<div className={classNames({ 'equipment-image': true })}></div>
									)}
									<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.body')}</span>
								</div>
								<div
									onClick={() => (equipment.leggs ? SetPickedItem(equipment.leggs) : null)}
									className={classNames({ equipment: true })}
								>
									{equipment.leggs ? (
										<img
											className={classNames({ 'equipment-image': true })}
											src={equipment.leggs.image}
										/>
									) : (
										<div className={classNames({ 'equipment-image': true })}></div>
									)}
									<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.leggs')}</span>
								</div>
								<div
									onClick={() => (equipment.boots ? SetPickedItem(equipment.boots) : null)}
									className={classNames({ equipment: true })}
								>
									{equipment.boots ? (
										<img
											className={classNames({ 'equipment-image': true })}
											src={equipment.boots.image}
										/>
									) : (
										<div className={classNames({ 'equipment-image': true })}></div>
									)}
									<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.boots')}</span>
								</div>
							</div>
							<div className={classNames({ 'equipment-row': true })}>
								<div
									onClick={() => (equipment.pickaxe ? SetPickedItem(equipment.pickaxe) : null)}
									className={classNames({ equipment: true })}
								>
									{equipment.pickaxe ? (
										<img
											className={classNames({ 'equipment-image': true })}
											src={equipment.pickaxe.image}
										/>
									) : (
										<div className={classNames({ 'equipment-image': true })}></div>
									)}
									<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.pickaxe')}</span>
								</div>
								<div
									onClick={() => (equipment.shovel ? SetPickedItem(equipment.shovel) : null)}
									className={classNames({ equipment: true })}
								>
									{equipment.shovel ? (
										<img
											className={classNames({ 'equipment-image': true })}
											src={equipment.shovel.image}
										/>
									) : (
										<div className={classNames({ 'equipment-image': true })}></div>
									)}
									<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.shovel')}</span>
								</div>
								<div
									onClick={() => (equipment.axe ? SetPickedItem(equipment.axe) : null)}
									className={classNames({ equipment: true })}
								>
									{equipment.axe ? (
										<img
											className={classNames({ 'equipment-image': true })}
											src={equipment.axe.image}
										/>
									) : (
										<div className={classNames({ 'equipment-image': true })}></div>
									)}
									<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.axe')}</span>
								</div>
							</div>
							<div className={classNames({ 'equipment-row': true })}>
								<div
									onClick={() => (equipment.leftArm ? SetPickedItem(equipment.leftArm) : null)}
									className={classNames({ equipment: true })}
								>
									{equipment.leftArm ? (
										<img
											className={classNames({ 'equipment-image': true })}
											src={equipment.leftArm.image}
										/>
									) : (
										<div className={classNames({ 'equipment-image': true })}></div>
									)}
									<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.left-arm')}</span>
								</div>
								<div
									onClick={() => (equipment.rightArm ? SetPickedItem(equipment.rightArm) : null)}
									className={classNames({ equipment: true })}
								>
									{equipment.rightArm ? (
										<img
											className={classNames({ 'equipment-image': true })}
											src={equipment.rightArm.image}
										/>
									) : (
										<div className={classNames({ 'equipment-image': true })}></div>
									)}
									<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.right-arm')}</span>
								</div>
								<div
									onClick={() => (equipment.backpack ? SetPickedItem(equipment.backpack) : null)}
									className={classNames({ equipment: true })}
								>
									{equipment.backpack ? (
										<img
											className={classNames({ 'equipment-image': true })}
											src={equipment.backpack.image}
										/>
									) : (
										<div className={classNames({ 'equipment-image': true })}></div>
									)}
									<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.backpack')}</span>
								</div>
							</div>
						</div>
					</div>
				)}
				<div className={classNames({ 'menu-buttons-wrapper': true })}>
					<div
						onClick={() => SetPickedMenu('inventory')}
						className={classNames({ 'menu-button': true, picked: PickedMenu == 'inventory' })}
					>
						<span className={classNames({ 'menu-button-text': true })}>{t('location-inventory-modal.buttons.inventory')}</span>
					</div>
					<div
						onClick={() => SetPickedMenu('equipment')}
						className={classNames({ 'menu-button': true, picked: PickedMenu == 'equipment' })}
					>
						<span className={classNames({ 'menu-button-text': true })}>{t('location-inventory-modal.buttons.equipment')}</span>
					</div>
				</div>
			</div>
		</div>
	);
});
