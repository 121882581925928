import axios from 'axios';

import { api_url } from '../../../common/config';

axios.defaults.withCredentials = true;

export default class {
	static getBankDepositsEndpoint = () => axios.get(`${api_url}bank/deposits`);
	static bankDepositFundsEndpoint = (data) => axios.post(`${api_url}bank/deposit-funds`, data);
	static bankWithdrawFundsEndpoint = (data) => axios.post(`${api_url}bank/withdraw-funds`, data);
}
