import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import RightArrowIcon from '../../components/Icons/RightArrowIcon';
import DefaultInput from '../../components/Inputs/DefaultInput';
import SearchIcon from '../../components/Icons/SearchIcon';
import ClearIcon from '../../components/Icons/ClearIcon';
import MainHeader from '../../components/MainHeader';
import MainNavbar from '../../components/MainNavbar';

import { getPotions, changePotionsField } from '../../store/actions/potions';
import { openModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		potions: s.potionsManager.potions,
		validated: s.userManager.validated,
		potionsSearch: s.potionsManager.search,
	}),
	{
		openModal,
		getPotions,
		changePotionsField,
	}
)(({ potions, validated, potionsSearch, openModal, getPotions, changePotionsField }) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (validated) {
			getPotions({ search: potionsSearch });
		}
	}, [validated, potionsSearch]);

	return (
		<div className={classNames({ container: true, potionery: true })}>
			<MainHeader title={t('potionery.header.title')} />
			<div className={classNames({ content: true })}>
				<DefaultInput
					placeholder={t('potionery.search-placeholder')}
					onChange={(e) => changePotionsField({ name: 'search', value: e.target.value })}
					rightIconClick={() => changePotionsField({ name: 'search', value: '' })}
					value={potionsSearch}
					height={25}
					leftIcon={
						<SearchIcon
							width={24}
							height={24}
							fill={'#ffffff'}
						/>
					}
					rightIcon={
						<ClearIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					}
				/>
				<div className={classNames({ locations: true })}>
					{potions.map((v, i) => (
						<div
							onClick={() => navigate('/brew', { state: { potion: v } })}
							className={classNames({ 'location-wrapper': true })}
							key={i}
						>
							<img
								className={classNames({ 'location-image': true })}
								alt={v.name[i18n.languages[0]]}
								src={v.image}
							/>
							<span className={classNames({ 'location-name': true })}>{v.name[i18n.languages[0]]}</span>
							<RightArrowIcon
								stroke={'#ffffff'}
								height={17}
								width={19}
							/>
						</div>
					))}
				</div>
			</div>
			<MainNavbar openModal={openModal} />
		</div>
	);
});
