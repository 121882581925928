import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			style={{
				shapeRendering: 'geometricPrecision',
				textRendering: 'geometricPrecision',
				imageRendering: 'optimizeQuality',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			}}
		>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.977 }}
					d='M 232.5,-0.5 C 248.167,-0.5 263.833,-0.5 279.5,-0.5C 367.811,10.7125 434.644,54.3791 480,130.5C 497.292,162.535 507.792,196.535 511.5,232.5C 511.5,248.167 511.5,263.833 511.5,279.5C 508.244,302.808 495.244,310.974 472.5,304C 464.03,298.731 459.696,291.065 459.5,281C 466.293,211.363 444.626,152.363 394.5,104C 336.587,54.3592 270.587,39.3592 196.5,59C 124.994,82.5061 78.4942,130.339 57,202.5C 40.3803,273.008 55.047,336.008 101,391.5C 157.19,450.599 225.356,471.765 305.5,455C 315.637,452.066 325.637,448.732 335.5,445C 347.99,438.922 360.323,432.589 372.5,426C 391.701,423.544 402.368,431.877 404.5,451C 404.664,457.174 402.831,462.674 399,467.5C 372.515,486.153 343.348,498.987 311.5,506C 300.425,508.269 289.425,510.103 278.5,511.5C 262.833,511.5 247.167,511.5 231.5,511.5C 152.718,502.145 90.2176,465.478 44,401.5C 18.989,364.152 4.15568,323.152 -0.5,278.5C -0.5,262.833 -0.5,247.167 -0.5,231.5C 9.31146,153.017 45.9781,90.5167 109.5,44C 146.848,18.989 187.848,4.15568 232.5,-0.5 Z'
				/>
			</g>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.968 }}
					d='M 354.5,160.5 C 377.69,164.21 385.523,177.21 378,199.5C 329.5,249.333 280.333,298.5 230.5,347C 220.439,351.934 210.773,351.267 201.5,345C 178.305,322.139 155.472,298.972 133,275.5C 126.731,262.077 129.231,250.577 140.5,241C 151.606,234.682 162.273,235.349 172.5,243C 187.167,257.667 201.833,272.333 216.5,287C 217.167,287.667 217.833,287.667 218.5,287C 258.167,247.333 297.833,207.667 337.5,168C 342.542,163.829 348.208,161.329 354.5,160.5 Z'
				/>
			</g>
		</svg>
	);
};
