import { Navigate } from 'react-router';
import React from 'react';

export default ({ condition, result, redirect, children }) => {
	if (condition !== result) {
		console.log('redirect to: ', redirect);

		return (
			<Navigate
				to={redirect}
				replace
			/>
		);
	}

	return children;
};
