import './style.sass';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../Buttons/DefaultButton';
import CloseIcon from '../../Icons/CloseIcon';

export default ({ takeJourney, location, closeModal }) => {
	const { t, i18n } = useTranslation();

	return (
		<div className={classNames({ modal: true, 'location-info': true })}>
			<div className={classNames({ 'modal-header': true })}>
				<div
					className={classNames({ 'modal-header-close': true })}
					onClick={closeModal}
				>
					<CloseIcon
						width={16}
						height={16}
						fill={'#ffffff'}
					/>
				</div>
				<span className={classNames({ 'modal-header-title': true })}>{t('location-info.header.title')}</span>
			</div>
			<div className={classNames({ content: true })}>
				<img
					className={classNames({ 'location-image': true })}
					alt={location.name[i18n.languages[0]]}
					src={location.image}
				/>
				<div className={classNames({ 'location-name-description-wrapper': true })}>
					<span className={classNames({ 'location-name': true })}>{location.name[i18n.languages[0]]}</span>
					<span className={classNames({ 'location-description': true })}>{location.description[i18n.languages[0]]}</span>
				</div>
				<div className={classNames({ 'resources-wrapper': true })}>
					<span className={classNames({ 'resources-title': true })}>{t('location-info.resources')}</span>
					{location.resources.map((v, i) => (
						<img
							className={classNames({ 'resource-image': true })}
							src={v.resource.image}
							key={i}
						/>
					))}
				</div>
				<div className={classNames({ 'enemies-wrapper': true })}>
					<span className={classNames({ 'enemies-title': true })}>{t('location-info.enemies')}</span>
					{location.mobs.map((v, i) => (
						<img
							className={classNames({ 'enemy-image': true })}
							src={v.mob.image}
							key={i}
						/>
					))}
				</div>
			</div>
			<div className={classNames({ 'action-button': true })}>
				<DefaultButton
					onClick={() => takeJourney({ locationId: location._id })}
					text={t('location-info.buttons.hit-road')}
					type={'primary'}
					fontSize={20}
					height={50}
				/>
			</div>
		</div>
	);
};
