import './style.sass';

import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';

import MainMenuIcon from '../Icons/MainMenuIcon';
import AvatarIcon from '../Icons/AvatarIcon';
import TasksIcon from '../Icons/TasksIcon';
import MapIcon from '../Icons/MapIcon';

export default ({ openModal }) => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={classNames({ 'main-navbar': true })}>
			<div
				className={classNames({ 'main-navbar-item': true })}
				onClick={() => navigate('/avatar-info')}
			>
				<AvatarIcon
					width={30}
					height={30}
					fill={pathname == '/avatar-info' ? '#AF8EF9' : '#8F8E94'}
				/>
				<span className={classNames({ 'main-navbar-item-name': true, picked: pathname == '/avatar-info' })}>
					{t('main-navbar.avatar')}
				</span>
			</div>
			<div
				className={classNames({ 'main-navbar-item': true })}
				onClick={() => navigate('/map')}
			>
				<MapIcon
					width={30}
					height={30}
					fill={pathname == '/map' ? '#AF8EF9' : '#8F8E94'}
				/>
				<span
					className={classNames({
						'main-navbar-item-name': true,
						picked: pathname == '/map',
					})}
				>
					{t('main-navbar.map')}
				</span>
			</div>
			<div
				className={classNames({ 'main-navbar-item': true })}
				onClick={() => navigate('/tasks')}
			>
				<TasksIcon
					width={30}
					height={30}
					fill={pathname == '/tasks' ? '#AF8EF9' : '#8F8E94'}
				/>
				<span className={classNames({ 'main-navbar-item-name': true, picked: pathname == '/tasks' })}>
					{t('main-navbar.tasks')}
				</span>
			</div>
			<div
				className={classNames({ 'main-navbar-item': true })}
				onClick={() =>
					openModal({
						name: 'main-menu',
						payload: { withoutPadding: true },
					})
				}
			>
				<MainMenuIcon
					width={30}
					height={30}
					fill={pathname !== '/avatar-info' && pathname !== '/map' && pathname !== '/tasks' ? '#AF8EF9' : '#8F8E94'}
				/>
				<span
					className={classNames({
						'main-navbar-item-name': true,
						picked: pathname !== '/avatar-info' && pathname !== '/map' && pathname !== '/tasks',
					})}
				>
					{t('main-navbar.menu')}
				</span>
			</div>
		</div>
	);
};
