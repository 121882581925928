import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import {
	getPotions,
	getPotionsAsync,
	brewPotion,
	brewPotionAsync,
	changePotionsField,
	changePotionsFieldAsync,
	clearPotionsError,
	clearPotionsErrorAsync,
} from '../../actions/potions';

import potionsApi from '../../../services/api/potions';

function clearErrorWorker() {
	return {};
}

function changePotionsFieldWorker({ name, value }) {
	return { name, value };
}

export function* potionsSaga() {
	yield takeEvery(getPotions, bindAsyncActions(getPotionsAsync)(potionsApi.getPotionsEndpoint));
	yield takeEvery(brewPotion, bindAsyncActions(brewPotionAsync)(potionsApi.brewPotionEndpoint));
	yield takeEvery(changePotionsField, bindAsyncActions(changePotionsFieldAsync)(changePotionsFieldWorker));
	yield takeEvery(clearPotionsError, bindAsyncActions(clearPotionsErrorAsync)(clearErrorWorker));
}
