import './style.sass';

import classNames from 'classnames';
import React from 'react';

export default ({
	size = 32,
	current,
	maximum,
	frontWidth = 12,
	backWidth = 12,
	frontColor = '#ffffff',
	backColor = '#000000',
}) => {
	const [Circumference, SetCircumference] = React.useState(100);
	const [Percentage, SetPercentage] = React.useState(100);

	React.useEffect(() => {
		const radius = size / 2 - 5;

		const circumference = 3.14 * radius * 2;

		const percentage = Math.round(circumference * ((100 - current / (maximum / 100)) / 100)) + 'px';

		SetCircumference(circumference);
		SetPercentage(percentage);
	}, [size, current, maximum]);

	return (
		<div
			className={classNames({ 'circle-bar-wrapper': true })}
			style={{ width: size }}
		>
			<svg
				width={size}
				height={size}
				viewBox={`0 0 ${size} ${size}`}
				style={{ transform: 'rotate(-90deg)' }}
			>
				<circle
					cx={size / 2}
					cy={size / 2}
					r={size / 2 - 5}
					stroke={backColor}
					fill={'transparent'}
					strokeDashoffset={'0'}
					strokeWidth={backWidth}
					strokeDasharray={Circumference}
				></circle>
				<circle
					cx={size / 2}
					cy={size / 2}
					r={size / 2 - 5}
					stroke={frontColor}
					strokeLinecap={'round'}
					strokeWidth={frontWidth}
					strokeDashoffset={Percentage}
					strokeDasharray={Circumference}
					fill={'transparent'}
				></circle>
			</svg>
			<span className={classNames({ 'circle-bar-stats': true })}>
				{current}/{maximum}
			</span>
		</div>
	);
};
