import axios from 'axios';

import { api_url } from '../../../common/config';

axios.defaults.withCredentials = true;

export default class {
	static getFoodsEndpoint = (params) => axios.get(`${api_url}foods`, { params });
	static cookFoodEndpoint = (data) => axios.post(`${api_url}foods/cook`, data);
}
