import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../components/Buttons/DefaultButton';
import CloseIcon from '../../components/Icons/CloseIcon';

import { townhallFundraisersDonate } from '../../store/actions/townhall';
import { openModal, closeModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		modalName: s.modals.name,
		fundraisers: s.townhallManager.fundraisers,
	}),
	{
		openModal,
		closeModal,
		townhallFundraisersDonate,
	}
)(({ modalName, fundraisers, openModal, closeModal, townhallFundraisersDonate }) => {
	const {
		state: { target },
	} = useLocation();
	const [Target, SetTarget] = React.useState(target);
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	React.useEffect(() => {
		const targetFundraiser = fundraisers.find((v) => v._id == Target._id);

		if (targetFundraiser) SetTarget(targetFundraiser);

		if (modalName == 'donate-amount') closeModal();
	}, [fundraisers]);

	return (
		<div className={classNames({ container: true, fundraising: true })}>
			<div className={classNames({ 'task-info-header': true })}>
				<div
					className={classNames({ 'task-info-header-close': true })}
					onClick={() => navigate(-1)}
				>
					<CloseIcon
						width={16}
						height={16}
						fill={'#ffffff'}
					/>
				</div>
				<span className={classNames({ 'task-info-header-title': true })}>{t(`fundraising.header.title`)}</span>
			</div>
			<div className={classNames({ content: true })}>
				<div className={classNames({ info: true })}>
					<div className={classNames({ 'fundraising-image-wrapper': true })}>
						<img
							className={classNames({ 'fundraising-image': true })}
							src={Target.image}
						/>
					</div>
					<div className={classNames({ 'bar-wrapper': true })}>
						<div
							style={{
								width: `${Math.min(Target.progress.current, Target.progress.maximum) / (Target.progress.maximum / 100)}%`,
							}}
							className={classNames({ 'bar-front': true })}
						/>
						<div className={classNames({ 'bar-back': true })} />
						<span className={classNames({ 'bar-state': true })}>
							{Math.min(Target.progress.current, Target.progress.maximum)}/{Target.progress.maximum}
						</span>
					</div>
					<span className={classNames({ 'fundraising-target-name': true })}>{Target.title[i18n.languages[0]]}</span>
					<span className={classNames({ 'fundraising-target-description': true })}>{Target.description[i18n.languages[0]]}</span>
				</div>
			</div>
			<div className={classNames({ 'action-button': true })}>
				<DefaultButton
					onClick={() =>
						openModal({
							name: 'donate-amount',
							payload: {
								type: 'custom',
								withoutPadding: true,
								onConfirm: (amount) => townhallFundraisersDonate({ fundraiserId: Target._id, amount }),
							},
						})
					}
					text={t('fundraising.buttons.donate')}
					type={'primary'}
					fontSize={20}
					height={50}
				/>
			</div>
		</div>
	);
});
