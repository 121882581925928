import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../components/Buttons/DefaultButton';
import CloseIcon from '../../components/Icons/CloseIcon';
import CopyIcon from '../../components/Icons/CopyIcon';

import { getReferralsCount } from '../../store/actions/guild';
import useTelegram from '../../hooks/useTelegram';

export default connect(
	(s) => ({
		user: s.userManager.state,
		referrals: s.guildManager.referrals,
	}),
	{ getReferralsCount }
)(({ user, referrals, getReferralsCount }) => {
	const { tg } = useTelegram();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const copyInviteLink = () => {
		const tempInput = document.createElement('textarea');
		tempInput.value = `https://t.me/synergyherobot?startapp=ref-${user._id}`;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand('copy');
		document.body.removeChild(tempInput);

		toast.success(t(`referrals.alerts.copied`), {
			position: 'top-center',
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			autoClose: 5000,
			draggable: true,
		});
	};

	const shareInviteLink = () => {
		tg.openTelegramLink(`https://t.me/share/url?url=https://t.me/synergyherobot?startapp=ref-${user._id}`);
	};

	React.useEffect(() => {
		getReferralsCount();
	}, []);

	return (
		<div className={classNames({ container: true, referrals: true })}>
			<div className={classNames({ 'task-info-header': true })}>
				<div
					className={classNames({ 'task-info-header-close': true })}
					onClick={() => navigate(-1)}
				>
					<CloseIcon
						width={16}
						height={16}
						fill={'#ffffff'}
					/>
				</div>
				<span className={classNames({ 'task-info-header-title': true })}>{t(`referrals.header.title`)}</span>
			</div>
			<div className={classNames({ content: true })}>
				<div className={classNames({ cover: true })} />
				<div className={classNames({ info: true })}>
					<div className={classNames({ 'referrals-count-wrapper': true })}>
						<span className={classNames({ 'referrals-count-title': true })}>{t(`referrals.you-invite`)}</span>
						<span className={classNames({ 'referrals-count-text': true })}>
							{t(`referrals.referrals-count`, { count: referrals.count })}
						</span>
					</div>
					<div className={classNames({ 'description-wrapper': true })}>
						<div className={classNames({ 'task-description': true })}>
							<img
								className={classNames({ 'task-description-image': true })}
								src={'https://i.imgur.com/F5TEIUU.png'}
							/>
							<div className={classNames({ 'task-description-info': true })}>
								<span className={classNames({ 'task-description-info-title': true })}>{t('referrals.description-title')}</span>
								<span className={classNames({ 'task-description-info-text': true })}>{t('referrals.description-text')}</span>
							</div>
						</div>
						<div className={classNames({ divider: true })} />
						<div className={classNames({ 'ranks-rewards': true })}>
							<div className={classNames({ 'ranks-reward': true })}>
								<img
									className={classNames({ 'ranks-reward-image': true })}
									src={'https://i.imgur.com/F5TEIUU.png'}
								/>
								<span className={classNames({ 'ranks-reward-level': true })}>{t('referrals.rank', { level: 'II' })}</span>
								<span className={classNames({ 'ranks-reward-amount': true })}>{t('referrals.reward', { count: 5000 })}</span>
							</div>
							<div className={classNames({ 'ranks-reward': true })}>
								<img
									className={classNames({ 'ranks-reward-image': true })}
									src={'https://i.imgur.com/F5TEIUU.png'}
								/>
								<span className={classNames({ 'ranks-reward-level': true })}>{t('referrals.rank', { level: 'III' })}</span>
								<span className={classNames({ 'ranks-reward-amount': true })}>{t('referrals.reward', { count: 10000 })}</span>
							</div>
							<div className={classNames({ 'ranks-reward': true })}>
								<img
									className={classNames({ 'ranks-reward-image': true })}
									src={'https://i.imgur.com/F5TEIUU.png'}
								/>
								<span className={classNames({ 'ranks-reward-level': true })}>{t('referrals.rank', { level: 'IV' })}</span>
								<span className={classNames({ 'ranks-reward-amount': true })}>{t('referrals.reward', { count: 15000 })}</span>
							</div>
							<div className={classNames({ 'ranks-reward': true })}>
								<img
									className={classNames({ 'ranks-reward-image': true })}
									src={'https://i.imgur.com/F5TEIUU.png'}
								/>
								<span className={classNames({ 'ranks-reward-level': true })}>{t('referrals.rank', { level: 'V' })}</span>
								<span className={classNames({ 'ranks-reward-amount': true })}>{t('referrals.reward', { count: 20000 })}</span>
							</div>
							<div className={classNames({ 'ranks-reward': true })}>
								<img
									className={classNames({ 'ranks-reward-image': true })}
									src={'https://i.imgur.com/F5TEIUU.png'}
								/>
								<span className={classNames({ 'ranks-reward-level': true })}>{t('referrals.rank', { level: 'VI' })}</span>
								<span className={classNames({ 'ranks-reward-amount': true })}>{t('referrals.reward', { count: 25000 })}</span>
							</div>
							<div className={classNames({ 'ranks-reward': true })}>
								<img
									className={classNames({ 'ranks-reward-image': true })}
									src={'https://i.imgur.com/F5TEIUU.png'}
								/>
								<span className={classNames({ 'ranks-reward-level': true })}>{t('referrals.rank', { level: 'VII' })}</span>
								<span className={classNames({ 'ranks-reward-amount': true })}>{t('referrals.reward', { count: 30000 })}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={classNames({ 'action-buttons': true })}>
				<DefaultButton
					text={t('referrals.buttons.share')}
					onClick={shareInviteLink}
					type={'primary'}
					fontSize={20}
					height={50}
					flex={1}
				/>
				<div
					className={classNames({ 'copy-button': true })}
					onClick={copyInviteLink}
				>
					<CopyIcon
						width={28}
						height={28}
						fill={'#AF8EF9'}
					/>
				</div>
			</div>
		</div>
	);
});
