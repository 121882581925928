import React from 'react';

export default ({ width = 512, height = 512 }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			style={{
				minWidth: `${width}px`,
				minHeight: `${height}px`,
				shapeRendering: 'geometricPrecision',
				textRendering: 'geometricPrecision',
				imageRendering: 'optimizeQuality',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			}}
		>
			<g>
				<path
					fill='#010101'
					style={{ opacity: 0.969 }}
					d='M 251.5,-0.5 C 254.167,-0.5 256.833,-0.5 259.5,-0.5C 272.472,21.6426 289.805,39.8093 311.5,54C 329.066,64.4748 348.066,70.1414 368.5,71C 395.167,71.3333 421.833,71.6667 448.5,72C 464.667,76.1667 474.833,86.3333 479,102.5C 479.667,148.5 479.667,194.5 479,240.5C 472.083,319.5 439.25,385.333 380.5,438C 344.814,469.352 304.814,493.852 260.5,511.5C 257.167,511.5 253.833,511.5 250.5,511.5C 188.339,486.837 135.839,448.837 93,397.5C 56.977,351.458 36.6437,299.125 32,240.5C 31.3333,194.5 31.3333,148.5 32,102.5C 36.1667,86.3333 46.3333,76.1667 62.5,72C 93.1864,71.8998 123.853,71.2332 154.5,70C 186.105,64.618 212.272,49.7847 233,25.5C 239.446,16.9047 245.613,8.23804 251.5,-0.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#abb2c7'
					style={{ opacity: 1 }}
					d='M 254.5,23.5 C 267.341,40.5042 282.674,55.0042 300.5,67C 317.717,77.6286 336.384,84.2953 356.5,87C 385.833,87.3333 415.167,87.6667 444.5,88C 453.573,90.7392 459.74,96.5725 463,105.5C 463.667,147.833 463.667,190.167 463,232.5C 458.924,300.623 433.59,359.29 387,408.5C 349.399,446.584 305.566,475.25 255.5,494.5C 186.515,467.93 131.015,424.263 89,363.5C 64.3746,323.351 50.7079,279.685 48,232.5C 47.3333,190.167 47.3333,147.833 48,105.5C 51.1667,97 57,91.1667 65.5,88C 93.5,87.6667 121.5,87.3333 149.5,87C 186.905,83.2151 218.071,67.3817 243,39.5C 247.097,34.2845 250.931,28.9511 254.5,23.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#020201'
					style={{ opacity: 1 }}
					d='M 439.5,167.5 C 435.363,167.518 432.863,165.518 432,161.5C 431.5,147.504 431.333,133.504 431.5,119.5C 406.164,119.667 380.831,119.5 355.5,119C 321.083,114.035 290.583,100.535 264,78.5C 263.667,90.1667 263.333,101.833 263,113.5C 262.5,116.667 260.667,118.5 257.5,119C 252.579,120.124 249.412,118.291 248,113.5C 247.667,101.833 247.333,90.1667 247,78.5C 207.875,110.147 163.042,123.814 112.5,119.5C 101.5,119.5 90.5,119.5 79.5,119.5C 79.0902,161.517 79.5902,203.517 81,245.5C 88.7384,307.15 115.572,358.65 161.5,400C 185.641,421.573 212.307,439.407 241.5,453.5C 242.937,454.725 244.604,455.725 246.5,456.5C 247.004,350.421 247.837,244.421 249,138.5C 254.347,134.235 259.014,134.902 263,140.5C 263.167,245.835 263.667,351.168 264.5,456.5C 318.186,431.909 361.686,395.242 395,346.5C 423.742,297.811 436.075,245.144 432,188.5C 433.41,185.211 435.91,183.544 439.5,183.5C 443.063,183.625 445.563,185.292 447,188.5C 453.701,289.839 416.868,371.006 336.5,432C 311.665,450.758 284.665,465.925 255.5,477.5C 190.272,451.427 138.439,409.093 100,350.5C 78.5231,313.733 66.5231,274.067 64,231.5C 63.3333,190.5 63.3333,149.5 64,108.5C 64.4652,107.069 65.2986,105.903 66.5,105C 95.7473,103.682 125.081,103.016 154.5,103C 192.049,97.2267 224.049,80.8934 250.5,54C 253.833,52 257.167,52 260.5,54C 286.961,80.8986 318.961,97.2319 356.5,103C 384.833,103.333 413.167,103.667 441.5,104C 444.057,104.685 445.891,106.185 447,108.5C 447.667,126.167 447.667,143.833 447,161.5C 446.15,165.513 443.65,167.513 439.5,167.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#fed681'
					style={{ opacity: 1 }}
					d='M 439.5,167.5 C 439.5,172.833 439.5,178.167 439.5,183.5C 435.91,183.544 433.41,185.211 432,188.5C 436.075,245.144 423.742,297.811 395,346.5C 361.686,395.242 318.186,431.909 264.5,456.5C 263.667,351.168 263.167,245.835 263,140.5C 259.014,134.902 254.347,134.235 249,138.5C 247.837,244.421 247.004,350.421 246.5,456.5C 244.604,455.725 242.937,454.725 241.5,453.5C 163.87,399.289 121.036,324.622 113,229.5C 112.5,192.835 112.333,156.168 112.5,119.5C 163.042,123.814 207.875,110.147 247,78.5C 247.333,90.1667 247.667,101.833 248,113.5C 249.412,118.291 252.579,120.124 257.5,119C 260.667,118.5 262.5,116.667 263,113.5C 263.333,101.833 263.667,90.1667 264,78.5C 290.583,100.535 321.083,114.035 355.5,119C 380.831,119.5 406.164,119.667 431.5,119.5C 431.333,133.504 431.5,147.504 432,161.5C 432.863,165.518 435.363,167.518 439.5,167.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#fdc26d'
					style={{ opacity: 1 }}
					d='M 112.5,119.5 C 112.333,156.168 112.5,192.835 113,229.5C 121.036,324.622 163.87,399.289 241.5,453.5C 212.307,439.407 185.641,421.573 161.5,400C 115.572,358.65 88.7384,307.15 81,245.5C 79.5902,203.517 79.0902,161.517 79.5,119.5C 90.5,119.5 101.5,119.5 112.5,119.5 Z'
				/>
			</g>
		</svg>
	);
};
