import { Routes, Route, useNavigate } from 'react-router';
import useWebSocket from 'react-use-websocket';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import React from 'react';

import RestrictAccess from '../RestrictAccess';
import CreateAvatarWearableLeggsBootsPage from '../../pages/CreateAvatarWearableLeggsBoots';
import CreateAvatarEyebrowsEyesMouthPage from '../../pages/CreateAvatarEyebrowsEyesMouth';
import CreateAvatarHornsWingsTailPage from '../../pages/CreateAvatarHornsWingsTail';
import CreateAvatarWearableBodyPage from '../../pages/CreateAvatarWearableBody';
import CreateAvatarGenderRacePage from '../../pages/CreateAvatarGenderRace';
import CreateAvatarHeadBodyPage from '../../pages/CreateAvatarHeadBody';
import CreateAvatarConfirmPage from '../../pages/CreateAvatarConfirm';
import SomethingWentWrongPage from '../../pages/SomethingWentWrong';
import CreateAvatarHairsPage from '../../pages/CreateAvatarHairs';
import CreateAvatarBeardPage from '../../pages/CreateAvatarBeard';
import AvatarEquipmentPage from '../../pages/AvatarEquipment';
import NotWhiteListedPage from '../../pages/NotWhiteListed';
import GuildTaskInfoPage from '../../pages/GuildTaskInfo';
import AvatarSkillsPage from '../../pages/AvatarSkills';
import FundraisingPage from '../../pages/Fundraising';
import GuesthousePage from '../../pages/Guesthouse';
import AvatarInfoPage from '../../pages/AvatarInfo';
import InventoryPage from '../../pages/Inventory';
import PotioneryPage from '../../pages/Potionery';
import ReferralsPage from '../../pages/Referrals';
import TasksInfoPage from '../../pages/TaskInfo';
import CraftingPage from '../../pages/Crafting';
import LocationPage from '../../pages/Location';
import TownhallPage from '../../pages/Townhall';
import WelcomePage from '../../pages/Welcome';
import DepositPage from '../../pages/Deposit';
import LoadingPage from '../../pages/Loading';
import CookingPage from '../../pages/Cooking';
import CheckInPage from '../../pages/CheckIn';
import GuildPage from '../../pages/Guild';
import TasksPage from '../../pages/Tasks';
import CraftPage from '../../pages/Craft';
import CookPage from '../../pages/Cook';
import BankPage from '../../pages/Bank';
import BrewPage from '../../pages/Brew';
import MapPage from '../../pages/Map';

import ModalsProvider from '../Modals/Provider';

import useTelegram from '../../hooks/useTelegram';

import { getAvatarInventory, updateAvatarInventory, clearInventoryError } from '../../store/actions/inventory';
import { getTownhallNews, clearTownhallError, getTownhallFundraisers } from '../../store/actions/townhall';
import { getAvatarLocation, getLocations, clearLocationsError } from '../../store/actions/locations';
import { getAvatarGuesthouse, clearGuesthouseError } from '../../store/actions/guesthouse';
import { getUserInfo, loginTelegram, clearUserError } from '../../store/actions/user';
import { completeDailyCheckIn, clearGuildError } from '../../store/actions/guild';
import { getBankDeposits, clearBankError } from '../../store/actions/bank';
import { clearPotionsError } from '../../store/actions/potions';
import { clearCraftsError } from '../../store/actions/crafts';
import { clearFoodsError } from '../../store/actions/foods';
import {
	getAvatarInfo,
	clearAvatarError,
	updateAvatarState,
	getAvatarGuildTasks,
	getAvatarSocialTasks,
	updateAvatarGuildTasks,
} from '../../store/actions/avatar';

import { socket_url } from '../../common/config';

export default connect(
	(s) => ({
		user: s.userManager.state,
		language: s.settings.language,
		avatar: s.avatarManager.state,
		userError: s.userManager.error,
		bankError: s.bankManager.error,
		guildError: s.guildManager.error,
		foodsError: s.foodsManager.error,
		validated: s.userManager.validated,
		avatarError: s.avatarManager.error,
		craftsError: s.craftsManager.error,
		potionsError: s.potionsManager.error,
		whitelisted: s.userManager.whitelisted,
		townhallError: s.townhallManager.error,
		locationsError: s.locationsManager.error,
		inventoryError: s.inventoryManager.error,
		guesthouseError: s.guesthouseManager.error,
		currentLocation: s.locationsManager.current,
	}),
	{
		getUserInfo,
		getLocations,
		getAvatarInfo,
		loginTelegram,
		clearBankError,
		getTownhallNews,
		clearGuildError,
		getBankDeposits,
		clearFoodsError,
		clearAvatarError,
		clearCraftsError,
		getAvatarLocation,
		updateAvatarState,
		clearPotionsError,
		clearTownhallError,
		getAvatarInventory,
		getAvatarGuesthouse,
		getAvatarGuildTasks,
		clearLocationsError,
		clearInventoryError,
		completeDailyCheckIn,
		clearGuesthouseError,
		getAvatarSocialTasks,
		updateAvatarInventory,
		updateAvatarGuildTasks,
		getTownhallFundraisers,
	}
)(
	({
		user,
		avatar,
		language,
		validated,
		userError,
		bankError,
		foodsError,
		guildError,
		avatarError,
		craftsError,
		whitelisted,
		potionsError,
		townhallError,
		locationsError,
		inventoryError,
		currentLocation,
		guesthouseError,
		getUserInfo,
		getLocations,
		getAvatarInfo,
		loginTelegram,
		clearBankError,
		getTownhallNews,
		clearGuildError,
		getBankDeposits,
		clearFoodsError,
		clearAvatarError,
		clearCraftsError,
		updateAvatarState,
		getAvatarLocation,
		clearPotionsError,
		clearTownhallError,
		getAvatarInventory,
		getAvatarGuesthouse,
		getAvatarGuildTasks,
		clearLocationsError,
		clearInventoryError,
		completeDailyCheckIn,
		clearGuesthouseError,
		getAvatarSocialTasks,
		updateAvatarInventory,
		updateAvatarGuildTasks,
		getTownhallFundraisers,
	}) => {
		const { lastMessage, sendMessage } = useWebSocket(
			socket_url,
			{
				share: true,
				shouldReconnect: (e) => {
					console.log(e);
					return true;
				},
				reconnectAttempts: 1000,
				reconnectInterval: 200,
			},
			true
		);
		const { tg, initData, hash } = useTelegram();
		const { i18n } = useTranslation();
		const navigate = useNavigate();

		React.useEffect(() => {
			tg.disableVerticalSwipes();

			tg.expand();

			loginTelegram({ params: initData, hash: hash });
		}, []);

		React.useEffect(() => {
			if (validated && whitelisted) {
				getUserInfo();

				getAvatarInfo();

				getAvatarLocation();

				getAvatarInventory();

				getAvatarGuildTasks();

				getAvatarSocialTasks();

				getLocations();

				getAvatarGuesthouse();

				getTownhallNews();

				getTownhallFundraisers();

				getBankDeposits();
			}
		}, [validated, whitelisted]);

		React.useEffect(() => {
			if (currentLocation) {
				navigate('/location');
			}
		}, [currentLocation]);

		React.useEffect(() => {
			if (lastMessage) {
				try {
					let content = JSON.parse(lastMessage.data);

					console.log(content);

					switch (content.type) {
						case 'avatar-update': {
							updateAvatarState(content.response);

							break;
						}
						case 'inventory-avatar-update': {
							updateAvatarInventory(content.response);

							break;
						}
						case 'guild-tasks-update': {
							updateAvatarGuildTasks(content.response);

							break;
						}
						case 'update-social-tasks': {
							getAvatarSocialTasks();

							break;
						}
						case 'avatar-rested': {
							getAvatarInfo();

							getAvatarGuesthouse();

							break;
						}
						case 'daily-check-in-complete': {
							completeDailyCheckIn();

							break;
						}
					}
				} catch (error) {}
			}
		}, [lastMessage]);

		React.useEffect(() => {
			if (user) i18n.changeLanguage(user.language);
			else i18n.changeLanguage(language);
		}, [language, user]);

		React.useEffect(() => {
			if (userError) {
				toast.error(userError[i18n.languages[0]], {
					position: 'top-center',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					autoClose: 5000,
					draggable: true,
				});

				clearUserError();
			}
		}, [userError]);

		React.useEffect(() => {
			if (avatarError) {
				toast.error(avatarError[i18n.languages[0]], {
					position: 'top-center',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					autoClose: 5000,
					draggable: true,
				});

				clearAvatarError();
			}
		}, [avatarError]);

		React.useEffect(() => {
			if (guildError) {
				toast.error(guildError[i18n.languages[0]], {
					position: 'top-center',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					autoClose: 5000,
					draggable: true,
				});

				clearGuildError();
			}
		}, [guildError]);

		React.useEffect(() => {
			if (locationsError) {
				toast.error(locationsError[i18n.languages[0]], {
					position: 'top-center',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					autoClose: 5000,
					draggable: true,
				});

				clearLocationsError();
			}
		}, [locationsError]);

		React.useEffect(() => {
			if (inventoryError) {
				toast.error(inventoryError[i18n.languages[0]], {
					position: 'top-center',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					autoClose: 5000,
					draggable: true,
				});

				clearInventoryError();
			}
		}, [inventoryError]);

		React.useEffect(() => {
			if (craftsError) {
				toast.error(craftsError[i18n.languages[0]], {
					position: 'top-center',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					autoClose: 5000,
					draggable: true,
				});

				clearCraftsError();
			}
		}, [craftsError]);

		React.useEffect(() => {
			if (guesthouseError) {
				toast.error(guesthouseError[i18n.languages[0]], {
					position: 'top-center',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					autoClose: 5000,
					draggable: true,
				});

				clearGuesthouseError();
			}
		}, [guesthouseError]);

		React.useEffect(() => {
			if (townhallError) {
				toast.error(townhallError[i18n.languages[0]], {
					position: 'top-center',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					autoClose: 5000,
					draggable: true,
				});

				clearTownhallError();
			}
		}, [townhallError]);

		React.useEffect(() => {
			if (bankError) {
				toast.error(bankError[i18n.languages[0]], {
					position: 'top-center',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					autoClose: 5000,
					draggable: true,
				});

				clearBankError();
			}
		}, [bankError]);

		React.useEffect(() => {
			if (potionsError) {
				toast.error(potionsError[i18n.languages[0]], {
					position: 'top-center',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					autoClose: 5000,
					draggable: true,
				});

				clearPotionsError();
			}
		}, [potionsError]);

		React.useEffect(() => {
			if (foodsError) {
				toast.error(foodsError[i18n.languages[0]], {
					position: 'top-center',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					autoClose: 5000,
					draggable: true,
				});

				clearFoodsError();
			}
		}, [foodsError]);

		return (
			<>
				<Routes>
					<Route
						path={'/'}
						element={<LoadingPage />}
					/>

					<Route
						path={'/something-went-wrong'}
						element={<SomethingWentWrongPage />}
					/>

					<Route
						path={'/not-white-listed'}
						element={<NotWhiteListedPage />}
					/>

					<Route
						path={'/welcome'}
						element={
							<RestrictAccess
								redirect={'/'}
								condition={user && !userError}
								result={true}
							>
								<WelcomePage />
							</RestrictAccess>
						}
					/>

					<Route
						path={'/create-avatar-gender-race'}
						element={
							<RestrictAccess
								redirect={'/avatar-info'}
								condition={!avatar}
								result={true}
							>
								<CreateAvatarGenderRacePage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/create-avatar-head-body'}
						element={
							<RestrictAccess
								redirect={'/avatar-info'}
								condition={!avatar}
								result={true}
							>
								<CreateAvatarHeadBodyPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/create-avatar-hairs'}
						element={
							<RestrictAccess
								redirect={'/avatar-info'}
								condition={!avatar}
								result={true}
							>
								<CreateAvatarHairsPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/create-avatar-eyebrows-eyes-mouth'}
						element={
							<RestrictAccess
								redirect={'/avatar-info'}
								condition={!avatar}
								result={true}
							>
								<CreateAvatarEyebrowsEyesMouthPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/create-avatar-beard'}
						element={
							<RestrictAccess
								redirect={'/avatar-info'}
								condition={!avatar}
								result={true}
							>
								<CreateAvatarBeardPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/create-avatar-horns-wings-tail'}
						element={
							<RestrictAccess
								redirect={'/avatar-info'}
								condition={!avatar}
								result={true}
							>
								<CreateAvatarHornsWingsTailPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/create-avatar-wearable-body'}
						element={
							<RestrictAccess
								redirect={'/avatar-info'}
								condition={!avatar}
								result={true}
							>
								<CreateAvatarWearableBodyPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/create-avatar-wearable-leggs-boots'}
						element={
							<RestrictAccess
								redirect={'/avatar-info'}
								condition={!avatar}
								result={true}
							>
								<CreateAvatarWearableLeggsBootsPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/create-avatar-confirm'}
						element={
							<RestrictAccess
								redirect={'/avatar-info'}
								condition={!avatar}
								result={true}
							>
								<CreateAvatarConfirmPage />
							</RestrictAccess>
						}
					/>

					<Route
						path={'/avatar-info'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<AvatarInfoPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/avatar-skills'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<AvatarSkillsPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/map'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<MapPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/location'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<LocationPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/tasks'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<TasksPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/task-info'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<TasksInfoPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/crafting'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<CraftingPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/craft'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<CraftPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/equipment'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<AvatarEquipmentPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/guild'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<GuildPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/check-in'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<CheckInPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/referrals'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<ReferralsPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/guild-task-info'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<GuildTaskInfoPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/inventory'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<InventoryPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/potionery'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<PotioneryPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/brew'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<BrewPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/guesthouse'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<GuesthousePage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/townhall'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<TownhallPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/fundraising'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<FundraisingPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/bank'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<BankPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/deposit'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<DepositPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/cooking'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<CookingPage />
							</RestrictAccess>
						}
					/>
					<Route
						path={'/cook'}
						element={
							<RestrictAccess
								redirect={'/welcome'}
								condition={!!avatar}
								result={true}
							>
								<CookPage />
							</RestrictAccess>
						}
					/>
				</Routes>

				<ModalsProvider />
			</>
		);
	}
);
