import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			preserveAspectRatio='xMidYMid meet'
		>
			<g
				transform='translate(0, 512) scale(0.100000,-0.100000)'
				fill={fill}
				stroke='none'
			>
				<path
					d='M70 5113 c-29 -11 -60 -47 -65 -77 -4 -20 7 -77 30 -161 98 -358 159
-774 174 -1192 l7 -202 28 -30 c18 -19 49 -35 89 -46 198 -54 374 -182 444
-320 54 -104 65 -153 70 -295 8 -223 -36 -374 -143 -489 -81 -88 -189 -138
-344 -161 -135 -19 -149 -44 -149 -270 -2 -507 -90 -1270 -192 -1675 -24 -92
-19 -132 17 -166 35 -33 66 -31 374 19 482 78 873 127 1214 153 448 34 1512
-27 3129 -177 273 -25 278 -26 310 -7 63 37 63 45 23 332 -149 1062 -207 2087
-161 2841 30 493 95 1117 176 1678 22 155 20 188 -14 220 -38 35 -68 36 -199
3 -704 -175 -1939 -228 -3188 -136 -419 31 -1158 106 -1473 150 -114 16 -133
17 -157 8z m405 -253 c205 -26 794 -85 1040 -105 1279 -102 2437 -71 3240 88
92 18 121 21 123 12 2 -7 -8 -100 -22 -206 -176 -1344 -200 -2141 -100 -3289
18 -204 87 -818 112 -1002 l18 -128 -24 0 c-14 0 -144 11 -291 25 -1298 121
-2404 185 -2841 164 -342 -16 -802 -70 -1325 -154 -82 -13 -150 -23 -152 -21
-2 1 8 56 21 122 77 379 130 856 145 1322 4 128 12 236 17 241 5 4 41 16 79
25 39 9 106 35 150 56 236 114 368 331 395 650 28 324 -73 584 -300 767 -71
57 -210 134 -278 154 -28 8 -47 20 -52 33 -4 12 -10 95 -13 186 -11 275 -58
633 -123 915 -41 183 -41 165 -8 165 16 0 101 -9 189 -20z'
				/>
				<path
					d='M2865 4260 c-102 -9 -135 -37 -135 -116 0 -26 8 -44 29 -65 l29 -29
169 0 c93 0 195 -5 226 -10 69 -13 118 4 141 49 21 40 20 67 -4 106 -24 38
-61 51 -191 65 -102 11 -156 11 -264 0z'
				/>
				<path
					d='M2320 4161 c-177 -66 -327 -161 -343 -218 -31 -108 83 -174 179 -105
69 50 147 89 239 122 49 17 100 41 112 52 47 44 39 129 -16 161 -40 24 -84 21
-171 -12z'
				/>
				<path
					d='M3531 4006 c-59 -33 -66 -102 -17 -173 19 -27 48 -77 65 -111 36 -71
62 -92 114 -92 29 0 46 7 68 29 52 52 35 134 -53 266 -61 91 -115 115 -177 81z'
				/>
				<path
					d='M1791 3683 c-19 -10 -43 -34 -53 -53 -29 -57 -57 -188 -68 -318 -9
-107 -9 -127 5 -156 33 -69 122 -82 170 -25 24 30 30 54 40 192 3 43 17 116
30 163 32 109 32 136 -2 174 -36 38 -77 46 -122 23z'
				/>
				<path
					d='M3255 3652 c-46 -29 -133 -128 -140 -162 -12 -54 19 -90 213 -245
101 -81 182 -150 180 -155 -2 -4 -86 -75 -188 -156 -105 -85 -191 -161 -199
-178 -22 -47 -2 -92 78 -169 61 -59 74 -67 108 -67 21 0 49 7 61 16 13 9 86
95 164 192 77 98 144 181 148 186 5 4 74 -75 155 -175 81 -101 159 -192 174
-201 14 -10 43 -18 65 -18 34 0 46 7 111 73 127 127 121 142 -134 347 -105 85
-187 157 -183 161 4 3 86 69 182 146 191 153 207 168 210 198 6 55 -2 76 -51
129 -68 75 -110 100 -156 93 -19 -3 -42 -12 -51 -19 -8 -7 -80 -94 -160 -193
-79 -99 -147 -181 -151 -183 -4 -1 -76 83 -159 188 -175 219 -202 237 -277
192z'
				/>
				<path
					d='M1779 2881 c-46 -47 -43 -92 13 -179 87 -135 292 -280 374 -264 32 6
71 47 79 82 11 51 -15 91 -90 134 -85 50 -156 114 -189 170 -36 61 -70 86
-118 86 -30 0 -46 -7 -69 -29z'
				/>
				<path
					d='M2449 2481 c-22 -23 -29 -39 -29 -70 0 -59 29 -86 135 -125 107 -40
181 -76 249 -123 65 -44 115 -42 160 8 63 70 10 153 -151 237 -102 53 -244
102 -295 102 -30 0 -46 -7 -69 -29z'
				/>
				<path
					d='M3003 1975 c-49 -21 -63 -59 -63 -170 0 -62 -8 -134 -22 -198 -12
-56 -21 -118 -20 -137 2 -75 104 -118 165 -70 48 38 87 210 88 392 1 134 -8
159 -66 184 -41 17 -42 17 -82 -1z'
				/>
				<path
					d='M1303 1695 c-55 -23 -67 -53 -60 -153 10 -167 56 -252 135 -252 48 0
97 36 105 76 3 15 -4 63 -14 106 -10 44 -19 93 -19 110 0 86 -75 144 -147 113z'
				/>
				<path
					d='M3725 1477 c-259 -86 -379 -352 -266 -592 123 -264 473 -324 679
-118 136 136 165 351 68 516 -38 65 -117 134 -194 170 -57 27 -80 32 -157 34
-55 2 -105 -2 -130 -10z m178 -208 c52 -14 112 -71 133 -124 24 -65 16 -144
-22 -198 -113 -160 -366 -96 -382 98 -13 155 121 266 271 224z'
				/>
				<path
					d='M2710 1246 c-118 -67 -183 -96 -265 -121 -108 -33 -145 -64 -145
-122 0 -47 22 -81 65 -98 27 -12 41 -11 107 5 144 37 292 109 364 176 45 42
48 99 7 144 -39 42 -78 47 -133 16z'
				/>
				<path
					d='M1563 1160 c-44 -27 -62 -84 -39 -129 38 -73 224 -155 388 -170 137
-13 179 11 182 102 1 53 -43 93 -111 101 -133 16 -172 27 -256 71 -98 50 -118
54 -164 25z'
				/>
			</g>
		</svg>
	);
};
