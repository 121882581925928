import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			style={{
				shapeRendering: 'geometricPrecision',
				textRendering: 'geometricPrecision',
				imageRendering: 'optimizeQuality',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			}}
		>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.985 }}
					d='M 217.5,15.5 C 227.36,13.3496 234.527,16.6829 239,25.5C 239.5,54.1647 239.667,82.8314 239.5,111.5C 321.501,111.333 403.501,111.5 485.5,112C 490,113.833 493.167,117 495,121.5C 495.667,210.833 495.667,300.167 495,389.5C 490.527,398.482 483.36,401.649 473.5,399C 469,397.167 465.833,394 464,389.5C 463.5,307.501 463.333,225.501 463.5,143.5C 381.499,143.667 299.499,143.5 217.5,143C 213,141.167 209.833,138 208,133.5C 207.667,111.167 207.333,88.8333 207,66.5C 157.329,108.008 107.829,149.675 58.5,191.5C 107.829,233.325 157.329,274.992 207,316.5C 207.333,294.167 207.667,271.833 208,249.5C 209.833,245 213,241.833 217.5,240C 264.167,239.333 310.833,239.333 357.5,240C 362,241.833 365.167,245 367,249.5C 367.667,328.167 367.667,406.833 367,485.5C 362.527,494.482 355.36,497.649 345.5,495C 341,493.167 337.833,490 336,485.5C 335.5,414.167 335.333,342.834 335.5,271.5C 303.5,271.5 271.5,271.5 239.5,271.5C 239.667,300.169 239.5,328.835 239,357.5C 234.527,366.482 227.36,369.649 217.5,367C 214.228,365.392 211.228,363.392 208.5,361C 146.792,308.957 84.7921,257.29 22.5,206C 15.9153,200.643 13.7486,193.809 16,185.5C 17.3999,182.098 19.5665,179.264 22.5,177C 87.5706,123.148 152.571,69.3148 217.5,15.5 Z'
				/>
			</g>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.971 }}
					d='M 473.5,431.5 C 483.36,429.35 490.527,432.683 495,441.5C 495.667,456.167 495.667,470.833 495,485.5C 490.527,494.482 483.36,497.649 473.5,495C 469,493.167 465.833,490 464,485.5C 463.333,470.833 463.333,456.167 464,441.5C 466.022,436.98 469.189,433.647 473.5,431.5 Z'
				/>
			</g>
		</svg>
	);
};
