import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import {
	getCrafts,
	getCraftsAsync,
	craftItem,
	craftItemAsync,
	changeCraftsField,
	changeCraftsFieldAsync,
	clearCraftsError,
	clearCraftsErrorAsync,
} from '../../actions/crafts';

import craftsApi from '../../../services/api/crafts';

function clearErrorWorker() {
	return {};
}

function changeCraftsFieldWorker({ name, value }) {
	return { name, value };
}

export function* craftsSaga() {
	yield takeEvery(getCrafts, bindAsyncActions(getCraftsAsync)(craftsApi.getCraftsEndpoint));
	yield takeEvery(craftItem, bindAsyncActions(craftItemAsync)(craftsApi.craftItemEndpoint));
	yield takeEvery(changeCraftsField, bindAsyncActions(changeCraftsFieldAsync)(changeCraftsFieldWorker));
	yield takeEvery(clearCraftsError, bindAsyncActions(clearCraftsErrorAsync)(clearErrorWorker));
}
