import './style.sass';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';

import CloseIcon from '../../Icons/CloseIcon';

export default ({ items, onClose, closeModal }) => {
	const { t } = useTranslation();

	const closeModalWrapper = () => {
		closeModal();

		onClose();
	};

	return (
		<div
			onClick={(e) => (e.target.classList.contains('loot-received-wrapper') ? closeModalWrapper() : null)}
			className={classNames({ modal: true, 'loot-received-wrapper': true })}
		>
			<div className={classNames({ modal: true, 'loot-received': true })}>
				<div className={classNames({ header: true })}>
					<span className={classNames({ 'header-title': true })}>{t('loot-received-modal.header.title')}</span>
					<div
						className={classNames({ 'header-close': true })}
						onClick={closeModalWrapper}
					>
						<CloseIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					</div>
				</div>
				<div className={classNames({ content: true })}>
					<div className={classNames({ items: true })}>
						{items.map((v, i) => (
							<div
								className={classNames({ item: true })}
								key={i}
							>
								<img
									className={classNames({ 'item-image': true })}
									src={v.item.image}
								/>
								<span className={classNames({ 'item-count': true })}>x{v.count}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
