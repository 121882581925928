import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import RightArrowIcon from '../../components/Icons/RightArrowIcon';
import MainHeader from '../../components/MainHeader';
import MainNavbar from '../../components/MainNavbar';

import { openModal, closeModal } from '../../store/actions/modals';
import { takeJourney } from '../../store/actions/locations';

export default connect(
	(s) => ({
		locations: s.locationsManager.locations,
		currentLocation: s.locationsManager.current,
	}),
	{
		openModal,
		closeModal,
		takeJourney,
	}
)(({ locations, currentLocation, openModal, closeModal, takeJourney }) => {
	const { t, i18n } = useTranslation();

	React.useEffect(() => {
		if (currentLocation) {
			closeModal();
		}
	}, [currentLocation]);

	return (
		<div className={classNames({ container: true, map: true })}>
			<MainHeader title={t('map.header.title')} />
			<div className={classNames({ content: true })}>
				<div className={classNames({ locations: true })}>
					{locations.map((v, i) => (
						<div
							onClick={() => openModal({ name: 'location-info', payload: { type: 'full', location: v, takeJourney } })}
							className={classNames({ 'location-wrapper': true })}
							key={i}
						>
							<img
								className={classNames({ 'location-image': true })}
								alt={v.name[i18n.languages[0]]}
								src={v.image}
							/>
							<span className={classNames({ 'location-name': true })}>{v.name[i18n.languages[0]]}</span>
							<RightArrowIcon
								stroke={'#ffffff'}
								height={17}
								width={19}
							/>
						</div>
					))}
				</div>
			</div>
			<MainNavbar openModal={openModal} />
		</div>
	);
});
