import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import {
	getAvatarGuesthouse,
	getAvatarGuesthouseAsync,
	makeGuesthouseRest,
	makeGuesthouseRestAsync,
	guesthouseChestMove,
	guesthouseChestMoveAsync,
	clearGuesthouseError,
	clearGuesthouseErrorAsync,
} from '../../actions/guesthouse';

import guesthouseApi from '../../../services/api/guesthouse';

function clearErrorWorker() {
	return {};
}

export function* guesthouseSaga() {
	yield takeEvery(getAvatarGuesthouse, bindAsyncActions(getAvatarGuesthouseAsync)(guesthouseApi.getAvatarGuestHouseEndpoint));
	yield takeEvery(makeGuesthouseRest, bindAsyncActions(makeGuesthouseRestAsync)(guesthouseApi.makeGuesthouseRestEndpoint));
	yield takeEvery(guesthouseChestMove, bindAsyncActions(guesthouseChestMoveAsync)(guesthouseApi.guesthouseChestMoveEndpoint));
	yield takeEvery(clearGuesthouseError, bindAsyncActions(clearGuesthouseErrorAsync)(clearErrorWorker));
}
