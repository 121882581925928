import colorConvert from 'color-convert';

import { public_url } from '../../common/config';

export default async ({ parts, color, mainCanvasContext, patternCanvasContext, getReplaceColors }) => {
	// clear canvas
	mainCanvasContext.clearRect(0, 0, mainCanvasContext.canvas.width, mainCanvasContext.canvas.height);

	if (color) {
		for (const part of parts) {
			// Create a new Image object
			const avatarPartImage = new Image();

			// Set the source of the image object
			avatarPartImage.crossOrigin = 'anonymous';
			avatarPartImage.src = `${public_url}${part}.png`;

			// wait for image laod
			await new Promise((r) => (avatarPartImage.onload = r));

			if (
				patternCanvasContext.canvas.width != avatarPartImage.width ||
				patternCanvasContext.canvas.height != avatarPartImage.height
			) {
				// resize canvas
				patternCanvasContext.canvas.width = avatarPartImage.width;
				patternCanvasContext.canvas.height = avatarPartImage.height;
			}

			// draw avatar body image
			await patternCanvasContext.drawImage(avatarPartImage, 0, 0, avatarPartImage.width, avatarPartImage.height);
		}

		// get image data
		let imageData = await patternCanvasContext.getImageData(
			0,
			0,
			patternCanvasContext.canvas.width,
			patternCanvasContext.canvas.height
		);

		// get rgb color for replace
		let rgbColorForReplace = colorConvert.hex.rgb(color);

		// set colors array
		const colors = getReplaceColors(rgbColorForReplace);

		for (var p = 0, len = imageData.data.length; p < len; p += 4) {
			if (imageData.data[p + 3] == 0) continue;
			for (let c = 0; c < colors.length; c++) {
				if (
					colors[c].target[0] === imageData.data[p + 0] &&
					colors[c].target[1] === imageData.data[p + 1] &&
					colors[c].target[2] === imageData.data[p + 2]
				) {
					imageData.data[p + 0] = colors[c].replace[0];
					imageData.data[p + 1] = colors[c].replace[1];
					imageData.data[p + 2] = colors[c].replace[2];
					imageData.data[p + 3] = 255;
				}
			}
		}

		// put updated image on canvas
		await patternCanvasContext.putImageData(imageData, 0, 0);

		// Create a new Image object
		const avatarPartsImage = new Image();

		// Set the source of the image object
		avatarPartsImage.src = await patternCanvasContext.canvas.toDataURL('image/png');

		// wait for image laod
		await new Promise((r) => (avatarPartsImage.onload = setTimeout(r, 100)));

		// clear canvas
		patternCanvasContext.clearRect(0, 0, patternCanvasContext.canvas.width, patternCanvasContext.canvas.height);

		// original image sizes
		const originalWidth = avatarPartsImage.width;
		const originalHeight = avatarPartsImage.height;

		// original canvas sizes
		const canvasWidth = mainCanvasContext.canvas.width;
		const canvasHeight = mainCanvasContext.canvas.height;

		// get dimensions scale
		const scaleWidth = canvasWidth / originalWidth;
		const scaleHeight = canvasHeight / originalHeight;

		// get smallest scale
		const scale = Math.min(scaleWidth, scaleHeight);

		// new scaled sizes
		const newWidth = originalWidth * scale;
		const newHeight = originalHeight * scale;

		// get position for scaled iamge
		const x = (canvasWidth - newWidth) / 2;
		const y = (canvasHeight - newHeight) / 2;

		// draw scaled image
		mainCanvasContext.drawImage(avatarPartsImage, x, y, newWidth, newHeight);
	} else {
		for (const part of parts) {
			// Create a new Image object
			const avatarPartImage = new Image();

			// Set the source of the image object
			avatarPartImage.crossOrigin = 'anonymous';
			avatarPartImage.src = `${public_url}${part}.png`;

			// wait for image laod
			await new Promise((r) => (avatarPartImage.onload = r));

			// original image sizes
			const originalWidth = avatarPartImage.width;
			const originalHeight = avatarPartImage.height;

			// original canvas sizes
			const canvasWidth = mainCanvasContext.canvas.width;
			const canvasHeight = mainCanvasContext.canvas.height;

			// get dimensions scale
			const scaleWidth = canvasWidth / originalWidth;
			const scaleHeight = canvasHeight / originalHeight;

			// get smallest scale
			const scale = Math.min(scaleWidth, scaleHeight);

			// new scaled sizes
			const newWidth = originalWidth * scale;
			const newHeight = originalHeight * scale;

			// get position for scaled iamge
			const x = (canvasWidth - newWidth) / 2;
			const y = (canvasHeight - newHeight) / 2;

			// draw scaled image
			mainCanvasContext.drawImage(avatarPartImage, x, y, newWidth, newHeight);
		}
	}
};
