const getLuminance = (r, g, b) => {
	r = r / 255.0;
	g = g / 255.0;
	b = b / 255.0;

	if (r <= 0.03928) r = r / 12.92;
	else r = Math.pow((r + 0.055) / 1.055, 2.4);

	if (g <= 0.03928) g = g / 12.92;
	else g = Math.pow((g + 0.055) / 1.055, 2.4);

	if (b <= 0.03928) b = b / 12.92;
	else b = Math.pow((b + 0.055) / 1.055, 2.4);

	return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export default (hexColor) => {
	hexColor = hexColor.replace('#', '');

	const r = parseInt(hexColor.substring(0, 2), 16);
	const g = parseInt(hexColor.substring(2, 4), 16);
	const b = parseInt(hexColor.substring(4, 6), 16);

	const luminance = getLuminance(r, g, b);

	return luminance > 0.5 ? '#000000' : '#FFFFFF';
};
