export const defaultSkinColor = {
	human: ['#e8dcd7'],
	elve: ['#ede5df'],
	dwarve: ['#e8dcd7'],
	demon: ['#b88989'],
};

export const defaultHairsColor = ['#907070', '#a78180', '#715f5f'];

export const defaultEyesColor = ['#6b7e84', '#9fc7cb', '#96dee4'];

export const defaultBeardColor = ['#907070', '#715f5f', '#a78180'];

export const defaultWingsColor = ['#b88989', '#c7aaaa'];

export const defaultWearableBodyColor = ['#556c67', '#3f5f58'];

export const defaultWearableLeggsColor = ['#47445B', '#393655'];
