import './style.sass';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../Buttons/DefaultButton';
import CloseIcon from '../../Icons/CloseIcon';

export default ({ targetType, staminaCost, onConfirm, closeModal }) => {
	const { t } = useTranslation();

	const confirmLocationAction = () => {
		closeModal();

		onConfirm();
	};

	return (
		<div className={classNames({ modal: true, 'confirm-location-action': true })}>
			<div className={classNames({ 'modal-header': true })}>
				<span className={classNames({ 'modal-header-title': true })}>{t('confirm-location-action-modal.header.title')}</span>
				<div
					className={classNames({ 'modal-header-close': true })}
					onClick={closeModal}
				>
					<CloseIcon
						width={16}
						height={16}
						fill={'#ffffff'}
					/>
				</div>
			</div>
			<span className={classNames({ description: true })}>
				{t(`confirm-location-action-modal.${targetType == 'resource' ? 'confirm-extraction' : 'confirm-attack'}`, {
					cost: staminaCost,
				})}
			</span>
			<DefaultButton
				text={t(`confirm-location-action-modal.buttons.${targetType == 'resource' ? 'extract' : 'attack'}`)}
				onClick={confirmLocationAction}
				type={'danger'}
				fontSize={20}
				height={50}
			/>
		</div>
	);
};
