import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			preserveAspectRatio='xMidYMid meet'
		>
			<g
				transform='translate(0, 512) scale(0.100000,-0.100000)'
				fill={fill}
				stroke='none'
			>
				<path
					d='M2105 4910 c-153 -23 -252 -141 -262 -313 l-6 -85 -201 -4 c-174 -4
-210 -8 -266 -27 -91 -31 -146 -66 -210 -130 -102 -105 -153 -242 -149 -400 2
-61 -4 -103 -33 -211 -62 -231 -112 -620 -140 -1072 l-12 -208 -70 0 c-158 -1
-297 -53 -395 -150 -65 -64 -100 -119 -133 -210 l-23 -65 0 -550 0 -550 23
-65 c81 -223 249 -345 493 -357 l107 -6 11 -37 c17 -56 91 -152 146 -188 26
-17 73 -42 104 -54 l56 -23 1415 0 1415 0 56 23 c31 12 78 37 104 54 55 36
129 132 146 188 l11 37 107 6 c244 12 412 134 493 357 l23 65 0 550 0 550 -23
65 c-33 91 -68 146 -133 210 -98 97 -237 149 -395 150 l-70 0 -13 208 c-28
481 -86 904 -157 1150 -18 63 -23 95 -18 136 14 117 -40 279 -126 378 -54 62
-147 123 -229 149 -55 18 -98 22 -267 26 l-201 5 -6 83 c-8 143 -70 239 -186
294 l-56 26 -440 2 c-242 1 -463 -2 -490 -7z m910 -212 c38 -17 55 -58 55
-128 l0 -60 -510 0 -510 0 0 63 c1 68 15 103 51 123 33 18 875 20 914 2z m717
-431 c101 -50 159 -137 165 -252 4 -58 -10 -140 -81 -494 -73 -360 -90 -431
-112 -463 -34 -49 -112 -88 -176 -88 l-48 0 0 120 c0 118 0 120 -29 152 -24
27 -36 33 -71 33 -35 0 -47 -6 -71 -33 -29 -32 -29 -34 -29 -152 l0 -120 -720
0 -720 0 0 120 c0 118 0 120 -29 152 -24 27 -36 33 -71 33 -35 0 -47 -6 -71
-33 -29 -32 -29 -34 -29 -152 l0 -120 -48 0 c-65 0 -146 41 -178 89 -19 30
-41 119 -109 461 -51 254 -85 449 -85 485 0 95 56 196 136 246 80 49 46 48
1219 46 l1100 -2 57 -28z m-2573 -1057 c37 -187 65 -252 137 -324 73 -73 155
-109 277 -121 l67 -7 0 -170 0 -170 29 -29 c41 -41 101 -41 142 0 l29 29 0
171 0 171 720 0 720 0 0 -171 0 -171 29 -29 c41 -41 101 -41 142 0 l29 29 0
170 0 170 68 7 c121 12 203 48 276 121 72 71 99 136 135 319 18 88 35 167 38
175 18 45 69 -479 95 -985 8 -149 12 -540 13 -1035 l0 -795 -22 -40 c-27 -50
-58 -80 -105 -100 -33 -13 -194 -15 -1418 -15 -1224 0 -1385 2 -1418 15 -47
20 -78 50 -105 100 l-22 40 0 800 c1 1008 17 1354 91 1968 4 32 10 54 14 50 4
-4 22 -82 39 -173z m-345 -1052 c-4 -51 -8 -396 -8 -767 l-1 -673 -90 4 c-76
3 -98 8 -144 33 -31 17 -71 51 -93 78 -68 86 -68 91 -68 652 0 557 1 564 65
647 63 83 139 117 263 118 l84 0 -8 -92z m3735 57 c66 -36 125 -108 146 -177
22 -76 22 -1030 0 -1106 -21 -69 -80 -141 -146 -177 -46 -25 -68 -30 -144 -33
l-90 -4 -1 673 c0 371 -4 716 -8 768 l-8 93 99 -4 c85 -3 105 -8 152 -33z'
				/>
				<path
					d='M1625 1826 c-142 -45 -244 -152 -280 -294 -14 -52 -16 -109 -13 -330
3 -248 5 -271 26 -322 46 -116 144 -208 259 -246 55 -18 100 -19 943 -19 843
0 888 1 943 19 115 38 212 130 260 246 21 53 22 68 22 345 0 253 -2 297 -18
342 -27 77 -88 156 -158 202 -112 75 -59 71 -1053 70 -691 0 -900 -3 -931 -13z
m1844 -212 c34 -17 62 -41 79 -67 l27 -40 3 -271 c2 -266 2 -272 -20 -311 -13
-21 -43 -53 -66 -70 l-44 -30 -888 0 -888 0 -44 30 c-23 17 -53 49 -66 70 -22
39 -22 45 -20 311 l3 271 27 40 c17 26 45 50 79 67 l53 26 856 0 856 0 53 -26z'
				/>
			</g>
		</svg>
	);
};
