{
	"welcome-page": {
		"title": "SYNERGY: HERO",
		"description": "Create an avatar. Imagine. Become who you want. Save this world",
		"buttons": {
			"create": "Create an Avatar"
		}
	},
	"something-went-wrong-page": {
		"title": "Something went wrong",
		"description": "An unexpected error occurred, please try again later"
	},
	"not-white-listed-page": {
		"title": "You are not white-listed",
		"description": "Currently, access to the game requires a special invite. Stay tuned for updates on the project's channel",
		"buttons": {
			"to-channel": "Go to the project channel"
		}
	},
	"create-avatar-gender-race": {
		"pick-gender": "Choose your gender",
		"genders": {
			"male": "Male",
			"female": "Female"
		},
		"pick-race": "Choose your race",
		"races": {
			"human": "Human",
			"elve": "Elf",
			"dwarve": "Dwarf",
			"demon": "Demon"
		},
		"buttons": {
			"back": "Back",
			"next": "Next"
		}
	},
	"create-avatar-head-body": {
		"pick-head": "Choose your head",
		"heads": {
			"small": "Small",
			"middle": "Medium",
			"big": "Large"
		},
		"pick-body": "Choose your body",
		"bodies": {
			"small": "Small",
			"middle": "Medium",
			"big": "Large"
		},
		"pick-skin-color": "Choose skin color",
		"buttons": {
			"back": "Back",
			"next": "Next"
		}
	},
	"create-avatar-hairs": {
		"pick-hairs-front": "Choose your bangs",
		"pick-hairs-back": "Choose your hairstyle",
		"pick-hairs-color": "Choose hair color",
		"type": "Type {{type}}",
		"modals": {
			"hairs-front": "Choose your bangs",
			"hairs-back": "Choose your hairstyle"
		},
		"buttons": {
			"back": "Back",
			"next": "Next"
		}
	},
	"create-avatar-eyebrows-eyes-mouth": {
		"pick-eyebrows": "Choose your eyebrows",
		"pick-eyes-color": "Choose eye color",
		"pick-mouth": "Choose your mouth",
		"type": "Type {{type}}",
		"modals": {
			"eyebrows": "Choose your eyebrows",
			"mouth": "Choose your mouth"
		},
		"buttons": {
			"back": "Back",
			"next": "Next"
		}
	},
	"create-avatar-beard": {
		"pick-beard": "Choose your beard",
		"pick-beard-color": "Choose beard color",
		"type": "Type {{type}}",
		"none": "None",
		"modals": {
			"beard": "Choose your beard"
		},
		"buttons": {
			"back": "Back",
			"next": "Next"
		}
	},
	"create-avatar-horns-wings-tail": {
		"pick-horns-color": "Choose horn color",
		"pick-wings-color": "Choose wing color",
		"pick-tail": "Choose tail",
		"type": "Type {{type}}",
		"modals": {
			"tail": "Choose tail"
		},
		"buttons": {
			"back": "Back",
			"next": "Next"
		}
	},
	"create-avatar-wearable-body": {
		"pick-body": "Choose upper clothing",
		"pick-body-color": "Choose clothing color",
		"bodies": {
			"0": "Tank Top",
			"1": "Sweater"
		},
		"modals": {
			"body": "Choose upper clothing"
		},
		"buttons": {
			"back": "Back",
			"next": "Next"
		}
	},
	"create-avatar-wearable-leggs-boots": {
		"pick-leggs": "Choose lower clothing",
		"pick-leggs-color": "Choose clothing color",
		"pick-boots": "Choose boots",
		"leggs": {
			"0": "Tight Pants",
			"1": "Loose Pants"
		},
		"boots": {
			"0": "High Boots",
			"1": "Low Boots"
		},
		"modals": {
			"leggs": "Choose lower clothing",
			"boots": "Choose boots"
		},
		"buttons": {
			"back": "Back",
			"next": "Next"
		}
	},
	"create-avatar-confirm": {
		"enter-avatar-name": "Enter character name",
		"avatar-name": "Character Name",
		"buttons": {
			"back": "Back",
			"confirm": "Confirm Creation"
		}
	},
	"main-navbar": {
		"avatar": "Avatar",
		"map": "Map",
		"tasks": "Tasks",
		"menu": "Menu"
	},
	"avatar-info": {
		"header": {
			"title": "Avatar"
		},
		"level": "Level",
		"health": "Health",
		"stamina": "Stamina",
		"mana": "Mana",
		"buttons": {
			"skills": "Skills"
		}
	},
	"avatar-skills": {
		"header": {
			"title": "Character Skills"
		},
		"stamina": "Stamina",
		"combat": "Combat",
		"extracting": "Extracting",
		"magic": "Magic",
		"crafting": "Crafting",
		"cooking": "Cooking",
		"potionery": "Potion Making"
	},
	"map": {
		"header": {
			"title": "Map"
		}
	},
	"location": {
		"durability": "Durability",
		"health": "Health"
	},
	"tasks": {
		"header": {
			"title": "Tasks"
		},
		"socials": "Social",
		"guilds": "Guilds",
		"empty": "No tasks available"
	},
	"task-info": {
		"header": {
			"social": "Social Task",
			"guild": "Guild Task"
		},
		"description-title": "Receive your reward for completing",
		"buttons": {
			"remaining": "Remaining: {{time}}",
			"pickup-reward": "Collect Reward"
		}
	},
	"crafting": {
		"header": {
			"title": "Crafting"
		},
		"search-placeholder": "Search"
	},
	"craft": {
		"header": {
			"title": "Item Crafting"
		},
		"stats-names": {
			"description": "Description: ",
			"stamina-cost": "Stamina Cost: ",
			"rarity": "Rarity: ",
			"durability": "Durability: ",
			"efficiency": "Efficiency: ",
			"damage": "Damage: ",
			"protection": "Protection: ",
			"capacity": "Capacity: ",
			"saturation": "Saturation: ",
			"mana-usage": "Mana Usage: ",
			"mana-multiplier": "Magic Multiplier: ",
			"weight": "Weight: ",
			"requirements": "Requirements: ",
			"repair": "Repair: ",
			"effect": "Effect: "
		},
		"stats": {
			"stamina-cost": "{{value}} pts",
			"rarity": "{{value}}",
			"durability": "{{current}}/{{maximum}} pts",
			"efficiency": "{{value}} pts",
			"damage": "{{value}} pts",
			"protection": "{{value}} pts",
			"capacity": "{{value}} kg",
			"saturation": "{{value}} pts",
			"mana-usage": "{{usage}} pts",
			"mana-multiplier": "x{{multiplier}}",
			"weight": "{{value}} kg",
			"effect": "{{name.en}} {{strength}}"
		},
		"rarities": {
			"common": "common",
			"uncommon": "uncommon",
			"rare": "rare",
			"mythical": "mythical",
			"legendary": "legendary",
			"ancient": "ancient",
			"eternal": "eternal"
		},
		"requirements": {
			"avatar-level": "{{value}} avatar level",
			"combat-level": "{{value}} combat level"
		},
		"unrepairable": "Unrepairable",
		"counts": {
			"1": "1 piece",
			"5": "5 pieces",
			"10": "10 pieces",
			"20": "20 pieces",
			"50": "50 pieces"
		},
		"modals": {
			"count": "Choose the amount"
		},
		"buttons": {
			"craft": "Craft",
			"count": "Quantity: {{count}} pieces"
		}
	},
	"potionery": {
		"header": {
			"title": "Potion Making"
		},
		"search-placeholder": "Search"
	},
	"brew": {
		"header": {
			"title": "Potion Making"
		},
		"stats-names": {
			"description": "Description: ",
			"stamina-cost": "Stamina Cost: ",
			"effect": "Effect: ",
			"weight": "Weight: "
		},
		"stats": {
			"stamina-cost": "{{value}} pts",
			"effect": "{{name.en}} {{strength}}",
			"weight": "{{value}} kg"
		},
		"counts": {
			"1": "1 piece",
			"5": "5 pieces",
			"10": "10 pieces",
			"20": "20 pieces",
			"50": "50 pieces"
		},
		"modals": {
			"count": "Select quantity"
		},
		"buttons": {
			"brew": "Brew",
			"count": "Quantity: {{count}} pieces"
		}
	},
	"cooking": {
		"header": {
			"title": "Cooking"
		},
		"search-placeholder": "Search"
	},
	"cook": {
		"header": {
			"title": "Cooking"
		},
		"stats-names": {
			"description": "Description: ",
			"stamina-cost": "Stamina Cost: ",
			"saturation": "Saturation: ",
			"weight": "Weight: "
		},
		"stats": {
			"stamina-cost": "{{value}} pts",
			"saturation": "{{value}} pts",
			"weight": "{{value}} kg"
		},
		"counts": {
			"1": "1 piece",
			"5": "5 pieces",
			"10": "10 pieces",
			"20": "20 pieces",
			"50": "50 pieces"
		},
		"modals": {
			"count": "Select quantity"
		},
		"buttons": {
			"cook": "Cook",
			"count": "Quantity: {{count}} pieces"
		}
	},
	"avatar-equipment": {
		"header": {
			"title": "Equipment"
		},
		"body": "Armor",
		"leggs": "Pants",
		"boots": "Boots",
		"pickaxe": "Pickaxe",
		"shovel": "Shovel",
		"axe": "Axe",
		"left-arm": "Left Hand",
		"right-arm": "Right Hand",
		"backpack": "Backpack"
	},
	"guild": {
		"header": {
			"title": "Adventurers' Guild"
		},
		"ranks": {
			"1": "Rank I: Neophyte",
			"2": "Rank II: Seeker",
			"3": "Rank III: Wanderer",
			"4": "Rank IV: Guardian",
			"5": "Rank V: Ranger",
			"6": "Rank VI: Master",
			"7": "Rank VII: Magister",
			"8": "Rank VIII: Legend",
			"9": "Rank IX: Archon",
			"10": "Rank X: Titan",
			"11": "Rank XI: Seer",
			"12": "Rank XII: Immortal",
			"13": "Rank XIII: Eternal",
			"14": "Rank XIV: Harbinger"
		},
		"tasks": "Task Board",
		"buttons": {
			"check-in": "Daily Check-in",
			"invite": "Invite Adventurer"
		}
	},
	"check-in": {
		"header": {
			"title": "Daily Check-in"
		},
		"days-streak": "Daily Check-in Streak",
		"days-count": "{{count}} days",
		"description-title": "Receive rewards for daily check-ins",
		"description-text": "Perform daily check-ins at the guild to receive rewards. The value of the reward depends on your check-in streak. Keep your streak uninterrupted to receive rarer rewards.",
		"periods": {
			"1-3": "1-3 days:",
			"4-6": "4-6 days:",
			"7-9": "7-9 days:",
			"10+": "10+ days:"
		},
		"reward": "+{{count}} nanites",
		"buttons": {
			"connect-wallet": "Connect Wallet",
			"check-in": "Check-in",
			"in-progress": "Checking...",
			"completed": "Reward Claimed"
		}
	},
	"referrals": {
		"header": {
			"title": "Referral System"
		},
		"you-invite": "You invited",
		"referrals-count": "{{count}} adventurers",
		"description-title": "Receive rewards for invitations",
		"description-text": "Invite friends to the game to receive rewards for each of their guild rank advancements.",
		"rank": "Rank {{level}}:",
		"reward": "+{{count}} nanites",
		"buttons": {
			"share": "Share with Friends"
		},
		"alerts": {
			"copied": "Invite link copied!"
		}
	},
	"guild-task-info": {
		"header": {
			"title": "Guild Task"
		},
		"description-title": "Receive reward for completing",
		"buttons": {
			"take": "Take Task"
		}
	},
	"inventory": {
		"header": {
			"title": "Inventory ({{current}}/{{maximum}}kg)"
		}
	},
	"guesthouse": {
		"header": {
			"title": "Guesthouse"
		},
		"buttons": {
			"open-chest": "Open Chest",
			"rest": "Rest",
			"resting": "Time left to rest: {{time}}"
		}
	},
	"townhall": {
		"header": {
			"title": "Townhall"
		},
		"news": "News",
		"empty": "No items"
	},
	"fundraising": {
		"header": {
			"title": "Fundraising"
		},
		"buttons": {
			"donate": "Donate"
		}
	},
	"bank": {
		"header": {
			"title": "Bank"
		},
		"your-balance": "Your balance:",
		"bank-balance": "Bank balance:",
		"deposits": "Deposits",
		"days": "({{count}} days)"
	},
	"deposit": {
		"header": {
			"title": "Deposit"
		},
		"name": "Name:",
		"deadline": "Deadline:",
		"days": "{{count}} days",
		"indefinitely": "indefinitely",
		"store": "Stored:",
		"rate": "Rate:",
		"buttons": {
			"deposit-funds": "Deposit Funds",
			"withdrawal-funds": "Withdraw Funds",
			"close-early": "Close Early",
			"take-savings": "Collect Savings"
		}
	},
	"color-picker-modal": {
		"header": {
			"title": "Select Color"
		}
	},
	"main-menu-modal": {
		"header": {
			"title": "Select Page"
		},
		"buttons": {
			"avatar": "Avatar",
			"map": "Map",
			"tasks": "Tasks",
			"crafting": "Crafting",
			"equipment": "Equipment",
			"guild": "Guild",
			"inventory": "Inventory",
			"guesthouse": "Guesthouse",
			"potionery": "Potionery",
			"townhall": "Townhall",
			"bank": "Bank",
			"cooking": "Cooking"
		}
	},
	"location-info": {
		"header": {
			"title": "Location Info"
		},
		"resources": "Available Resources:",
		"enemies": "Enemies to Encounter:",
		"buttons": {
			"hit-road": "Hit the Road"
		}
	},
	"location-inventory-modal": {
		"header": {
			"title-1": "Inventory ({{current}}/{{maximum}}kg)",
			"title-2": "Item Description",
			"title-3": "Equipment"
		},
		"stats-names": {
			"description": "Description: ",
			"rarity": "Rarity: ",
			"durability": "Durability: ",
			"efficiency": "Efficiency: ",
			"damage": "Damage: ",
			"protection": "Protection: ",
			"capacity": "Capacity: ",
			"saturation": "Saturation: ",
			"mana-usage": "Mana Usage: ",
			"mana-multiplier": "Mana Multiplier: ",
			"weight": "Weight: ",
			"requirements": "Requirements: ",
			"repair": "Repair: ",
			"effect": "Effect: "
		},
		"stats": {
			"rarity": "{{value}}",
			"durability": "{{current}}/{{maximum}} units",
			"efficiency": "{{value}} units",
			"damage": "{{value}} units",
			"protection": "{{value}} units",
			"capacity": "{{value}} kg",
			"saturation": "{{value}} units",
			"mana-usage": "{{usage}} units",
			"mana-multiplier": "x{{multiplier}}",
			"weight": "{{value}} kg",
			"effect": "{{name.en}} {{strength}}"
		},
		"rarities": {
			"common": "common",
			"uncommon": "uncommon",
			"rare": "rare",
			"mythical": "mythical",
			"legendary": "legendary",
			"ancient": "ancient",
			"eternal": "eternal"
		},
		"requirements": {
			"avatar-level": "{{value}} avatar level",
			"combat-level": "{{value}} combat level"
		},
		"buttons": {
			"use-item": "Use Item",
			"inventory": "Inventory",
			"equipment": "Equipment"
		}
	},
	"confirm-location-action-modal": {
		"header": {
			"title": "Confirmation"
		},
		"confirm-extraction": "Are you sure you want to extract this material? This will cost {{cost}} stamina.",
		"confirm-attack": "Are you sure you want to attack this creature? This will cost {{cost}} stamina.",
		"buttons": {
			"extract": "Extract",
			"attack": "Attack"
		}
	},
	"loot-received-modal": {
		"header": {
			"title": "Loot Received"
		}
	},
	"crafted-item-modal": {
		"header": {
			"title": "Crafted Item"
		}
	},
	"equipment-inventory-modal": {
		"header": {
			"title-description": "Item Description",
			"title-body": "Choose Armor",
			"title-leggs": "Choose Pants",
			"title-boots": "Choose Boots",
			"title-pickaxe": "Choose Pickaxe",
			"title-shovel": "Choose Shovel",
			"title-axe": "Choose Axe",
			"title-leftArm": "Choose Left Hand",
			"title-rightArm": "Choose Right Hand",
			"title-backpack": "Choose Backpack"
		},
		"stats-names": {
			"description": "Description: ",
			"rarity": "Rarity: ",
			"durability": "Durability: ",
			"efficiency": "Efficiency: ",
			"damage": "Damage: ",
			"protection": "Protection: ",
			"capacity": "Capacity: ",
			"saturation": "Saturation: ",
			"mana-usage": "Mana Usage: ",
			"mana-multiplier": "Magic Multiplier: ",
			"weight": "Weight: ",
			"requirements": "Requirements: ",
			"repair": "Repair: ",
			"effect": "Effect: "
		},
		"stats": {
			"rarity": "{{value}}",
			"durability": "{{current}}/{{maximum}} units",
			"efficiency": "{{value}} units",
			"damage": "{{value}} units",
			"protection": "{{value}} units",
			"capacity": "{{value}} kg",
			"saturation": "{{value}} units",
			"mana-usage": "{{usage}} units",
			"mana-multiplier": "x{{multiplier}}",
			"weight": "{{value}} kg",
			"effect": "{{name.en}} {{strength}}"
		},
		"rarities": {
			"common": "common",
			"uncommon": "uncommon",
			"rare": "rare",
			"mythical": "mythical",
			"legendary": "legendary",
			"ancient": "ancient",
			"eternal": "eternal"
		},
		"requirements": {
			"avatar-level": "{{value}} avatar level",
			"combat-level": "{{value}} combat level"
		},
		"buttons": {
			"set-equipment": "Select Equipment"
		}
	},
	"guild-tasks-limit-reached-modal": {
		"header": {
			"title": "Confirmation"
		},
		"description": "You have reached the task limit. Complete the current tasks to take new ones.",
		"buttons": {
			"confirm": "Close"
		}
	},
	"inventory-item-info-modal": {
		"header": {
			"title": "Item Information"
		},
		"stats-names": {
			"description": "Description: ",
			"rarity": "Rarity: ",
			"durability": "Durability: ",
			"efficiency": "Efficiency: ",
			"damage": "Damage: ",
			"protection": "Protection: ",
			"capacity": "Capacity: ",
			"saturation": "Saturation: ",
			"mana-usage": "Mana Usage: ",
			"mana-multiplier": "Magic Multiplier: ",
			"weight": "Weight: ",
			"requirements": "Requirements: ",
			"repair": "Repair: ",
			"effect": "Effect: "
		},
		"stats": {
			"rarity": "{{value}}",
			"durability": "{{current}}/{{maximum}} units",
			"efficiency": "{{value}} units",
			"damage": "{{value}} units",
			"protection": "{{value}} units",
			"capacity": "{{value}} kg",
			"saturation": "{{value}} units",
			"mana-usage": "{{usage}} units",
			"mana-multiplier": "x{{multiplier}}",
			"weight": "{{value}} kg",
			"effect": "{{name.en}} {{strength}}"
		},
		"rarities": {
			"common": "common",
			"uncommon": "uncommon",
			"rare": "rare",
			"mythical": "mythical",
			"legendary": "legendary",
			"ancient": "ancient",
			"eternal": "eternal"
		},
		"requirements": {
			"avatar-level": "{{value}} avatar level",
			"combat-level": "{{value}} combat level"
		},
		"buttons": {
			"use-item": "Use Item"
		}
	},
	"guesthouse-chest-modal": {
		"header": {
			"title": "Guesthouse Chest"
		},
		"chest": "Chest",
		"inventory": "Inventory ({{current}}/{{maximum}} kg)"
	},
	"confirm-rest-modal": {
		"header": {
			"title": "Confirmation"
		},
		"description": "Are you sure you want to rest? You will be restricted from actions for 2 hours, but you will fully recover.",
		"buttons": {
			"rest": "Rest"
		}
	},
	"donate-amount-modal": {
		"header": {
			"title": "Donate Funds"
		},
		"amount": "Donation Amount",
		"buttons": {
			"confirm": "Confirm"
		}
	},
	"confirm-level-upgrade-modal": {
		"header": {
			"title": "Confirmation"
		},
		"description": "Are you sure you want to level up {{type}}? This will cost {{price}} nanites.",
		"types": {
			"avatar": "avatar",
			"stamina": "stamina skill",
			"combat": "combat skill",
			"magic": "magic skill",
			"extracting": "extracting skill",
			"crafting": "crafting skill",
			"cooking": "cooking skill",
			"potionery": "potionery skill"
		},
		"buttons": {
			"confirm": "Level Up"
		}
	},
	"confirm-deposit-early-closing-modal": {
		"header": {
			"title": "Confirmation"
		},
		"description": "Are you sure you want to close the deposit early? The bank will retain 10% of the deposit amount (no less than 1 nanite).",
		"buttons": {
			"confirm": "Close Early"
		}
	},
	"deposit-funds-modal": {
		"header": {
			"title": "Deposit Funds"
		},
		"amount": "Deposit Amount",
		"buttons": {
			"confirm": "Confirm Deposit"
		}
	},
	"withdrawal-funds-modal": {
		"header": {
			"title": "Withdraw Funds"
		},
		"amount": "Withdrawal Amount",
		"buttons": {
			"confirm": "Confirm Withdrawal"
		}
	},
	"resources": {
		"wood": "wood",
		"silver": "silver"
	}
}
