import { handleActions } from 'redux-actions';

import { getCraftsAsync, craftItemAsync, changeCraftsFieldAsync, clearCraftsErrorAsync } from '../../actions/crafts';

import { defaultError } from '../../../common/errors';

const initialState = {
	search: '',
	crafts: [],
	error: null,
};

export default handleActions(
	{
		[getCraftsAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			crafts: response.success ? response.result.crafts : null,
		}),
		[craftItemAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
		}),
		[changeCraftsFieldAsync.success]: (s, { payload: { name, value } }) => ({ ...s, [name]: value }),
		[clearCraftsErrorAsync.success]: (s, p) => ({ ...s, error: null }),

		[getCraftsAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			crafts: [],
		}),
	},
	initialState
);
