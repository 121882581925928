import axios from 'axios';

import { api_url } from '../../../common/config';

axios.defaults.withCredentials = true;

export default class {
	static getGuildTasksEndpoint = () => axios.get(`${api_url}guild/tasks`);
	static getReferralsCountEndpoint = () => axios.get(`${api_url}guild/referrals`);
	static takeGuildTaskEndpoint = (data) => axios.post(`${api_url}guild/tasks/take`, data);
	static getDailyCheckInEndpoint = () => axios.get(`${api_url}guild/daily-check-in`);
	static updateDailyCheckInWalletEndpoint = (data) => axios.post(`${api_url}guild/daily-check-in/update-wallet`, data);
	static confirmDailyCheckInEndpoint = (data) => axios.post(`${api_url}guild/daily-check-in/confirm`, data);
}
