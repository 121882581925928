export const itemStats = {
	resource: ['weight'],
	arrow: ['weight'],
	potion: ['effect', 'weight'],
	food: ['saturation', 'weight'],
	axe: ['rarity', 'durability', 'efficiency', 'mana-usage', 'mana-multiplier', 'weight'],
	pickaxe: ['rarity', 'durability', 'efficiency', 'mana-usage', 'mana-multiplier', 'weight'],
	shovel: ['rarity', 'durability', 'efficiency', 'mana-usage', 'mana-multiplier', 'weight'],
	wand: ['rarity', 'durability', 'damage', 'mana-usage', 'mana-multiplier', 'weight'],
	sword: ['rarity', 'durability', 'damage', 'mana-usage', 'mana-multiplier', 'weight'],
	shield: ['rarity', 'durability', 'protection', 'mana-usage', 'mana-multiplier', 'weight'],
	bow: ['rarity', 'durability', 'damage', 'mana-usage', 'mana-multiplier', 'weight'],
	backpack: ['rarity', 'durability', 'capacity', 'weight'],
	body: ['rarity', 'durability', 'protection', 'mana-usage', 'mana-multiplier', 'weight'],
	leggs: ['rarity', 'durability', 'protection', 'mana-usage', 'mana-multiplier', 'weight'],
	boots: ['rarity', 'durability', 'protection', 'mana-usage', 'mana-multiplier', 'weight'],
};

export const equipmentTypes = {
	axe: ['axe'],
	pickaxe: ['pickaxe'],
	shovel: ['shovel'],
	body: ['body'],
	leggs: ['leggs'],
	boots: ['boots'],
	leftArm: ['wand', 'sword', 'shield', 'bow'],
	rightArm: ['wand', 'sword', 'shield', 'bow'],
	backpack: ['backpack'],
};
