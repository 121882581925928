import axios from 'axios';

import { api_url } from '../../../common/config';

axios.defaults.withCredentials = true;

export default class {
	static getUserInfoEndpoint = () => axios.get(`${api_url}users/current`);
	static loginTelegramEndpoint = (data) => axios.post(`${api_url}users/login/telegram`, data);
}
