import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import RightArrowIcon from '../../components/Icons/RightArrowIcon';
import DefaultInput from '../../components/Inputs/DefaultInput';
import SearchIcon from '../../components/Icons/SearchIcon';
import ClearIcon from '../../components/Icons/ClearIcon';
import MainHeader from '../../components/MainHeader';
import MainNavbar from '../../components/MainNavbar';

import { getFoods, changeFoodsField } from '../../store/actions/foods';
import { openModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		foods: s.foodsManager.foods,
		validated: s.userManager.validated,
		foodsSearch: s.foodsManager.search,
	}),
	{
		openModal,
		getFoods,
		changeFoodsField,
	}
)(({ foods, validated, foodsSearch, openModal, getFoods, changeFoodsField }) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (validated) {
			getFoods({ search: foodsSearch });
		}
	}, [validated, foodsSearch]);

	return (
		<div className={classNames({ container: true, cooking: true })}>
			<MainHeader title={t('cooking.header.title')} />
			<div className={classNames({ content: true })}>
				<DefaultInput
					placeholder={t('cooking.search-placeholder')}
					onChange={(e) => changeFoodsField({ name: 'search', value: e.target.value })}
					rightIconClick={() => changeFoodsField({ name: 'search', value: '' })}
					value={foodsSearch}
					height={25}
					leftIcon={
						<SearchIcon
							width={24}
							height={24}
							fill={'#ffffff'}
						/>
					}
					rightIcon={
						<ClearIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					}
				/>
				<div className={classNames({ locations: true })}>
					{foods.map((v, i) => (
						<div
							onClick={() => navigate('/cook', { state: { food: v } })}
							className={classNames({ 'location-wrapper': true })}
							key={i}
						>
							<img
								className={classNames({ 'location-image': true })}
								alt={v.name[i18n.languages[0]]}
								src={v.image}
							/>
							<span className={classNames({ 'location-name': true })}>{v.name[i18n.languages[0]]}</span>
							<RightArrowIcon
								stroke={'#ffffff'}
								height={17}
								width={19}
							/>
						</div>
					))}
				</div>
			</div>
			<MainNavbar openModal={openModal} />
		</div>
	);
});
