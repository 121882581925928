import colorConvert from 'color-convert';

import { defaultWearableBodyColor } from '../../../common/avatar';

export default (rgbColorForReplace) => [
	{
		target: colorConvert.hex.rgb(defaultWearableBodyColor[0]),
		replace: rgbColorForReplace,
	},
	{
		target: colorConvert.hex.rgb(defaultWearableBodyColor[1]),
		replace: [rgbColorForReplace[0] - 18, rgbColorForReplace[1] - 13, rgbColorForReplace[2] - 14],
	},
];
