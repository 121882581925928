import './style.sass';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';

import getSaturationCoordinates from '../../../utils/colors/getSaturationCoordinates';
import getHueCoordinates from '../../../utils/colors/getHueCoordinates';
import { hsvToRgb, rgbToHex } from '../../../utils/colors/converters';
import isValidHex from '../../../utils/colors/isValidHex';
import parseColor from '../../../utils/colors/parseColor';
import clamp from '../../../utils/colors/clamp';

import CloseIcon from '../../Icons/CloseIcon';

export default ({ Color, SetColor, closeModal }) => {
	const [color, setColor] = React.useState(Color.replace('#', ''));
	const [ParsedColor, SetParsedColor] = React.useState(parseColor('#' + color));
	const [SaturationCoords, SetSaturationCoords] = React.useState(ParsedColor);
	const [HueCoords, SetHueCoords] = React.useState(ParsedColor);
	const [MouseDowned, SetMouseDowned] = React.useState(false);
	const { t } = useTranslation();

	React.useEffect(() => {
		SetParsedColor(parseColor(color));
	}, [color]);

	React.useEffect(() => {
		SetSaturationCoords(getSaturationCoordinates(ParsedColor));
		SetHueCoords(getHueCoordinates(ParsedColor));
	}, [ParsedColor]);

	React.useEffect(() => {
		if (!MouseDowned && isValidHex('#' + color)) SetColor('#' + color);
	}, [MouseDowned, color]);

	const onSaturationChange = (event) => {
		if (event.type === 'mousemove' && event.buttons !== 1) return;

		const { width, height, left, top } = event.target.getBoundingClientRect();

		let clientX = 0;
		let clientY = 0;

		if (event.type.startsWith('touch')) {
			clientX = event.touches[0].clientX;
			clientY = event.touches[0].clientY;
		} else {
			clientX = event.clientX;
			clientY = event.clientY;
		}

		const x = clamp(clientX - left, 0, width);
		const y = clamp(clientY - top, 0, height);

		const s = (x / width) * 100;
		const v = 100 - (y / height) * 100;

		const rgb = hsvToRgb({ h: ParsedColor?.hsv.h, s, v });
		const hex = rgbToHex(rgb);

		setColor(hex);
	};

	const onHueChange = (event) => {
		if (event.type === 'mousemove' && event.buttons !== 1) return;

		const { width, left } = event.target.getBoundingClientRect();
		let clientX;

		if (event.type.startsWith('touch')) {
			clientX = event.touches[0].clientX;
		} else {
			clientX = event.clientX;
		}

		const x = clamp(clientX - left, 0, width);
		const h = Math.round((x / width) * 360);

		const rgb = hsvToRgb({ h, s: ParsedColor?.hsv.s, v: ParsedColor?.hsv.v });
		const hex = rgbToHex(rgb);

		setColor(hex);
	};

	return (
		<div className={classNames({ modal: true, 'color-picker-wrapper': true })}>
			<div className={classNames({ modal: true, 'color-picker': true })}>
				<div className={classNames({ header: true })}>
					<span className={classNames({ 'header-title': true })}>{t('color-picker-modal.header.title')}</span>
					<div
						className={classNames({ 'header-close': true })}
						onClick={closeModal}
					>
						<CloseIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					</div>
				</div>
				<div className={classNames({ content: true })}>
					<div
						className={classNames({ saturation: true })}
						style={{ backgroundColor: `hsl(${ParsedColor.hsv.h}, 100%, 50%)` }}
						onMouseDown={() => SetMouseDowned(true)}
						onMouseUp={() => SetMouseDowned(false)}
						onTouchStart={() => SetMouseDowned(true)}
						onTouchEnd={() => SetMouseDowned(false)}
						onMouseMove={onSaturationChange}
						onTouchMove={onSaturationChange}
						onClick={onSaturationChange}
					>
						<div
							className={classNames({ 'saturation-indicator': true })}
							style={{
								backgroundColor: '#' + ParsedColor.hex,
								left: (SaturationCoords?.[0] ?? 0) + '%',
								top: (SaturationCoords?.[1] ?? 0) + '%',
							}}
						/>
					</div>
					<div
						className={classNames({ hue: true })}
						onMouseDown={() => SetMouseDowned(true)}
						onMouseUp={() => SetMouseDowned(false)}
						onTouchStart={() => SetMouseDowned(true)}
						onTouchEnd={() => SetMouseDowned(false)}
						onMouseMove={onHueChange}
						onTouchMove={onHueChange}
						onClick={onHueChange}
					>
						<div
							className={classNames({ 'hue-indicator': true })}
							style={{
								backgroundColor: `hsl(${ParsedColor.hsv.h}, 100%, 50%)`,
								left: (HueCoords ?? 0) + '%',
							}}
						/>
					</div>

					<div className={classNames({ inputs: true })}>
						<div className={classNames({ 'hex-wrapper': true })}>
							<span className={classNames({ 'hex-prefix': true })}>#</span>
							<input
								className='hex-input'
								value={ParsedColor?.hex.toUpperCase()}
								onChange={(e) => setColor(e.target.value)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
