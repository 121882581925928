import './style.sass';

import classNames from 'classnames';
import { isEqual } from 'lodash';
import React from 'react';

import getWearableLeggsColorTargets from '../../utils/avatar/getWearableLeggsColorTargets';
import getWearableBodyColorTargets from '../../utils/avatar/getWearableBodyColorTargets';
import getBeardColorTargets from '../../utils/avatar/getBeardColorTargets';
import getWingsColorTargets from '../../utils/avatar/getWingsColorTargets';
import getEyesColorTargets from '../../utils/avatar/getEyesColorTargets';
import getHairColorTargets from '../../utils/avatar/getHairColorTargets';
import getTailColorTargets from '../../utils/avatar/getTailColorTargets';
import getSkinColorTargets from '../../utils/avatar/getSkinColorTargets';
import drawParts from './drawParts';

import { defaultBeardColor, defaultEyesColor, defaultHairsColor, defaultSkinColor, defaultWingsColor } from '../../common/avatar';

export default ({
	wearable,
	appearance,
	race = 'human',
	equipment = {},
	gender = 'male',
	withWearable = false,
	withUnderwear = false,
}) => {
	const wearableLeggsCanvas = React.useRef(false);
	const wearableBootsCanvas = React.useRef(false);
	const wearableBodyCanvas = React.useRef(false);
	const avatarRendererRef = React.useRef(false);
	const hairsFrontCanvas = React.useRef(false);
	const equipmentCanvas = React.useRef(false);
	const hairsBackCanvas = React.useRef(false);
	const eyebrowsCanvas = React.useRef(false);
	const patternCanvas = React.useRef(false);
	const beardCanvas = React.useRef(false);
	const mouthCanvas = React.useRef(false);
	const wingsCanvas = React.useRef(false);
	const hornsCanvas = React.useRef(false);
	const bodyCanvas = React.useRef(false);
	const eyesCanvas = React.useRef(false);
	const tailCanvas = React.useRef(false);

	const loaderWrapper = React.useRef(false);

	const [SavedEquipmentLeggs, SetSavedEquipmentLeggs] = React.useState('');
	const [SavedEquipmentBoots, SetSavedEquipmentBoots] = React.useState('');
	const [SavedWearableLeggs, SetSavedWearableLeggs] = React.useState('');
	const [SavedWearableBoots, SetSavedWearableBoots] = React.useState('');
	const [SavedEquipmentBody, SetSavedEquipmentBody] = React.useState('');
	const [SavedWearableBody, SetSavedWearableBody] = React.useState('');
	const [SavedEyebrows, SetSavedEyebrows] = React.useState('');
	const [SavedGender, SetSavedGender] = React.useState('');
	const [SavedHairs, SetSavedHairs] = React.useState('');
	const [SavedWings, SetSavedWings] = React.useState('');
	const [SavedHorns, SetSavedHorns] = React.useState('');
	const [SavedMouth, SetSavedMouth] = React.useState('');
	const [SavedBeard, SetSavedBeard] = React.useState('');
	const [SavedRace, SetSavedRace] = React.useState('');
	const [SavedTail, SetSavedTail] = React.useState('');
	const [SavedBody, SetSavedBody] = React.useState('');
	const [SavedHead, SetSavedHead] = React.useState('');
	const [SavedSkin, SetSavedSkin] = React.useState('');
	const [SavedEyes, SetSavedEyes] = React.useState('');

	const [CanvasHeight, SetCanvasHeight] = React.useState(367);
	const [CanvasWidth, SetCanvasWidth] = React.useState(353);

	const draw = async (update = []) => {
		// return if canvases not load
		if (!patternCanvas.current) return;

		// get pattern canvas context
		let patternCanvasContext = patternCanvas.current.getContext('2d', { willReadFrequently: true });

		if (update.includes('gender') || update.includes('race') || update.includes('hairs-color') || update.includes('hairs-back')) {
			// return if canvases not load
			if (!hairsBackCanvas.current) return;

			// get hairs back canvas context
			let hairsBackCanvasContext = hairsBackCanvas.current.getContext('2d', { willReadFrequently: true });

			// draw hairs back part
			await drawParts({
				getReplaceColors: getHairColorTargets,
				mainCanvasContext: hairsBackCanvasContext,
				patternCanvasContext: patternCanvasContext,
				parts: [`avatar-parts/${gender}/${race}/appearance/hairs/${appearance.hairs.back || 0}`],
				color: defaultHairsColor.includes(appearance.hairs.color) ? false : appearance.hairs.color,
			});
		}

		// return if canvases not load
		if (!wingsCanvas.current) return;

		// get wings canvas context
		let wingsCanvasContext = wingsCanvas.current.getContext('2d', { willReadFrequently: true });

		if (race == 'demon' && (update.includes('gender') || update.includes('race') || update.includes('wings'))) {
			// draw wings part
			await drawParts({
				mainCanvasContext: wingsCanvasContext,
				getReplaceColors: getWingsColorTargets,
				patternCanvasContext: patternCanvasContext,
				parts: [`avatar-parts/${gender}/${race}/appearance/wings/${appearance.wings.type || 0}`],
				color: defaultWingsColor.includes(appearance.wings.color) ? false : appearance.wings.color,
			});
		} else if (race != 'demon') {
			// clear canvas
			wingsCanvasContext.clearRect(0, 0, wingsCanvasContext.canvas.width, wingsCanvasContext.canvas.height);
		}

		// return if canvases not load
		if (!tailCanvas.current) return;

		// get tail canvas context
		let tailCanvasContext = tailCanvas.current.getContext('2d', { willReadFrequently: true });

		if (race == 'demon' && (update.includes('gender') || update.includes('race') || update.includes('tail'))) {
			// draw tail part
			await drawParts({
				color: appearance.skin,
				mainCanvasContext: tailCanvasContext,
				getReplaceColors: getTailColorTargets,
				patternCanvasContext: patternCanvasContext,
				parts: [`avatar-parts/${gender}/${race}/appearance/tails/${appearance.tail}`],
			});
		} else if (race != 'demon') {
			// clear canvas
			tailCanvasContext.clearRect(0, 0, tailCanvasContext.canvas.width, tailCanvasContext.canvas.height);
		}

		if (
			update.includes('gender') ||
			update.includes('race') ||
			update.includes('body') ||
			update.includes('head') ||
			update.includes('skin')
		) {
			// return if canvases not load
			if (!bodyCanvas.current) return;

			// get body canvas context
			let bodyCanvasContext = bodyCanvas.current.getContext('2d', { willReadFrequently: true });

			// draw body parts
			await drawParts({
				mainCanvasContext: bodyCanvasContext,
				patternCanvasContext: patternCanvasContext,
				getReplaceColors: getSkinColorTargets(race),
				parts: [
					`avatar-parts/${gender}/${race}/appearance/bodies/${appearance.body}`,
					`avatar-parts/${gender}/${race}/appearance/heads/${appearance.head}`,
				],
				color: defaultSkinColor[race].includes(appearance.skin) ? false : appearance.skin,
			});
		}

		// return if canvases not load
		if (!hornsCanvas.current) return;

		// get horns canvas context
		let hornsCanvasContext = hornsCanvas.current.getContext('2d', { willReadFrequently: true });

		if (race == 'demon' && (update.includes('gender') || update.includes('race') || update.includes('horns'))) {
			// draw hairs back part
			await drawParts({
				mainCanvasContext: hornsCanvasContext,
				patternCanvasContext: patternCanvasContext,
				getReplaceColors: getSkinColorTargets(race),
				parts: [`avatar-parts/${gender}/${race}/appearance/horns/${appearance.horns.type}`],
				color: defaultHairsColor.includes(appearance.horns.color) ? false : appearance.horns.color,
			});
		} else if (race != 'demon') {
			// clear canvas
			hornsCanvasContext.clearRect(0, 0, hornsCanvasContext.canvas.width, hornsCanvasContext.canvas.height);
		}

		if (update.includes('gender') || update.includes('race') || update.includes('eyebrows')) {
			// return if canvases not load
			if (!eyebrowsCanvas.current) return;

			// get eyebrows canvas context
			let eyebrowsCanvasContext = eyebrowsCanvas.current.getContext('2d', { willReadFrequently: true });

			// draw tail part
			await drawParts({
				mainCanvasContext: eyebrowsCanvasContext,
				parts: [`avatar-parts/${gender}/${race}/appearance/eyebrows/${appearance.eyebrows}`],
			});
		}

		if (update.includes('gender') || update.includes('race') || update.includes('eyes')) {
			// return if canvases not load
			if (!eyesCanvas.current) return;

			// get eyes canvas context
			let eyesCanvasContext = eyesCanvas.current.getContext('2d', { willReadFrequently: true });

			// draw tail part
			await drawParts({
				mainCanvasContext: eyesCanvasContext,
				getReplaceColors: getEyesColorTargets,
				patternCanvasContext: patternCanvasContext,
				parts: [`avatar-parts/${gender}/${race}/appearance/eyes/${appearance.eyes.type}`],
				color: defaultEyesColor.includes(appearance.eyes.color) ? false : appearance.eyes.color,
			});
		}

		if (update.includes('gender') || update.includes('race') || update.includes('mouth')) {
			// return if canvases not load
			if (!mouthCanvas.current) return;

			// get mouth canvas context
			let mouthCanvasContext = mouthCanvas.current.getContext('2d', { willReadFrequently: true });

			// draw tail part
			await drawParts({
				mainCanvasContext: mouthCanvasContext,
				parts: [`avatar-parts/${gender}/${race}/appearance/mouths/${appearance.mouth}`],
			});
		}

		// return if canvases not load
		if (!beardCanvas.current) return;

		// get beard canvas context
		let beardCanvasContext = beardCanvas.current.getContext('2d', { willReadFrequently: true });

		if (
			appearance.beard.type !== false &&
			(gender == 'male' || race == 'dwarve') &&
			(update.includes('gender') || update.includes('race') || update.includes('beard'))
		) {
			// draw beard part
			await drawParts({
				mainCanvasContext: beardCanvasContext,
				getReplaceColors: getBeardColorTargets,
				patternCanvasContext: patternCanvasContext,
				parts: [`avatar-parts/${gender}/${race}/appearance/beards/${appearance.beard.type}`],
				color: defaultBeardColor.includes(appearance.beard.color) ? false : appearance.beard.color,
			});
		} else {
			// clear canvas
			beardCanvasContext.clearRect(0, 0, beardCanvasContext.canvas.width, beardCanvasContext.canvas.height);
		}

		if (
			update.includes('gender') ||
			update.includes('race') ||
			update.includes('hairs-color') ||
			update.includes('hairs-front')
		) {
			// return if canvases not load
			if (!hairsFrontCanvas.current) return;

			// get hairs back canvas context
			let hairsFrontCanvasContext = hairsFrontCanvas.current.getContext('2d', { willReadFrequently: true });

			// draw hairs back part
			await drawParts({
				getReplaceColors: getHairColorTargets,
				mainCanvasContext: hairsFrontCanvasContext,
				patternCanvasContext: patternCanvasContext,
				parts: [`avatar-parts/${gender}/${race}/appearance/bangs/${appearance.hairs.front}`],
				color: defaultHairsColor.includes(appearance.hairs.color) ? false : appearance.hairs.color,
			});
		}

		if (withWearable) {
			if (update.includes('gender') || update.includes('race') || update.includes('body') || update.includes('wearable-body')) {
				// return if canvases not load
				if (!wearableBodyCanvas.current) return;

				// get wearable body canvas context
				let wearableBodyCanvasContext = wearableBodyCanvas.current.getContext('2d', { willReadFrequently: true });

				// draw wearable body part
				await drawParts({
					patternCanvasContext: patternCanvasContext,
					mainCanvasContext: wearableBodyCanvasContext,
					getReplaceColors: getWearableBodyColorTargets,
					color: defaultHairsColor.includes(wearable.body.color) ? false : wearable.body.color,
					parts: [`avatar-parts/${gender}/${race}/wearable/body/${appearance.body}/${wearable.body.type}`],
				});
			}

			if (update.includes('gender') || update.includes('race') || update.includes('body') || update.includes('wearable-leggs')) {
				// return if canvases not load
				if (!wearableLeggsCanvas.current) return;

				// get wearable leggs canvas context
				let wearableLeggsCanvasContext = wearableLeggsCanvas.current.getContext('2d', { willReadFrequently: true });

				// draw wearable leggs part
				await drawParts({
					patternCanvasContext: patternCanvasContext,
					mainCanvasContext: wearableLeggsCanvasContext,
					getReplaceColors: getWearableLeggsColorTargets,
					color: defaultHairsColor.includes(wearable.leggs.color) ? false : wearable.leggs.color,
					parts: [`avatar-parts/${gender}/${race}/wearable/leggs/${appearance.body}/${wearable.leggs.type}`],
				});
			}

			if (update.includes('gender') || update.includes('race') || update.includes('body') || update.includes('wearable-boots')) {
				// return if canvases not load
				if (!wearableBootsCanvas.current) return;

				// get wearable boots canvas context
				let wearableBootsCanvasContext = wearableBootsCanvas.current.getContext('2d', { willReadFrequently: true });

				// draw wearable boots part
				await drawParts({
					mainCanvasContext: wearableBootsCanvasContext,
					parts: [`avatar-parts/${gender}/${race}/wearable/boots/${appearance.body}/${wearable.boots.type}`],
				});
			}
		} else if (withUnderwear) {
			if (update.includes('gender') || update.includes('race') || update.includes('body')) {
				// return if canvases not load
				if (!wearableLeggsCanvas.current) return;

				// get wearable leggs canvas context
				let wearableLeggsCanvasContext = wearableLeggsCanvas.current.getContext('2d', { willReadFrequently: true });

				// draw hairs back part
				await drawParts({
					mainCanvasContext: wearableLeggsCanvasContext,
					parts: [`avatar-parts/${gender}/${race}/wearable/underwear/${appearance.body}/0`],
				});
			}
		}

		if (update.includes('equipment-body') || update.includes('equipment-leggs') || update.includes('equipment-boots')) {
			// return if canvases not load
			if (!equipmentCanvas.current) return;

			// get equipment canvas context
			let equipmentCanvasContext = equipmentCanvas.current.getContext('2d', { willReadFrequently: true });

			// draw equipment
			await drawParts({
				mainCanvasContext: equipmentCanvasContext,
				parts: [
					...(equipment.body
						? [`avatar-parts/${gender}/${race}/equipment/body/${appearance.body}/${equipment.body.wearable}`]
						: []),
					...(equipment.leggs
						? [`avatar-parts/${gender}/${race}/equipment/leggs/${appearance.body}/${equipment.leggs.wearable}`]
						: []),
					...(equipment.boots
						? [`avatar-parts/${gender}/${race}/equipment/boots/${appearance.body}/${equipment.boots.wearable}`]
						: []),
				],
			});
		}

		if (loaderWrapper.current && !loaderWrapper.current.classList.contains('hidden')) {
			// hide loader
			if (loaderWrapper.current) loaderWrapper.current.classList.add('hidden');
		}
	};

	React.useEffect(() => {
		if (avatarRendererRef.current) {
			const BoundingClientRect = avatarRendererRef.current.getBoundingClientRect();

			SetCanvasHeight(BoundingClientRect.height);
			SetCanvasWidth(BoundingClientRect.width);
		}
	}, []);

	React.useEffect(() => {
		// set default vars
		const update = [];

		if (SavedGender != gender) {
			// push update
			update.push('gender');

			// update saved hairs
			SetSavedGender(gender);
		}

		if (SavedRace != race) {
			// push update
			update.push('race');

			// update saved hairs
			SetSavedRace(race);
		}

		if (SavedHairs != appearance.hairs) {
			if (SavedHairs.color != appearance.hairs.color) {
				// push update
				update.push('hairs-color');
			}

			if (SavedHairs.back != appearance.hairs.back) {
				// push update
				update.push('hairs-back');
			}

			if (SavedHairs.front != appearance.hairs.front) {
				// push update
				update.push('hairs-front');
			}

			// update saved hairs
			SetSavedHairs(appearance.hairs);
		}

		if (SavedBody != appearance.body) {
			// push update
			update.push('body');

			// update saved body
			SetSavedBody(appearance.body);
		}

		if (SavedHead != appearance.head) {
			// push update
			update.push('head');

			// update saved head
			SetSavedHead(appearance.head);
		}

		if (SavedSkin != appearance.skin) {
			// push update
			update.push('skin');

			// update saved skin
			SetSavedSkin(appearance.skin);
		}

		if (SavedEyebrows != appearance.eyebrows) {
			// push update
			update.push('eyebrows');

			// update saved eyebrows
			SetSavedEyebrows(appearance.eyebrows);
		}

		if (!isEqual(SavedEyes, appearance.eyes)) {
			// push update
			update.push('eyes');

			// update saved eyes
			SetSavedEyes(appearance.eyes);
		}

		if (SavedTail != appearance.tail) {
			// push update
			update.push('tail');

			// update saved tail
			SetSavedTail(appearance.tail);
		}

		if (!isEqual(SavedWings, appearance.wings)) {
			// push update
			update.push('wings');

			// update saved wings
			SetSavedWings(appearance.wings);
		}

		if (!isEqual(SavedHorns, appearance.horns)) {
			// push update
			update.push('horns');

			// update saved horns
			SetSavedHorns(appearance.horns);
		}

		if (!isEqual(SavedBeard, appearance.beard)) {
			// push update
			update.push('beard');

			// update saved beard
			SetSavedBeard(appearance.beard);
		}

		if (SavedMouth != appearance.mouth) {
			// push update
			update.push('mouth');

			// update saved mouth
			SetSavedMouth(appearance.mouth);
		}

		if (!isEqual(SavedWearableBody, wearable.body)) {
			// push update
			update.push('wearable-body');

			// update saved wearable body
			SetSavedWearableBody(wearable.body);
		}

		if (!isEqual(SavedWearableLeggs, wearable.leggs)) {
			// push update
			update.push('wearable-leggs');

			// update saved wearable leggs
			SetSavedWearableLeggs(wearable.leggs);
		}

		if (!isEqual(SavedWearableBoots, wearable.boots)) {
			// push update
			update.push('wearable-boots');

			// update saved wearable boots
			SetSavedWearableBoots(wearable.boots);
		}

		if (SavedEquipmentBody != equipment?.body?.wearable) {
			// push update
			update.push('equipment-body');

			// update saved equipment body
			SetSavedEquipmentBody(equipment?.body?.wearable);
		}

		if (SavedEquipmentLeggs != equipment?.leggs?.wearable) {
			// push update
			update.push('equipment-leggs');

			// update saved equipment leggs
			SetSavedEquipmentLeggs(equipment?.leggs?.wearable);
		}

		if (SavedEquipmentBoots != equipment?.boots?.wearable) {
			// push update
			update.push('equipment-boots');

			// update saved equipment boots
			SetSavedEquipmentBoots(equipment?.boots?.wearable);
		}

		// call draw function
		draw(update);
	}, [gender, race, appearance, wearable, equipment]);

	return (
		<div
			className={classNames({ 'avatar-renderer': true })}
			ref={(ref) => (avatarRendererRef.current = ref)}
		>
			<canvas
				ref={(ref) => (hairsBackCanvas.current = ref)}
				className={classNames({ 'parts-canvas': true })}
				width={CanvasWidth}
				height={CanvasHeight}
			/>
			<canvas
				ref={(ref) => (wingsCanvas.current = ref)}
				className={classNames({ 'parts-canvas': true })}
				width={CanvasWidth}
				height={CanvasHeight}
			/>
			<canvas
				ref={(ref) => (tailCanvas.current = ref)}
				className={classNames({ 'parts-canvas': true })}
				width={CanvasWidth}
				height={CanvasHeight}
			/>
			<canvas
				ref={(ref) => (bodyCanvas.current = ref)}
				className={classNames({ 'parts-canvas': true })}
				width={CanvasWidth}
				height={CanvasHeight}
			/>
			<canvas
				ref={(ref) => (hornsCanvas.current = ref)}
				className={classNames({ 'parts-canvas': true })}
				width={CanvasWidth}
				height={CanvasHeight}
			/>
			<canvas
				ref={(ref) => (eyebrowsCanvas.current = ref)}
				className={classNames({ 'parts-canvas': true })}
				width={CanvasWidth}
				height={CanvasHeight}
			/>
			<canvas
				ref={(ref) => (eyesCanvas.current = ref)}
				className={classNames({ 'parts-canvas': true })}
				width={CanvasWidth}
				height={CanvasHeight}
			/>
			<canvas
				ref={(ref) => (mouthCanvas.current = ref)}
				className={classNames({ 'parts-canvas': true })}
				width={CanvasWidth}
				height={CanvasHeight}
			/>
			<canvas
				ref={(ref) => (beardCanvas.current = ref)}
				className={classNames({ 'parts-canvas': true })}
				width={CanvasWidth}
				height={CanvasHeight}
			/>
			<canvas
				ref={(ref) => (hairsFrontCanvas.current = ref)}
				className={classNames({ 'parts-canvas': true })}
				width={CanvasWidth}
				height={CanvasHeight}
			/>
			<canvas
				ref={(ref) => (wearableBodyCanvas.current = ref)}
				className={classNames({ 'parts-canvas': true })}
				width={CanvasWidth}
				height={CanvasHeight}
			/>
			<canvas
				ref={(ref) => (wearableLeggsCanvas.current = ref)}
				className={classNames({
					'parts-canvas': true,
					'leggs-upper': wearable.leggs.type == 1 && wearable.boots.type == 0,
				})}
				width={CanvasWidth}
				height={CanvasHeight}
			/>
			<canvas
				ref={(ref) => (wearableBootsCanvas.current = ref)}
				className={classNames({ 'parts-canvas': true })}
				width={CanvasWidth}
				height={CanvasHeight}
			/>
			<canvas
				ref={(ref) => (equipmentCanvas.current = ref)}
				className={classNames({ 'parts-canvas': true })}
				width={CanvasWidth}
				height={CanvasHeight}
			/>

			<canvas
				className={classNames({ 'pattern-canvas': true })}
				ref={(ref) => (patternCanvas.current = ref)}
				width={CanvasWidth}
				height={CanvasHeight}
			/>

			<div
				className={classNames({ 'loader-wrapper': true })}
				ref={(ref) => (loaderWrapper.current = ref)}
			>
				<span className={classNames({ loader: true })} />
			</div>
		</div>
	);
};
