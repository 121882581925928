import { handleActions } from 'redux-actions';

import { loginTelegramAsync, getUserInfoAsync, clearUserErrorAsync } from '../../actions/user';

import { defaultError } from '../../../common/errors';

const initialState = {
	validated: null,
	whitelisted: null,
	state: null,
	error: null,
};

export default handleActions(
	{
		[getUserInfoAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			state: response.success ? response.result.user : response.null,
			error: response.success ? null : response.message ? response.message : defaultError,
		}),
		[loginTelegramAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			validated: response.success ? response.result.validated : false,
			whitelisted: response.success ? response.result.whitelisted : false,
			error: response.success ? null : response.message ? response.message : defaultError,
		}),
		[clearUserErrorAsync.success]: (s, p) => ({ ...s, error: null }),

		[getUserInfoAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			state: null,
		}),
		[loginTelegramAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			validated: false,
			whitelisted: false,
			state: null,
		}),
	},
	initialState
);
