import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('FOODS');

export const getFoods = factory.create('GET_FOODS');
export const getFoodsAsync = factory.createAsync('GET_FOODS_ASYNC');

export const cookFood = factory.create('COOK_FOOD');
export const cookFoodAsync = factory.createAsync('COOK_FOOD_ASYNC');

export const changeFoodsField = factory.create('CHANGE_FOODS_FIELD');
export const changeFoodsFieldAsync = factory.createAsync('CHANGE_FOODS_FIELD_ASYNC');

export const clearFoodsError = factory.create('CLEAR_FOODS_ERROR');
export const clearFoodsErrorAsync = factory.createAsync('CLEAR_FOODS_ERROR_ASYNC');
