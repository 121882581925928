import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('AVATAR');

export const createAvatar = factory.create('CREATE_AVATAR');
export const createAvatarAsync = factory.createAsync('CREATE_AVATAR_ASYNC');

export const getAvatarInfo = factory.create('GET_AVATAR_INFO');
export const getAvatarInfoAsync = factory.createAsync('GET_AVATAR_INFO_ASYNC');

export const getAvatarGuildTasks = factory.create('GET_AVATAR_GUILD_TASKS');
export const getAvatarGuildTasksAsync = factory.createAsync('GET_AVATAR_GUILD_TASKS_ASYNC');

export const getAvatarSocialTasks = factory.create('GET_AVATAR_SOCIAL_TASKS');
export const getAvatarSocialTasksAsync = factory.createAsync('GET_AVATAR_SOCIAL_TASKS_ASYNC');

export const getAvatarEffects = factory.create('GET_AVATAR_EFFECTS');
export const getAvatarEffectsAsync = factory.createAsync('GET_AVATAR_EFFECTS_ASYNC');

export const useTaskStep = factory.create('USE_TASK_STEP');
export const useTaskStepAsync = factory.createAsync('USE_TASK_STEP_ASYNC');

export const checkTaskStep = factory.create('CHECK_TASK_STEP');
export const checkTaskStepAsync = factory.createAsync('CHECK_TASK_STEP_ASYNC');

export const completeTask = factory.create('COMPLETE_TASK');
export const completeTaskAsync = factory.createAsync('COMPLETE_TASK_ASYNC');

export const updateAvatarState = factory.create('UPDATE_AVATAR_STATE');
export const updateAvatarStateAsync = factory.createAsync('UPDATE_AVATAR_STATE_ASYNC');

export const updateAvatarGuildTasks = factory.create('UPDATE_AVATAR_GUILD_TASKS');
export const updateAvatarGuildTasksAsync = factory.createAsync('UPDATE_AVATAR_GUILD_TASKS_ASYNC');

export const changeAvatarEquipment = factory.create('CHANGE_AVATAR_EQUIPMENT_TASKS');
export const changeAvatarEquipmentAsync = factory.createAsync('CHANGE_AVATAR_EQUIPMENT_TASKS_ASYNC');

export const upgradeAvatarLevel = factory.create('UPGRADE_AVATAR_LEVEL');
export const upgradeAvatarLevelAsync = factory.createAsync('UPGRADE_AVATAR_LEVEL_ASYNC');

export const upgradeAvatarSkill = factory.create('UPGRADE_AVATAR_SKILL');
export const upgradeAvatarSkillAsync = factory.createAsync('UPGRADE_AVATAR_SKILL_ASYNC');

export const clearAvatarError = factory.create('CLEAR_AVATAR_ERROR');
export const clearAvatarErrorAsync = factory.createAsync('CLEAR_AVATAR_ERROR_ASYNC');
