import axios from 'axios';

import { api_url } from '../../../common/config';

axios.defaults.withCredentials = true;

export default class {
	static getAvatarInventoryEndpoint = () => axios.get(`${api_url}inventory`);
	static getAvatarEquipmentEndpoint = (params) => axios.get(`${api_url}inventory/equipment`, { params });
	static useInventoryItemEndpoint = (data) => axios.post(`${api_url}inventory/use`, data);
}
