import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('POTIONS');

export const getPotions = factory.create('GET_POTIONS');
export const getPotionsAsync = factory.createAsync('GET_POTIONS_ASYNC');

export const brewPotion = factory.create('BREW_POTION');
export const brewPotionAsync = factory.createAsync('BREW_POTION_ASYNC');

export const changePotionsField = factory.create('CHANGE_POTIONS_FIELD');
export const changePotionsFieldAsync = factory.createAsync('CHANGE_POTIONS_FIELD_ASYNC');

export const clearPotionsError = factory.create('CLEAR_POTIONS_ERROR');
export const clearPotionsErrorAsync = factory.createAsync('CLEAR_POTIONS_ERROR_ASYNC');
