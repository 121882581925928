import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			style={{
				shapeRendering: 'geometricPrecision',
				textRendering: 'geometricPrecision',
				imageRendering: 'optimizeQuality',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			}}
		>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.919 }}
					d='M 444.5,-0.5 C 447.833,-0.5 451.167,-0.5 454.5,-0.5C 465.132,5.85739 466.798,14.0241 459.5,24C 447.085,29.2713 439.418,25.2713 436.5,12C 437.051,6.38028 439.718,2.21361 444.5,-0.5 Z'
				/>
			</g>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.917 }}
					d='M 404.5,72.5 C 417.334,71.8368 422.501,77.8368 420,90.5C 414.335,100.037 406.668,102.037 397,96.5C 390.979,85.7328 393.479,77.7328 404.5,72.5 Z'
				/>
			</g>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.957 }}
					d='M 259.5,107.5 C 281.671,108.836 294.504,120.502 298,142.5C 298.519,143.516 299.353,144.182 300.5,144.5C 345.833,133.833 391.167,123.167 436.5,112.5C 448.479,115.273 451.979,122.273 447,133.5C 444.612,136.634 441.446,138.467 437.5,139C 320.464,166.509 203.464,194.176 86.5,222C 72.4479,222.611 67.2812,216.111 71,202.5C 72.9384,199.894 75.4384,198.061 78.5,197C 123.472,186.306 168.472,175.806 213.5,165.5C 212.815,159.581 211.815,153.747 210.5,148C 211.034,133.262 217.701,122.262 230.5,115C 240.095,111.686 249.762,109.186 259.5,107.5 Z M 259.5,133.5 C 263.13,133.765 266.297,135.098 269,137.5C 271.042,141.842 272.542,146.342 273.5,151C 262.833,153.5 252.167,156 241.5,158.5C 240.833,158.167 240.167,157.833 239.5,157.5C 237.222,152.194 237.056,146.86 239,141.5C 240.214,139.951 241.714,138.785 243.5,138C 249.035,136.786 254.368,135.286 259.5,133.5 Z'
				/>
			</g>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.955 }}
					d='M 431.5,159.5 C 461.965,158.464 476.465,173.131 475,203.5C 466.043,228.395 448.876,236.895 423.5,229C 402.244,215.365 397.411,197.198 409,174.5C 415.045,167.078 422.545,162.078 431.5,159.5 Z M 435.5,185.5 C 447.116,185.077 451.283,190.41 448,201.5C 439.008,208.839 432.508,207.005 428.5,196C 429.242,191.326 431.575,187.826 435.5,185.5 Z'
				/>
			</g>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.948 }}
					d='M 343.5,187.5 C 371.085,189.114 386.419,203.614 389.5,231C 386.179,243.639 378.845,246.972 367.5,241C 365.959,239.421 364.792,237.587 364,235.5C 363.104,219.515 354.937,212.682 339.5,215C 331.716,219.055 327.882,225.555 328,234.5C 325.673,240.499 321.34,243.832 315,244.5C 304.933,242.084 300.933,235.751 303,225.5C 307.35,202.981 320.85,190.314 343.5,187.5 Z'
				/>
			</g>
			<g>
				<path
					fill={fill}
					style={{ opacity: 0.968 }}
					d='M 406.5,511.5 C 305.833,511.5 205.167,511.5 104.5,511.5C 80.6614,505.158 66.1614,489.824 61,465.5C 60.5,440.836 60.3334,416.169 60.5,391.5C 29.8074,392.806 14.474,378.14 14.5,347.5C 14.9823,317.353 30.3157,303.019 60.5,304.5C 60.3334,291.163 60.5001,277.829 61,264.5C 62.4421,260.725 64.9421,257.892 68.5,256C 193.167,255.333 317.833,255.333 442.5,256C 446.058,257.892 448.558,260.725 450,264.5C 450.5,277.829 450.667,291.163 450.5,304.5C 480.684,303.019 496.018,317.353 496.5,347.5C 496.526,378.14 481.193,392.806 450.5,391.5C 450.667,416.169 450.5,440.836 450,465.5C 444.839,489.824 430.339,505.158 406.5,511.5 Z M 86.5,281.5 C 199.167,281.5 311.833,281.5 424.5,281.5C 424.667,342.168 424.5,402.834 424,463.5C 419.935,477.231 410.768,484.731 396.5,486C 302.5,486.667 208.5,486.667 114.5,486C 100.232,484.731 91.065,477.231 87,463.5C 86.5,402.834 86.3333,342.168 86.5,281.5 Z M 48.5,330.5 C 52.5,330.5 56.5,330.5 60.5,330.5C 60.5,342.167 60.5,353.833 60.5,365.5C 54.928,366.01 49.5947,365.177 44.5,363C 43.2986,362.097 42.4652,360.931 42,359.5C 39.7532,351.037 40.0865,342.704 43,334.5C 44.4998,332.531 46.3331,331.198 48.5,330.5 Z M 450.5,330.5 C 464.86,327.698 471.526,333.531 470.5,348C 471.528,362.348 464.861,368.181 450.5,365.5C 450.5,353.833 450.5,342.167 450.5,330.5 Z'
				/>
			</g>
		</svg>
	);
};
