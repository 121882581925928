import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import { openModal, openModalAsync, closeModal, closeModalAsync } from '../../actions/modals';

function openModalWorker({ name, payload }) {
	return { name, payload };
}

function closeModalWorker() {
	return true;
}

export function* modalsSaga() {
	yield takeEvery(openModal, bindAsyncActions(openModalAsync)(openModalWorker));
	yield takeEvery(closeModal, bindAsyncActions(closeModalAsync)(closeModalWorker));
}
