import { handleActions } from 'redux-actions';

import { changeAppLanguageAsync } from '../../actions/settings';

const initialState = {
	storeVersion: '0.1',
	language: 'ru',
};

export default handleActions(
	{
		[changeAppLanguageAsync.success]: (s, { payload: { language } } = {}) => ({ ...s, language: language }),
	},
	initialState
);
