import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('CRAFTS');

export const getCrafts = factory.create('GET_CRAFTS');
export const getCraftsAsync = factory.createAsync('GET_CRAFTS_ASYNC');

export const craftItem = factory.create('CRAFT_ITEM');
export const craftItemAsync = factory.createAsync('CRAFT_ITEM_ASYNC');

export const changeCraftsField = factory.create('CHANGE_CRAFTS_FIELD');
export const changeCraftsFieldAsync = factory.createAsync('CHANGE_CRAFTS_FIELD_ASYNC');

export const clearCraftsError = factory.create('CLEAR_CRAFTS_ERROR');
export const clearCraftsErrorAsync = factory.createAsync('CLEAR_CRAFTS_ERROR_ASYNC');
