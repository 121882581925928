import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			preserveAspectRatio='xMidYMid meet'
		>
			<g
				transform='translate(0, 512) scale(0.100000,-0.100000)'
				fill={fill}
				stroke='none'
			>
				<path
					d='M2335 5106 c-287 -48 -508 -165 -707 -376 -189 -199 -299 -438 -328
-710 -13 -126 -13 -970 0 -1099 34 -327 198 -630 449 -831 77 -61 90 -76 85
-94 -11 -34 -44 -75 -73 -90 -22 -12 -220 -56 -250 -56 -4 0 -23 73 -41 162
-38 179 -57 215 -126 238 -47 16 -88 7 -157 -35 -32 -19 -61 -35 -65 -35 -4 0
-88 141 -187 312 -99 172 -196 330 -215 352 -104 117 -308 116 -411 -2 -71
-80 -92 -202 -52 -293 11 -24 97 -177 191 -340 94 -163 172 -301 174 -307 2
-6 -26 -28 -62 -49 -87 -51 -113 -91 -107 -162 4 -51 7 -57 68 -115 35 -34 93
-86 128 -117 l63 -55 -20 -40 c-11 -21 -30 -70 -43 -109 -24 -70 -24 -73 -27
-637 l-3 -568 25 -25 c32 -32 70 -32 101 0 l25 24 0 528 c0 589 2 608 66 741
28 58 56 95 118 158 116 116 182 146 430 195 105 21 200 39 211 39 12 0 77
-58 170 -150 165 -164 183 -173 230 -125 46 45 36 67 -90 195 -63 64 -115 119
-115 123 0 4 6 7 13 7 37 1 150 115 169 172 2 5 37 -5 78 -22 73 -30 82 -37
377 -333 l303 -302 -117 -117 -118 -118 -135 135 c-118 118 -139 135 -168 135
-41 0 -72 -30 -72 -72 0 -27 18 -50 135 -167 l135 -136 -415 -415 c-450 -450
-443 -442 -390 -495 55 -55 8 -97 941 836 l862 862 188 -37 c103 -20 190 -38
193 -41 3 -3 -340 -351 -762 -773 -694 -694 -767 -770 -767 -799 0 -37 38 -73
79 -73 21 0 175 149 821 795 l795 795 35 -21 c117 -71 220 -203 267 -342 l27
-82 4 -552 3 -553 26 -20 c47 -36 104 -16 118 43 4 13 5 264 3 558 l-4 534
-27 88 c-31 101 -53 148 -110 231 -84 124 -228 238 -367 289 -36 13 -187 48
-336 77 -287 55 -320 66 -354 122 -28 47 -25 59 23 93 67 48 195 174 249 247
l51 68 56 -48 c384 -330 842 -324 1202 15 73 70 92 107 77 158 -14 50 -48 74
-134 96 -110 27 -219 82 -276 141 -108 111 -114 230 -27 513 89 292 110 385
116 531 7 168 -1 239 -32 270 -30 30 -69 31 -99 1 -23 -23 -23 -27 -19 -163 6
-164 -7 -249 -69 -454 -104 -344 -124 -463 -99 -578 38 -177 176 -312 395
-386 l68 -23 -58 -47 c-71 -56 -217 -130 -299 -151 -135 -34 -314 -11 -453 59
-70 35 -208 134 -249 178 l-22 23 35 87 c19 48 44 126 56 175 21 84 22 113 25
682 l3 595 58 18 c170 52 291 195 324 381 l10 58 30 -28 c16 -15 55 -66 86
-113 31 -47 63 -90 72 -97 42 -34 110 3 110 59 0 81 -165 286 -302 375 -38 24
-68 48 -68 53 0 5 -28 39 -61 75 -174 186 -455 203 -646 40 l-42 -36 -58 44
c-135 102 -303 180 -472 218 -112 26 -337 33 -446 15z m351 -151 c462 -75 824
-439 894 -901 7 -47 10 -261 8 -630 -4 -533 -5 -563 -25 -634 -59 -212 -150
-371 -292 -510 -139 -138 -289 -225 -473 -276 -116 -32 -326 -43 -445 -24
-480 76 -849 463 -902 943 -14 121 -14 972 -1 1087 20 173 84 347 180 491 56
83 207 233 290 287 229 150 503 210 766 167z m1099 -154 c62 -28 130 -94 162
-160 57 -115 29 -267 -67 -359 -42 -42 -107 -78 -149 -84 -22 -3 -26 4 -51 78
-50 151 -124 285 -218 398 -47 56 -46 61 25 103 97 56 205 65 298 24z m-3199
-2040 c26 -16 94 -113 94 -134 0 -5 -213 -127 -222 -127 -4 0 -20 25 -37 55
-66 117 -21 224 94 225 22 0 54 -8 71 -19z m211 -332 c18 -30 32 -57 33 -60 0
-7 -219 -133 -224 -128 -16 17 -66 113 -63 122 4 13 199 127 212 123 5 -1 24
-27 42 -57z m153 -263 c19 -36 32 -67 30 -69 -3 -2 -52 -32 -110 -65 -117 -69
-109 -70 -158 18 l-31 55 107 62 c59 34 112 62 117 62 6 1 26 -28 45 -63z
m350 -70 c0 -2 11 -58 25 -126 14 -68 25 -129 25 -136 0 -8 -12 -16 -27 -20
-225 -49 -335 -100 -450 -208 l-79 -74 -97 85 -97 86 342 198 c317 183 358
206 358 195z m1779 -193 c19 -38 90 -112 123 -130 15 -7 -13 -40 -175 -201
l-192 -192 -219 219 -218 219 158 4 c175 6 288 29 420 86 38 16 72 30 76 31 4
1 16 -16 27 -36z'
				/>
				<path
					d='M2120 3906 c-190 -34 -361 -68 -381 -74 -40 -14 -98 -70 -125 -122
-18 -32 -19 -64 -19 -435 0 -393 0 -401 22 -441 25 -46 72 -91 118 -110 17 -7
200 -42 407 -79 l376 -66 379 67 c243 42 392 73 417 86 48 25 83 62 107 113
17 37 19 72 19 430 0 371 -1 392 -21 435 -25 56 -53 86 -102 111 -42 22 -745
150 -812 148 -22 -1 -195 -29 -385 -63z m758 -147 c189 -33 354 -64 367 -69
13 -5 29 -18 34 -29 7 -13 11 -148 11 -389 l0 -369 -24 -22 c-19 -18 -78 -31
-385 -86 l-362 -64 -337 59 c-185 33 -354 64 -374 70 -69 20 -69 23 -66 430 3
356 3 360 25 382 19 19 73 31 365 84 189 34 356 62 373 63 16 0 184 -26 373
-60z'
				/>
				<path
					d='M1967 3594 c-111 -27 -151 -66 -127 -125 20 -48 49 -55 132 -31 85
25 170 28 247 7 44 -11 60 -12 80 -2 48 23 54 104 9 128 -73 39 -232 50 -341
23z'
				/>
				<path
					d='M2790 3595 c-30 -9 -62 -23 -71 -31 -26 -24 -32 -63 -14 -91 24 -37
55 -44 116 -27 75 19 173 17 239 -6 72 -25 86 -25 115 5 52 51 24 107 -70 140
-70 24 -243 30 -315 10z'
				/>
				<path
					d='M2040 3282 c-30 -30 -30 -31 -30 -142 0 -104 1 -112 25 -135 30 -31
77 -33 105 -5 18 18 20 33 20 139 0 131 -9 154 -62 167 -22 5 -33 0 -58 -24z'
				/>
				<path
					d='M2900 3282 c-30 -30 -30 -31 -30 -142 0 -104 1 -112 25 -135 30 -31
73 -32 103 -5 20 19 22 30 22 139 0 131 -9 154 -62 167 -22 5 -33 0 -58 -24z'
				/>
				<path
					d='M1265 528 c-43 -23 -45 -32 -45 -260 l0 -219 25 -24 c13 -14 36 -25
50 -25 14 0 37 11 50 25 25 24 25 24 25 234 0 230 -5 254 -53 271 -16 5 -30
10 -31 10 -1 0 -10 -6 -21 -12z'
				/>
				<path
					d='M3720 533 c-49 -18 -50 -22 -50 -264 l0 -228 26 -20 c34 -26 68 -27
94 -1 29 29 31 49 28 268 -3 186 -5 203 -23 221 -23 22 -53 32 -75 24z'
				/>
			</g>
		</svg>
	);
};
