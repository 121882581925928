import { handleActions } from 'redux-actions';

import { bankDepositFundsAsync, bankWithdrawFundsAsync } from '../../actions/bank';
import { townhallFundraisersDonateAsync } from '../../actions/townhall';
import { useInventoryItemAsync } from '../../actions/inventory';
import { takeJourneyAsync } from '../../actions/locations';
import { takeGuildTaskAsync } from '../../actions/guild';
import { brewPotionAsync } from '../../actions/potions';
import { craftItemAsync } from '../../actions/crafts';
import { cookFoodAsync } from '../../actions/foods';
import {
	createAvatarAsync,
	getAvatarInfoAsync,
	getAvatarGuildTasksAsync,
	getAvatarSocialTasksAsync,
	getAvatarEffectsAsync,
	useTaskStepAsync,
	checkTaskStepAsync,
	completeTaskAsync,
	updateAvatarStateAsync,
	updateAvatarGuildTasksAsync,
	changeAvatarEquipmentAsync,
	upgradeAvatarLevelAsync,
	upgradeAvatarSkillAsync,
	clearAvatarErrorAsync,
} from '../../actions/avatar';

import { defaultError } from '../../../common/errors';

const initialState = {
	tasks: {
		guild: [],
		social: [],
	},
	effects: [],
	state: null,
	error: null,
};

export default handleActions(
	{
		[getAvatarInfoAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			state: response.success ? response.result.avatar : null,
		}),
		[createAvatarAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			state: response.success ? response.result.avatar : null,
		}),
		[changeAvatarEquipmentAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			state: response.success
				? { ...s.state, equipment: { ...s.state.equipment, ...response.result.avatar.equipment } }
				: s.state,
		}),
		[upgradeAvatarLevelAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			state: response.success ? { ...s.state, ...response.result.avatar } : s.state,
		}),
		[upgradeAvatarSkillAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			state: response.success ? { ...s.state, ...response.result.avatar } : s.state,
		}),
		[getAvatarGuildTasksAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			tasks: {
				...s.tasks,
				guild: response.success ? response.result.tasks : [],
			},
		}),
		[getAvatarSocialTasksAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			tasks: {
				...s.tasks,
				social: response.success ? response.result.tasks : [],
			},
		}),
		[getAvatarEffectsAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			effects: response.success ? response.result.effects : [],
		}),
		[useTaskStepAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			tasks: {
				...s.tasks,
				social: response.success
					? s.tasks.social.map((v) => (v._id == response.result.task._id ? response.result.task : v))
					: s.tasks.social,
			},
		}),
		[checkTaskStepAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			tasks: {
				...s.tasks,
				social: response.success
					? s.tasks.social.map((v) => (v._id == response.result.task._id ? response.result.task : v))
					: s.tasks.social,
			},
		}),
		[completeTaskAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			tasks: {
				guild: response.success ? s.tasks.guild.filter((v) => v._id != response.result.taskId) : s.tasks.guild,
				social: response.success ? s.tasks.social.filter((v) => v._id != response.result.taskId) : s.tasks.social,
			},
		}),
		[updateAvatarStateAsync.success]: (s, { payload: { response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			state: response.success
				? { ...s.state, ...response.result.avatar, equipment: { ...s.state.equipment, ...response.result.avatar.equipment } }
				: s.state,
		}),
		[updateAvatarGuildTasksAsync.success]: (s, { payload: { response } } = {}) => ({
			...s,
			tasks: {
				...s.tasks,
				guild:
					response.success && response.result.tasks.length
						? s.tasks.guild.reduce((result, value) => {
								const task = response.result.tasks.find((v) => v._id == value._id);

								if (task) value.steps = task.steps;

								return [...result, value];
						  }, [])
						: s.tasks.guild,
			},
		}),
		[clearAvatarErrorAsync.success]: (s, p) => ({ ...s, error: null }),

		[getAvatarInfoAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			state: null,
		}),
		[createAvatarAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			state: null,
		}),
		[changeAvatarEquipmentAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
		[upgradeAvatarLevelAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
		[upgradeAvatarSkillAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
		[getAvatarGuildTasksAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			tasks: { ...s.tasks, guild: [] },
		}),
		[getAvatarSocialTasksAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			tasks: { ...s.tasks, social: [] },
		}),
		[getAvatarEffectsAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			effects: [],
		}),
		[useTaskStepAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
		[checkTaskStepAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
		[completeTaskAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
		//
		//
		//
		[takeGuildTaskAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			tasks: {
				...s.tasks,
				guild: response.success ? [response.result.avatarTask, ...s.tasks.guild] : s.tasks.guild,
			},
		}),
		[takeJourneyAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			tasks: {
				...s.tasks,
				guild:
					response.success && response.result.tasks.length
						? s.tasks.guild.reduce((result, value) => {
								const task = response.result.tasks.find((v) => v._id == value._id);

								if (task) value.steps = task.steps;

								return [...result, value];
						  }, [])
						: s.tasks.guild,
			},
		}),
		[townhallFundraisersDonateAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			state: response.success ? { ...s.state, nanits: response.result.avatar.nanits } : s.state,
		}),
		[bankDepositFundsAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			state: response.success ? { ...s.state, nanits: response.result.avatar.nanits } : s.state,
		}),
		[bankWithdrawFundsAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			state: response.success ? { ...s.state, nanits: response.result.avatar.nanits } : s.state,
		}),
		[craftItemAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			state: response.success
				? { ...s.state, skills: response.result.avatar.skills, stamina: response.result.avatar.stamina }
				: s.state,
		}),
		[brewPotionAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			state: response.success
				? { ...s.state, skills: response.result.avatar.skills, stamina: response.result.avatar.stamina }
				: s.state,
		}),
		[cookFoodAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			state: response.success
				? { ...s.state, skills: response.result.avatar.skills, stamina: response.result.avatar.stamina }
				: s.state,
		}),
		[useInventoryItemAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			state: response.success
				? {
						...s.state,
						...(response.result.avatar.health
							? {
									health: response.result.avatar.health,
							  }
							: {}),
						...(response.result.avatar.stamina
							? {
									stamina: response.result.avatar.stamina,
							  }
							: {}),
						...(response.result.avatar.mana
							? {
									mana: response.result.avatar.mana,
							  }
							: {}),
				  }
				: s.state,
			effects: response.success ? response.result.avatarEffects : s.effects,
		}),
	},
	initialState
);
