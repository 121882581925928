import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('GUESTHOUSE');

export const getAvatarGuesthouse = factory.create('GET_AVATAR_GUESTHOUSE');
export const getAvatarGuesthouseAsync = factory.createAsync('GET_AVATAR_GUESTHOUSE_ASYNC');

export const makeGuesthouseRest = factory.create('MAKE_GUESTHOUSE_REST');
export const makeGuesthouseRestAsync = factory.createAsync('MAKE_GUESTHOUSE_REST_ASYNC');

export const guesthouseChestMove = factory.create('GUESTHOUSE_CHEST_MOVE');
export const guesthouseChestMoveAsync = factory.createAsync('GUESTHOUSE_CHEST_MOVE_ASYNC');

export const clearGuesthouseError = factory.create('CLEAR_GUESTHOUSE_ERROR');
export const clearGuesthouseErrorAsync = factory.createAsync('CLEAR_GUESTHOUSE_ERROR_ASYNC');
