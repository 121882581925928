import './style.sass';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../Buttons/DefaultButton';
import CloseIcon from '../../Icons/CloseIcon';

import { changeAvatarEquipment } from '../../../store/actions/avatar';
import { getAvatarEquipment } from '../../../store/actions/inventory';

import { itemStats, equipmentTypes } from '../../../common/items';

export default connect(
	(s) => ({
		equipment: s.inventoryManager.equipment,
		avatarEquipment: s.avatarManager.state.equipment,
	}),
	{ getAvatarEquipment, changeAvatarEquipment }
)(({ equipment, equipmentType, avatarEquipment, closeModal, getAvatarEquipment, changeAvatarEquipment }) => {
	const [AvatarEquipmentItem, SetAvatarEquipmentItem] = React.useState(avatarEquipment[equipmentType]);
	const [PickedItem, SetPickedItem] = React.useState();
	const inventoryWrapperRef = React.useRef(null);
	const itemInfoWrapperRef = React.useRef(null);
	const { t, i18n } = useTranslation();

	React.useEffect(() => {
		getAvatarEquipment({ types: equipmentTypes[equipmentType] });
	}, []);

	React.useEffect(() => {
		if (avatarEquipment[equipmentType]?._id != AvatarEquipmentItem?._id) closeModal();
	}, [avatarEquipment]);

	React.useEffect(() => {
		if (PickedItem && itemInfoWrapperRef.current && inventoryWrapperRef.current) {
			// up item info wrapper
			itemInfoWrapperRef.current.style.zIndex = 1;

			// down item info wrapper
			inventoryWrapperRef.current.style.zIndex = -1;
		} else if (itemInfoWrapperRef.current && inventoryWrapperRef.current) {
			// down item info wrapper
			itemInfoWrapperRef.current.style.zIndex = -1;

			// up item info wrapper
			inventoryWrapperRef.current.style.zIndex = 1;
		}
	}, [PickedItem]);

	return (
		<div className={classNames({ modal: true, 'equipment-inventory': true })}>
			<div
				className={classNames({ 'item-info-wrapper': true })}
				ref={(ref) => (itemInfoWrapperRef.current = ref)}
			>
				<div className={classNames({ 'modal-header': true })}>
					<div
						className={classNames({ 'modal-header-close': true })}
						onClick={() => SetPickedItem(null)}
					>
						<CloseIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					</div>
					<span className={classNames({ 'modal-header-title': true })}>
						{t('equipment-inventory-modal.header.title-description')}
					</span>
				</div>
				<div className={classNames({ content: true })}>
					<div className={classNames({ 'item-info': true })}>
						<img
							className={classNames({ 'item-image': true })}
							src={PickedItem?.image}
						/>
						<div className={classNames({ 'item-stats': true })}>
							<span className={classNames({ 'item-stats-text': true, strong: true, center: true })}>
								{PickedItem?.name[i18n.languages[0]]}
							</span>
							<span className={classNames({ 'item-stats-text': true })}>
								<span className={classNames({ 'item-stats-text': true, strong: true })}>
									{t(`equipment-inventory-modal.stats-names.description`)}
								</span>
								{PickedItem?.description[i18n.languages[0]]}
							</span>
							{itemStats[PickedItem?.type]?.map((v, i) => (
								<span
									className={classNames({ 'item-stats-text': true })}
									key={i}
								>
									<span className={classNames({ 'item-stats-text': true, strong: true })}>
										{t(`equipment-inventory-modal.stats-names.${v}`)}
									</span>
									{t(
										`equipment-inventory-modal.stats.${v}`,
										typeof PickedItem?.[v.split('-')[0]] == 'object'
											? { ...PickedItem?.[v.split('-')[0]] }
											: {
													value:
														v == 'rarity'
															? t(`location-inventory-modal.rarities.${PickedItem?.[v]}`)
															: v == 'weight' || v == 'capacity'
															? PickedItem?.[v] / 1000
															: PickedItem?.[v],
											  }
									)}
								</span>
							))}
							{PickedItem?.requires.length ? (
								<span className={classNames({ 'item-stats-text': true })}>
									<span className={classNames({ 'item-stats-text': true, strong: true })}>
										{t(`equipment-inventory-modal.stats-names.requirements`)}
									</span>
									{PickedItem?.requires
										.map((v) => t(`equipment-inventory-modal.requirements.${v.name}`, { value: v.value }))
										.join(', ')}
								</span>
							) : null}
						</div>
					</div>
				</div>
				<div className={classNames({ 'action-button': true })}>
					<DefaultButton
						text={t('equipment-inventory-modal.buttons.set-equipment')}
						onClick={() => changeAvatarEquipment({ slot: equipmentType, equipmentId: PickedItem?._id })}
						type={'primary'}
						fontSize={20}
						height={50}
					/>
				</div>
			</div>
			<div
				className={classNames({ 'inventory-wrapper': true })}
				ref={(ref) => (inventoryWrapperRef.current = ref)}
			>
				<div className={classNames({ 'modal-header': true })}>
					<div
						className={classNames({ 'modal-header-close': true })}
						onClick={closeModal}
					>
						<CloseIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					</div>
					<span className={classNames({ 'modal-header-title': true })}>
						{t(`equipment-inventory-modal.header.title-${equipmentType}`)}
					</span>
				</div>
				<div className={classNames({ content: true })}>
					<div className={classNames({ items: true })}>
						{avatarEquipment[equipmentType] ? (
							<div
								onClick={() => changeAvatarEquipment({ slot: equipmentType, equipmentId: avatarEquipment[equipmentType]._id })}
								className={classNames({ item: true })}
							>
								<CloseIcon
									width={24}
									height={24}
									fill={'#ffffff'}
								/>
							</div>
						) : null}
						{equipment.map((v, i) => (
							<div
								className={classNames({ item: true })}
								onClick={() => SetPickedItem(v)}
								key={i}
							>
								<img
									className={classNames({ 'item-image': true })}
									src={v.image}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
});
