import { handleActions } from 'redux-actions';

import mergeItems from '../../../utils/inventory/mergeItems';

import { changeAvatarEquipmentAsync } from '../../actions/avatar';

import {
	makeGuesthouseRestAsync,
	getAvatarGuesthouseAsync,
	guesthouseChestMoveAsync,
	clearGuesthouseErrorAsync,
} from '../../actions/guesthouse';

import { defaultError } from '../../../common/errors';

const initialState = {
	chest: [],
	restEndsAt: null,
	error: null,
};

export default handleActions(
	{
		[getAvatarGuesthouseAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			chest: response.success ? (response.result.guesthouse ? response.result.guesthouse.chest : []) : [],
			restEndsAt: response.success ? (response.result.guesthouse ? new Date(response.result.guesthouse.restEndsAt) : null) : null,
		}),
		[makeGuesthouseRestAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			restEndsAt: response.success ? new Date(response.result.guesthouse.restEndsAt) : null,
		}),
		[guesthouseChestMoveAsync.success]: (s, { payload: { data: response } }) => ({
			error: response.success ? null : response.message ? response.message : defaultError,
			chest: response.success ? mergeItems(s.chest, response.result.guesthouse.chest) : s.chest,
		}),

		[clearGuesthouseErrorAsync.success]: (s, p) => ({ ...s, error: null }),

		[getAvatarGuesthouseAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			chest: [],
			restEndsAt: null,
		}),
		[makeGuesthouseRestAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
		[guesthouseChestMoveAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),

		[changeAvatarEquipmentAsync.success]: (s, { payload: { data: response } }) => ({
			chest: response.success ? mergeItems(s.chest, response.result.guesthouse.chest) : s.chest,
			restEndsAt: response.success ? new Date(response.result.guesthouse.restEndsAt) : s.restEndsAt,
		}),
	},
	initialState
);
