import axios from 'axios';

import { api_url } from '../../../common/config';

axios.defaults.withCredentials = true;

export default class {
	static getPotionsEndpoint = (params) => axios.get(`${api_url}potions`, { params });
	static brewPotionEndpoint = (data) => axios.post(`${api_url}potions/brew`, data);
}
