import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import {
	getLocations,
	getLocationsAsync,
	getAvatarLocation,
	getAvatarLocationAsync,
	takeJourney,
	takeJourneyAsync,
	updateLocationTarget,
	updateLocationTargetAsync,
	clearLocation,
	clearLocationAsync,
	clearLocationsError,
	clearLocationsErrorAsync,
} from '../../actions/locations';

import locationsApi from '../../../services/api/locations';

function clearErrorWorker() {
	return {};
}

function updateLocationTargetWorker(response) {
	return { response };
}

function clearLocationWorker() {
	return {};
}

export function* locationsSaga() {
	yield takeEvery(getLocations, bindAsyncActions(getLocationsAsync)(locationsApi.getLocationsEndpoint));
	yield takeEvery(getAvatarLocation, bindAsyncActions(getAvatarLocationAsync)(locationsApi.getAvatarLocationEndpoint));
	yield takeEvery(takeJourney, bindAsyncActions(takeJourneyAsync)(locationsApi.takeJourneyEndpoint));
	yield takeEvery(updateLocationTarget, bindAsyncActions(updateLocationTargetAsync)(updateLocationTargetWorker));
	yield takeEvery(clearLocation, bindAsyncActions(clearLocationAsync)(clearLocationWorker));
	yield takeEvery(clearLocationsError, bindAsyncActions(clearLocationsErrorAsync)(clearErrorWorker));
}
