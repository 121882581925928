export const rgbToHex = ({ r, g, b }) => {
	if (
		typeof r !== 'number' ||
		r < 0 ||
		r > 255 ||
		typeof g !== 'number' ||
		g < 0 ||
		g > 255 ||
		typeof b !== 'number' ||
		b < 0 ||
		b > 255
	) {
		throw new Error('Invalid RGB value. Each value must be a number between 0 and 255.');
	}

	const toHex = (color) => color.toString(16).padStart(2, '0');

	const hex = `${toHex(r)}${toHex(g)}${toHex(b)}`;

	return hex.toUpperCase();
};

export const hexToRgb = (color) => {
	let r = 0;
	let g = 0;
	let b = 0;

	// 3 digits
	if (color.length === 4) {
		r = Number('0x' + color[1] + color[1]);
		g = Number('0x' + color[2] + color[2]);
		b = Number('0x' + color[3] + color[3]);

		// 6 digits
	} else if (color.length === 7) {
		r = Number('0x' + color[1] + color[2]);
		g = Number('0x' + color[3] + color[4]);
		b = Number('0x' + color[5] + color[6]);
	}

	return { r, g, b };
};

export const rgbToHsv = ({ r, g, b }) => {
	r /= 255;
	g /= 255;
	b /= 255;

	const max = Math.max(r, g, b);
	const d = max - Math.min(r, g, b);

	const h = d ? (max === r ? (g - b) / d + (g < b ? 6 : 0) : max === g ? 2 + (b - r) / d : 4 + (r - g) / d) * 60 : 0;
	const s = max ? (d / max) * 100 : 0;
	const v = max * 100;

	return { h, s, v };
};

export const hsvToRgb = ({ h, s, v }) => {
	s /= 100;
	v /= 100;

	const i = ~~(h / 60);
	const f = h / 60 - i;
	const p = v * (1 - s);
	const q = v * (1 - s * f);
	const t = v * (1 - s * (1 - f));
	const index = i % 6;

	const r = Math.round([v, q, p, p, t, v][index] * 255);
	const g = Math.round([t, v, v, q, p, p][index] * 255);
	const b = Math.round([p, p, t, v, v, q][index] * 255);

	return { r, g, b };
};
