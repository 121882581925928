import { handleActions } from 'redux-actions';

import mergeItems from '../../../utils/inventory/mergeItems';

import { guesthouseChestMoveAsync } from '../../actions/guesthouse';
import { changeAvatarEquipmentAsync } from '../../actions/avatar';
import { brewPotionAsync } from '../../actions/potions';
import { craftItemAsync } from '../../actions/crafts';
import { cookFoodAsync } from '../../actions/foods';
import {
	useInventoryItemAsync,
	getAvatarInventoryAsync,
	getAvatarEquipmentAsync,
	clearInventoryErrorAsync,
	updateAvatarInventoryAsync,
} from '../../actions/inventory';

import { defaultError } from '../../../common/errors';

const initialState = {
	avatar: null,
	equipment: [],
	error: null,
};

export default handleActions(
	{
		[getAvatarInventoryAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			avatar: responseData.success ? responseData.result.inventory : null,
		}),
		[getAvatarEquipmentAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			equipment: responseData.success ? responseData.result.items : [],
		}),
		[useInventoryItemAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			avatar: responseData.success
				? {
						...s.avatar,
						capacity: responseData.result.inventory.capacity,
						items: s.avatar.items.filter((v) => v._id != responseData.result.itemId),
				  }
				: null,
		}),
		[updateAvatarInventoryAsync.success]: (s, { payload: { response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			avatar: response.success
				? {
						...s.avatar,
						...response.result.inventory,
						...(response.result.inventory && response.result.inventory.items
							? { items: mergeItems(s.avatar.items, response.result.inventory.items) }
							: {}),
				  }
				: s.avatar,
		}),
		[clearInventoryErrorAsync.success]: (s, p) => ({ ...s, error: null }),

		[getAvatarInventoryAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			avatar: null,
		}),
		[getAvatarEquipmentAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			equipment: [],
		}),
		//
		//
		//
		[guesthouseChestMoveAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			avatar: response.success
				? {
						...s.avatar,
						...response.result.inventory,
						...(response.result.inventory && response.result.inventory.items
							? { items: mergeItems(s.avatar.items, response.result.inventory.items) }
							: {}),
				  }
				: s.avatar,
		}),
		[changeAvatarEquipmentAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			avatar: response.success
				? {
						...s.avatar,
						...response.result.inventory,
						...(response.result.inventory && response.result.inventory.items
							? { items: mergeItems(s.avatar.items, response.result.inventory.items) }
							: {}),
				  }
				: s.avatar,
		}),
		[craftItemAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			avatar: response.success
				? {
						...s.avatar,
						...response.result.inventory,
						...(response.result.inventory && response.result.inventory.items
							? { items: mergeItems(s.avatar.items, response.result.inventory.items) }
							: {}),
				  }
				: s.avatar,
		}),
		[brewPotionAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			avatar: response.success
				? {
						...s.avatar,
						...response.result.inventory,
						...(response.result.inventory && response.result.inventory.items
							? { items: mergeItems(s.avatar.items, response.result.inventory.items) }
							: {}),
				  }
				: s.avatar,
		}),
		[cookFoodAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			avatar: response.success
				? {
						...s.avatar,
						...response.result.inventory,
						...(response.result.inventory && response.result.inventory.items
							? { items: mergeItems(s.avatar.items, response.result.inventory.items) }
							: {}),
				  }
				: s.avatar,
		}),
	},
	initialState
);
