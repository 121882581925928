import colorConvert from 'color-convert';

import { defaultWingsColor } from '../../../common/avatar';

export default (rgbColorForReplace) => [
	{
		target: colorConvert.hex.rgb(defaultWingsColor[0]),
		replace: rgbColorForReplace,
	},
	{
		target: colorConvert.hex.rgb(defaultWingsColor[1]),
		replace: [rgbColorForReplace[0] + 15, rgbColorForReplace[1] + 33, rgbColorForReplace[2] + 33],
	},
];
