import axios from 'axios';

import { api_url } from '../../../common/config';

axios.defaults.withCredentials = true;

export default class {
	static getTownhallNewsEndpoint = () => axios.get(`${api_url}townhall/news`);
	static getTownhallFundraisersEndpoint = () => axios.get(`${api_url}townhall/fundraisers`);
	static townhallFundraisersDonateEndpoint = (data) => axios.post(`${api_url}townhall/fundraisers/donate`, data);
}
