import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('USER');

export const loginTelegram = factory.create('LOGIN_TELEGRAM');
export const loginTelegramAsync = factory.createAsync('LOGIN_TELEGRAM_ASYNC');

export const getUserInfo = factory.create('GET_USER_INFO');
export const getUserInfoAsync = factory.createAsync('GET_USER_INFO_ASYNC');

export const clearUserError = factory.create('CLEAR_USER_ERROR');
export const clearUserErrorAsync = factory.createAsync('CLEAR_USER_ERROR_ASYNC');
