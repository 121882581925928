import './style.sass';

import classNames from 'classnames';
import React from 'react';

export default ({ name, level = 1, current, maximum, fontSize = 18, frontColor = '#ffffff', backColor = '#000000' }) => {
	const [Percentage, SetPercentage] = React.useState(100);

	React.useEffect(() => {
		SetPercentage(Math.min(current, maximum) / (maximum / 100));
	}, [current, maximum]);

	return (
		<div className={classNames({ 'level-progress-bar-wrapper': true })}>
			<span
				className={classNames({ 'progress-bar-name': true })}
				style={{ fontSize }}
			>
				{name}{' '}
				<span
					className={classNames({ 'progress-bar-level': true })}
					style={{ fontSize }}
				>
					{level}
				</span>{' '}
				| {current}/{maximum}
			</span>
			<div className={classNames({ 'bar-wrapper': true })}>
				<div
					className={classNames({ 'bar-front': true })}
					style={{ background: frontColor, width: `${Percentage}%` }}
				/>
				<div
					className={classNames({ 'bar-back': true })}
					style={{ background: backColor }}
				/>
			</div>
		</div>
	);
};
