import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import {
	getAvatarInventory,
	getAvatarInventoryAsync,
	getAvatarEquipment,
	getAvatarEquipmentAsync,
	useInventoryItem,
	useInventoryItemAsync,
	updateAvatarInventory,
	updateAvatarInventoryAsync,
	clearInventoryError,
	clearInventoryErrorAsync,
} from '../../actions/inventory';

import inventoryApi from '../../../services/api/inventory';

function clearErrorWorker() {
	return {};
}

function updateAvatarInventoryWorker(response) {
	return { response };
}

export function* inventorySaga() {
	yield takeEvery(getAvatarInventory, bindAsyncActions(getAvatarInventoryAsync)(inventoryApi.getAvatarInventoryEndpoint));
	yield takeEvery(getAvatarEquipment, bindAsyncActions(getAvatarEquipmentAsync)(inventoryApi.getAvatarEquipmentEndpoint));
	yield takeEvery(useInventoryItem, bindAsyncActions(useInventoryItemAsync)(inventoryApi.useInventoryItemEndpoint));
	yield takeEvery(updateAvatarInventory, bindAsyncActions(updateAvatarInventoryAsync)(updateAvatarInventoryWorker));
	yield takeEvery(clearInventoryError, bindAsyncActions(clearInventoryErrorAsync)(clearErrorWorker));
}
