import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import RankProgressBar from '../../components/ProgressBars/RankProgressBar';
import MainHeader from '../../components/MainHeader';
import MainNavbar from '../../components/MainNavbar';

import { openModal, closeModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		news: s.townhallManager.news,
		fundraisers: s.townhallManager.fundraisers,
	}),
	{
		openModal,
		closeModal,
	}
)(({ news, fundraisers, openModal }) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={classNames({ container: true, townhall: true })}>
			<MainHeader title={t('townhall.header.title')} />
			<div className={classNames({ content: true })}>
				{fundraisers.length ? (
					<div className={classNames({ fundraisers: true })}>
						{fundraisers.map((v, i) => (
							<div
								onClick={() => navigate('/fundraising', { state: { target: v } })}
								className={classNames({ 'fundraiser-wrapper': true })}
								key={i}
							>
								<img
									className={classNames({ 'fundraiser-image': true })}
									src={v.image}
								/>
								<RankProgressBar
									frontColor={v.progress.current >= v.progress.maximum ? '009933' : '#FF9900'}
									backColor={v.progress.current >= v.progress.maximum ? '00551C' : '#965A00'}
									current={Math.min(v.progress.current, v.progress.maximum)}
									name={v.title[i18n.languages[0]]}
									maximum={v.progress.maximum}
								/>
							</div>
						))}
					</div>
				) : null}
				<div className={classNames({ news: true })}>
					<span className={classNames({ title: true })}>{t('townhall.news')}</span>
					{news.length ? (
						news.map((v, i) => (
							<div
								className={classNames({ 'news-wrapper': true })}
								key={i}
							>
								<img
									className={classNames({ 'news-image': true })}
									src={v.image}
								/>
								<div className={classNames({ 'news-info': true })}>
									<span className={classNames({ 'news-info-title': true })}>{v.title[i18n.languages[0]]}</span>
									<span className={classNames({ 'news-info-description': true })}>{v.description[i18n.languages[0]]}</span>
								</div>
							</div>
						))
					) : (
						<span className={classNames({ 'news-empty': true })}>{t('townhall.empty')}</span>
					)}
				</div>
			</div>
			<MainNavbar openModal={openModal} />
		</div>
	);
});
