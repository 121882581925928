import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import {
	createAvatar,
	createAvatarAsync,
	getAvatarInfo,
	getAvatarInfoAsync,
	getAvatarGuildTasks,
	getAvatarGuildTasksAsync,
	getAvatarSocialTasks,
	getAvatarSocialTasksAsync,
	getAvatarEffects,
	getAvatarEffectsAsync,
	useTaskStep,
	useTaskStepAsync,
	checkTaskStep,
	checkTaskStepAsync,
	completeTask,
	completeTaskAsync,
	updateAvatarState,
	updateAvatarStateAsync,
	updateAvatarGuildTasks,
	updateAvatarGuildTasksAsync,
	changeAvatarEquipment,
	changeAvatarEquipmentAsync,
	upgradeAvatarLevel,
	upgradeAvatarLevelAsync,
	upgradeAvatarSkill,
	upgradeAvatarSkillAsync,
	clearAvatarError,
	clearAvatarErrorAsync,
} from '../../actions/avatar';

import avatarApi from '../../../services/api/avatar';

function clearErrorWorker() {
	return {};
}

function updateAvatarStateWorker(response) {
	return { response };
}

function updateAvatarGuildTasksWorker(response) {
	return { response };
}

export function* avatarSaga() {
	yield takeEvery(createAvatar, bindAsyncActions(createAvatarAsync)(avatarApi.createAvatarEndpoint));
	yield takeEvery(getAvatarInfo, bindAsyncActions(getAvatarInfoAsync)(avatarApi.getAvatarInfoEndpoint));
	yield takeEvery(getAvatarGuildTasks, bindAsyncActions(getAvatarGuildTasksAsync)(avatarApi.getAvatarGuildTasksEndpoint));
	yield takeEvery(getAvatarSocialTasks, bindAsyncActions(getAvatarSocialTasksAsync)(avatarApi.getAvatarSocialTasksEndpoint));
	yield takeEvery(getAvatarEffects, bindAsyncActions(getAvatarEffectsAsync)(avatarApi.getAvatarEffectsEndpoint));
	yield takeEvery(useTaskStep, bindAsyncActions(useTaskStepAsync)(avatarApi.useTaskStepEndpoint));
	yield takeEvery(checkTaskStep, bindAsyncActions(checkTaskStepAsync)(avatarApi.checkTaskStepEndpoint));
	yield takeEvery(completeTask, bindAsyncActions(completeTaskAsync)(avatarApi.completeTaskEndpoint));
	yield takeEvery(changeAvatarEquipment, bindAsyncActions(changeAvatarEquipmentAsync)(avatarApi.changeAvatarEquipmentEndpoint));
	yield takeEvery(upgradeAvatarLevel, bindAsyncActions(upgradeAvatarLevelAsync)(avatarApi.upgradeAvatarLevelEndpoint));
	yield takeEvery(upgradeAvatarSkill, bindAsyncActions(upgradeAvatarSkillAsync)(avatarApi.upgradeAvatarSkillEndpoint));
	yield takeEvery(updateAvatarState, bindAsyncActions(updateAvatarStateAsync)(updateAvatarStateWorker));
	yield takeEvery(updateAvatarGuildTasks, bindAsyncActions(updateAvatarGuildTasksAsync)(updateAvatarGuildTasksWorker));
	yield takeEvery(clearAvatarError, bindAsyncActions(clearAvatarErrorAsync)(clearErrorWorker));
}
