import colorConvert from 'color-convert';

import { defaultWearableLeggsColor } from '../../../common/avatar';

export default (rgbColorForReplace) => [
	{
		target: colorConvert.hex.rgb(defaultWearableLeggsColor[0]),
		replace: rgbColorForReplace,
	},
	{
		target: colorConvert.hex.rgb(defaultWearableLeggsColor[1]),
		replace: [rgbColorForReplace[0] - 8, rgbColorForReplace[1] - 8, rgbColorForReplace[2] - 4],
	},
];
