import './style.sass';

import classNames from 'classnames';
import React from 'react';

import CloseIcon from '../../Icons/CloseIcon';

export default ({ title, types, type, setType, closeOnPick = false, closeOnBackgroundClick = false, closeModal }) => {
	const [Type, SetType] = React.useState(type);

	const SetTypeWrapper = (type) => {
		SetType(type);

		setType(type);

		if (closeOnPick) closeModal();
	};

	return (
		<div
			onClick={(e) => (e.target.classList.contains('type-picker-wrapper') && closeOnBackgroundClick ? closeModal() : null)}
			className={classNames({ modal: true, 'type-picker-wrapper': true })}
		>
			<div className={classNames({ modal: true, 'type-picker': true })}>
				<div className={classNames({ header: true })}>
					<span className={classNames({ 'header-title': true })}>{title}</span>
					<div
						className={classNames({ 'header-close': true })}
						onClick={closeModal}
					>
						<CloseIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					</div>
				</div>
				<div className={classNames({ content: true })}>
					<div className={classNames({ types: true })}>
						{types.map((type, index) => (
							<div
								key={index}
								className={classNames({ 'type-wrapper': true, picked: Type === type.value })}
								onClick={() => SetTypeWrapper(type.value)}
							>
								<span className={classNames({ type: true })}>{type.name}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
