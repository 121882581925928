import { handleActions } from 'redux-actions';

import { getFoodsAsync, cookFoodAsync, changeFoodsFieldAsync, clearFoodsErrorAsync } from '../../actions/foods';

import { defaultError } from '../../../common/errors';

const initialState = {
	search: '',
	foods: [],
	error: null,
};

export default handleActions(
	{
		[getFoodsAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			foods: response.success ? response.result.recipes : null,
		}),
		[cookFoodAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
		}),
		[changeFoodsFieldAsync.success]: (s, { payload: { name, value } }) => ({ ...s, [name]: value }),
		[clearFoodsErrorAsync.success]: (s, p) => ({ ...s, error: null }),

		[getFoodsAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			foods: [],
		}),
		[cookFoodAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			foods: [],
		}),
	},
	initialState
);
