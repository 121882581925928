export default (oldItems, newItems) => {
	// Step 1: Create a Set of _ids from the second array for quick lookup
	const newItemsIds = new Set(newItems.map((item) => item._id));

	// Step 2: Filter oldItems to keep only those items that are in newItems
	const filteredoldItems = oldItems.filter((item) => newItemsIds.has(item._id));

	// Step 3: Create a Map from the first array for efficient merging
	const oldItemsMap = new Map(oldItems.map((item) => [item._id, item]));

	// Step 4: Iterate through newItems and add items not present in oldItems
	newItems.forEach((item) => {
		if (!oldItemsMap.has(item._id)) filteredoldItems.push(item);
	});

	return filteredoldItems;
};
