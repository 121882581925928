import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import RightArrowIcon from '../../components/Icons/RightArrowIcon';
import DefaultInput from '../../components/Inputs/DefaultInput';
import SearchIcon from '../../components/Icons/SearchIcon';
import ClearIcon from '../../components/Icons/ClearIcon';
import MainHeader from '../../components/MainHeader';
import MainNavbar from '../../components/MainNavbar';

import { getCrafts, changeCraftsField } from '../../store/actions/crafts';
import { openModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		crafts: s.craftsManager.crafts,
		validated: s.userManager.validated,
		craftsSearch: s.craftsManager.search,
	}),
	{
		openModal,
		getCrafts,
		changeCraftsField,
	}
)(({ crafts, validated, craftsSearch, openModal, getCrafts, changeCraftsField }) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (validated) {
			getCrafts({ search: craftsSearch });
		}
	}, [validated, craftsSearch]);

	return (
		<div className={classNames({ container: true, crafting: true })}>
			<MainHeader title={t('crafting.header.title')} />
			<div className={classNames({ content: true })}>
				<DefaultInput
					placeholder={t('crafting.search-placeholder')}
					onChange={(e) => changeCraftsField({ name: 'search', value: e.target.value })}
					rightIconClick={() => changeCraftsField({ name: 'search', value: '' })}
					value={craftsSearch}
					height={25}
					leftIcon={
						<SearchIcon
							width={24}
							height={24}
							fill={'#ffffff'}
						/>
					}
					rightIcon={
						<ClearIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					}
				/>
				<div className={classNames({ locations: true })}>
					{crafts.map((v, i) => (
						<div
							onClick={() => navigate('/craft', { state: { craft: v } })}
							className={classNames({ 'location-wrapper': true })}
							key={i}
						>
							<img
								className={classNames({ 'location-image': true })}
								alt={v.name[i18n.languages[0]]}
								src={v.image}
							/>
							<span className={classNames({ 'location-name': true })}>{v.name[i18n.languages[0]]}</span>
							<RightArrowIcon
								stroke={'#ffffff'}
								height={17}
								width={19}
							/>
						</div>
					))}
				</div>
			</div>
			<MainNavbar openModal={openModal} />
		</div>
	);
});
