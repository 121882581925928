import { handleActions } from 'redux-actions';

import {
	getTownhallNewsAsync,
	getTownhallFundraisersAsync,
	townhallFundraisersDonateAsync,
	clearTownhallErrorAsync,
} from '../../actions/townhall';

import { defaultError } from '../../../common/errors';

const initialState = {
	news: [],
	fundraisers: [],
	error: null,
};

export default handleActions(
	{
		[getTownhallNewsAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			news: response.success ? response.result.news : [],
		}),
		[getTownhallFundraisersAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			fundraisers: response.success ? response.result.fundraisers : [],
		}),
		[townhallFundraisersDonateAsync.success]: (s, { payload: { data: response } }) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			fundraisers: response.success
				? s.fundraisers.map((v) => (v._id == response.result.fundraiser._id ? response.result.fundraiser : v))
				: s.fundraisers,
		}),
		[clearTownhallErrorAsync.success]: (s, p) => ({ ...s, error: null }),

		[getTownhallNewsAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			news: [],
		}),
		[getTownhallFundraisersAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			fundraisers: [],
		}),
		[townhallFundraisersDonateAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
	},
	initialState
);
