import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../components/Buttons/DefaultButton';
import AvatarRenderer from '../../components/AvatarRenderer';
import getContrastColor from '../../utils/colors/getContrastColor';

import { changeWearableLeggs, changeWearableBoots } from '../../store/actions/createAvatar';
import { openModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		appearance: s.createAvatarManager.appearance,
		wearable: s.createAvatarManager.wearable,
		gender: s.createAvatarManager.gender,
		race: s.createAvatarManager.race,
	}),
	{
		openModal,
		changeWearableLeggs,
		changeWearableBoots,
	}
)(({ appearance, wearable, gender, race, openModal, changeWearableLeggs, changeWearableBoots }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={classNames({ container: true, 'create-avatar-wearable-leggs-boots': true })}>
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'avatar-wrapper': true })}>
					<AvatarRenderer
						appearance={appearance}
						withUnderwear={true}
						withWearable={true}
						wearable={wearable}
						gender={gender}
						race={race}
					/>
				</div>
				<div className={classNames({ 'settings-wrapper': true })}>
					<div className={classNames({ 'pick-part': true })}>
						<span className={classNames({ title: true })}>{t('create-avatar-wearable-leggs-boots.pick-leggs')}</span>
						<div className={classNames({ type: true })}>
							<DefaultButton
								fontSize={12}
								type={'primary'}
								text={t(`create-avatar-wearable-leggs-boots.leggs.${wearable.leggs.type}`)}
								onClick={() =>
									openModal({
										name: 'type-picker',
										payload: {
											title: t('create-avatar-wearable-leggs-boots.modals.leggs'),
											types: [0, 1].map((type) => ({
												name: t(`create-avatar-wearable-leggs-boots.leggs.${type}`),
												value: type,
											})),
											type: wearable.leggs.type,
											setType: (type) => changeWearableLeggs({ name: 'type', value: type }),
											withoutBackground: true,
											withoutPadding: true,
										},
									})
								}
							/>
						</div>
					</div>
					<div className={classNames({ 'pick-part': true })}>
						<span className={classNames({ title: true })}>{t('create-avatar-wearable-leggs-boots.pick-leggs-color')}</span>
						<div className={classNames({ type: true })}>
							<DefaultButton
								fontSize={12}
								background={wearable.leggs.color}
								color={getContrastColor(wearable.leggs.color)}
								text={wearable.leggs.color}
								onClick={() =>
									openModal({
										name: 'color-picker',
										payload: {
											Color: wearable.leggs.color,
											SetColor: (color) => changeWearableLeggs({ name: 'color', value: color }),
											withoutBackground: true,
											withoutPadding: true,
										},
									})
								}
							/>
						</div>
					</div>
					<div className={classNames({ 'pick-part': true })}>
						<span className={classNames({ title: true })}>{t('create-avatar-wearable-leggs-boots.pick-boots')}</span>
						<div className={classNames({ type: true })}>
							<DefaultButton
								fontSize={12}
								type={'primary'}
								text={t(`create-avatar-wearable-leggs-boots.boots.${wearable.boots.type}`)}
								onClick={() =>
									openModal({
										name: 'type-picker',
										payload: {
											title: t('create-avatar-wearable-leggs-boots.modals.boots'),
											types: [0, 1].map((type) => ({
												name: t(`create-avatar-wearable-leggs-boots.boots.${type}`),
												value: type,
											})),
											type: wearable.boots.type,
											setType: (type) => changeWearableBoots({ name: 'type', value: type }),
											withoutBackground: true,
											withoutPadding: true,
										},
									})
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={classNames({ 'bottom-buttons': true })}>
				<DefaultButton
					type={'secondary'}
					text={t('create-avatar-wearable-leggs-boots.buttons.back')}
					onClick={() => navigate('/create-avatar-wearable-body')}
				/>
				<DefaultButton
					text={t('create-avatar-wearable-leggs-boots.buttons.next')}
					onClick={() => navigate('/create-avatar-confirm')}
				/>
			</div>
		</div>
	);
});
