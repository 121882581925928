import { handleActions } from 'redux-actions';

import { getBankDepositsAsync, bankDepositFundsAsync, bankWithdrawFundsAsync, clearBankErrorAsync } from '../../actions/bank';

import { defaultError } from '../../../common/errors';

const initialState = {
	bank: [],
	avatar: [],
	error: null,
};

export default handleActions(
	{
		[getBankDepositsAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			bank: response.success ? response.result.bank : [],
			avatar: response.success ? response.result.avatar : [],
			error: response.success ? null : response.message ? response.message : defaultError,
		}),
		[bankDepositFundsAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			avatar: response.success
				? response.result.deposit.endsAt == null
					? s.avatar.map((v) => (v._id == response.result.deposit._id ? response.result.deposit : v))
					: [...s.avatar, response.result.deposit]
				: s.avatar,
			error: response.success ? null : response.message ? response.message : defaultError,
		}),
		[bankWithdrawFundsAsync.success]: (s, { payload: { data: response } }) => ({
			...s,
			avatar: response.success
				? response.result.deposit.endsAt == null
					? s.avatar.map((v) => (v._id == response.result.deposit._id ? response.result.deposit : v))
					: s.avatar.filter((v) => v._id != response.result.deposit._id)
				: s.avatar,
			error: response.success ? null : response.message ? response.message : defaultError,
		}),
		[clearBankErrorAsync.success]: (s, p) => ({ ...s, error: null }),

		[getBankDepositsAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			bank: [],
			avatar: [],
		}),
		[bankDepositFundsAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
		[bankWithdrawFundsAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
	},
	initialState
);
