import './style.sass';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../Buttons/DefaultButton';
import DefaultInput from '../../Inputs/DefaultInput';
import CloseIcon from '../../Icons/CloseIcon';

export default ({ onConfirm, closeModal }) => {
	const [Amount, SetAmount] = React.useState(1);
	const { t } = useTranslation();

	return (
		<div
			onClick={(e) => (e.target.classList.contains('donate-amount-wrapper') ? closeModal() : null)}
			className={classNames({ modal: true, 'donate-amount-wrapper': true })}
		>
			<div className={classNames({ modal: true, 'donate-amount': true })}>
				<div className={classNames({ header: true })}>
					<span className={classNames({ 'header-title': true })}>{t('donate-amount-modal.header.title')}</span>
					<div
						className={classNames({ 'header-close': true })}
						onClick={closeModal}
					>
						<CloseIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					</div>
				</div>
				<div className={classNames({ content: true })}>
					<div className={classNames({ 'amount-input-wrapper': true })}>
						<span className={classNames({ 'amount-input-label': true })}>{t('donate-amount-modal.amount')}</span>
						<DefaultInput
							onChange={(e) => SetAmount(e.target.value ? parseInt(e.target.value) || 1 : '')}
							placeholder={'1000'}
							value={Amount}
							height={21}
						/>
					</div>
					<DefaultButton
						text={t('donate-amount-modal.buttons.confirm')}
						onClick={() => onConfirm(Amount)}
						type={'primary'}
						fontSize={20}
						height={50}
					/>
				</div>
			</div>
		</div>
	);
};
