import React from 'react';

export default ({ width = 512, height = 512, fill = '#000000' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			preserveAspectRatio='xMidYMid meet'
		>
			<g
				transform='translate(0, 512) scale(0.100000,-0.100000)'
				fill={fill}
				stroke='none'
			>
				<path
					d='M427 4899 c-54 -13 -87 -32 -131 -76 -54 -53 -78 -112 -78 -189 1
-149 116 -274 253 -274 l54 0 258 -258 257 -257 -111 -110 c-137 -135 -169
-182 -169 -245 0 -159 216 -221 299 -87 46 75 34 158 -32 213 l-30 25 34 35
33 34 114 -112 c92 -92 112 -117 107 -135 -36 -146 -81 -538 -96 -827 -18
-365 8 -626 87 -875 l35 -112 -103 -107 c-211 -219 -474 -531 -675 -802 -170
-228 -315 -449 -316 -478 0 -26 25 -52 50 -52 20 0 303 193 502 343 207 155
478 379 654 541 71 65 129 116 131 114 1 -1 41 -51 88 -109 153 -188 390 -411
602 -569 108 -80 304 -210 317 -210 12 0 102 58 224 143 256 180 521 423 699
640 l88 107 80 -72 c243 -222 474 -415 716 -597 157 -118 471 -331 489 -331
18 0 53 36 53 55 0 18 -146 236 -284 425 -195 267 -469 597 -685 824 -147 155
-135 131 -107 212 88 252 117 525 97 913 -15 302 -62 703 -96 826 -5 16 20 45
109 135 l116 114 35 -34 35 -33 -39 -36 c-50 -47 -68 -103 -51 -157 28 -85 77
-124 157 -124 116 0 194 117 152 227 -5 15 -68 86 -139 158 l-130 131 258 257
257 257 51 0 c65 0 114 20 169 71 181 167 68 473 -175 473 -154 0 -279 -114
-280 -254 l0 -55 -258 -258 -257 -257 -120 121 c-130 131 -172 159 -235 159
-160 0 -222 -215 -87 -299 75 -45 155 -33 212 34 l30 36 35 -36 34 -36 -215
-215 -215 -215 -144 55 c-195 72 -292 115 -438 192 -67 35 -130 64 -139 64 -9
0 -103 -42 -207 -94 -105 -52 -265 -122 -355 -156 l-164 -62 -216 216 -215
215 34 36 33 35 36 -39 c68 -74 165 -76 236 -6 58 59 65 127 19 205 -36 61
-140 88 -207 54 -18 -9 -88 -72 -157 -140 l-126 -124 -254 255 -254 255 -2 52
c-2 70 -26 123 -76 175 -67 69 -177 103 -261 82z m126 -113 c44 -18 95 -78
103 -122 22 -117 -97 -228 -208 -195 -122 37 -166 176 -85 268 53 60 122 78
190 49z m4140 0 c18 -7 47 -29 64 -49 59 -67 56 -156 -6 -218 -72 -72 -168
-74 -237 -5 -64 64 -69 157 -11 223 53 60 122 78 190 49z m-3535 -823 l-43
-43 -237 237 -238 238 42 42 43 43 237 -237 238 -238 -42 -42z m3085 195
l-238 -238 -42 42 -43 43 237 237 238 238 42 -42 43 -43 -237 -237z m-2578 77
c14 -13 25 -31 25 -40 0 -21 -36 -55 -60 -55 -40 0 -73 55 -52 86 8 12 43 33
55 34 4 0 19 -11 32 -25z m1859 4 c14 -11 26 -29 26 -40 0 -25 -35 -59 -60
-59 -24 0 -60 34 -60 55 0 17 43 65 58 65 5 0 21 -9 36 -21z m-1999 -449 c104
-104 186 -192 182 -196 -9 -9 -255 -73 -282 -74 -13 0 -69 49 -155 135 l-135
135 95 95 c52 52 97 95 100 95 3 0 90 -85 195 -190z m2365 95 l95 -95 -135
-135 c-86 -86 -142 -135 -155 -135 -27 1 -273 65 -282 74 -6 6 365 386 377
386 3 0 48 -43 100 -95z m-1218 -115 c288 -151 666 -283 1045 -365 25 -6 26
-8 47 -150 82 -537 87 -1001 16 -1310 -107 -468 -380 -866 -852 -1242 -131
-105 -353 -263 -368 -263 -17 0 -197 129 -375 269 -133 105 -382 348 -467 456
-216 276 -338 546 -401 890 -20 107 -22 153 -22 430 0 239 5 345 19 460 24
199 61 444 68 451 3 3 63 18 132 34 361 83 784 245 1043 399 1 1 52 -26 115
-59z m-1712 -240 c32 -32 25 -66 -17 -89 -17 -8 -29 -9 -43 -1 -25 13 -43 47
-34 63 29 50 61 60 94 27z m3283 -12 l21 -28 -21 -27 c-11 -16 -29 -29 -41
-31 -25 -4 -62 30 -62 58 0 28 37 62 62 58 12 -2 30 -15 41 -30z m-2870 -2010
c8 -18 37 -72 65 -121 l50 -87 -145 -132 c-245 -224 -565 -482 -808 -654 l-70
-49 49 70 c148 209 333 443 521 660 117 134 313 345 321 345 2 0 10 -15 17
-32z m2720 -323 c180 -207 368 -446 513 -650 l49 -70 -70 49 c-246 174 -561
428 -810 655 l-143 131 50 87 c28 49 57 104 66 123 l14 35 101 -105 c55 -58
159 -172 230 -255z'
				/>
				<path
					d='M2455 3588 c-199 -101 -515 -219 -765 -284 -74 -20 -143 -43 -152
-52 -14 -14 -23 -72 -47 -312 -49 -492 -49 -701 -1 -941 64 -314 222 -594 499
-882 141 -148 532 -467 571 -467 10 0 56 28 102 62 590 436 890 851 979 1354
38 212 27 634 -26 1047 -9 64 -18 124 -21 132 -6 16 -31 25 -154 55 -213 53
-507 161 -729 266 -74 35 -143 64 -155 64 -12 -1 -57 -19 -101 -42z m55 -1439
l0 -1341 -37 28 c-447 335 -716 665 -834 1026 -69 213 -91 452 -70 783 11 169
47 525 53 530 2 1 37 10 78 20 175 42 556 178 725 259 39 19 73 35 78 35 4 1
7 -603 7 -1340z m285 1263 c185 -80 396 -155 575 -202 69 -19 126 -35 128 -35
6 -4 42 -360 53 -530 35 -567 -55 -910 -340 -1280 -107 -139 -386 -403 -555
-523 l-46 -33 0 1341 c0 1070 3 1341 13 1337 6 -4 84 -37 172 -75z'
				/>
			</g>
		</svg>
	);
};
