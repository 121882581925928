import { all, take } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist';

import { createAvatarSaga } from './createAvatar';
import { guesthouseSaga } from './guesthouse';
import { locationsSaga } from './locations';
import { inventorySaga } from './inventory';
import { settingsSaga } from './settings';
import { townhallSaga } from './townhall';
import { potionsSaga } from './potions';
import { craftsSaga } from './crafts';
import { modalsSaga } from './modals';
import { avatarSaga } from './avatar';
import { guildSaga } from './guild';
import { foodsSaga } from './foods';
import { bankSaga } from './bank';
import { userSaga } from './user';

export default function* () {
	yield take(REHYDRATE);
	yield all([
		createAvatarSaga(),
		guesthouseSaga(),
		locationsSaga(),
		inventorySaga(),
		settingsSaga(),
		townhallSaga(),
		potionsSaga(),
		modalsSaga(),
		craftsSaga(),
		avatarSaga(),
		foodsSaga(),
		guildSaga(),
		bankSaga(),
		userSaga(),
	]);
}
