import axios from 'axios';

import { api_url } from '../../../common/config';

axios.defaults.withCredentials = true;

export default class {
	static getCraftsEndpoint = (params) => axios.get(`${api_url}crafts`, { params });
	static craftItemEndpoint = (data) => axios.post(`${api_url}crafts/craft`, data);
}
