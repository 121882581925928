import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import RightArrowIcon from '../../components/Icons/RightArrowIcon';
import MainHeader from '../../components/MainHeader';
import MainNavbar from '../../components/MainNavbar';

import { openModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		socialTasks: s.avatarManager.tasks.social,
		guildTasks: s.avatarManager.tasks.guild,
	}),
	{ openModal }
)(({ socialTasks, guildTasks, openModal }) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={classNames({ container: true, 'avatar-tasks': true })}>
			<MainHeader title={t('tasks.header.title')} />
			<div className={classNames({ content: true })}>
				<div className={classNames({ tasks: true })}>
					<div className={classNames({ socials: true })}>
						<span className={classNames({ title: true })}>{t('tasks.socials')}</span>
						{socialTasks.length ? (
							socialTasks.map((v, i) => (
								<div
									onClick={() => navigate('/task-info', { state: { task: v, taskType: 'social' } })}
									className={classNames({ task: true })}
									key={i}
								>
									<img
										className={classNames({ 'task-image': true })}
										src={v.image}
									/>
									<span className={classNames({ 'task-name': true })}>{v.name[i18n.languages[0]]}</span>
									<RightArrowIcon
										stroke={'#ffffff'}
										height={17}
										width={19}
									/>
								</div>
							))
						) : (
							<span className={classNames({ empty: true })}>{t('tasks.empty')}</span>
						)}
					</div>
					<div className={classNames({ guilds: true })}>
						<span className={classNames({ title: true })}>{t('tasks.guilds')}</span>
						{guildTasks.length ? (
							guildTasks.map((v, i) => (
								<div
									onClick={() => navigate('/task-info', { state: { task: v, taskType: 'guild' } })}
									className={classNames({ task: true })}
									key={i}
								>
									<img
										className={classNames({ 'task-image': true })}
										src={v.image}
									/>
									<span className={classNames({ 'task-name': true })}>{v.name[i18n.languages[0]]}</span>
									<RightArrowIcon
										stroke={'#ffffff'}
										height={17}
										width={19}
									/>
								</div>
							))
						) : (
							<span className={classNames({ empty: true })}>{t('tasks.empty')}</span>
						)}
					</div>
				</div>
			</div>
			<MainNavbar openModal={openModal} />
		</div>
	);
});
