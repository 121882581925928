import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import {
	getFoods,
	getFoodsAsync,
	cookFood,
	cookFoodAsync,
	changeFoodsField,
	changeFoodsFieldAsync,
	clearFoodsError,
	clearFoodsErrorAsync,
} from '../../actions/foods';

import foodsApi from '../../../services/api/foods';

function clearErrorWorker() {
	return {};
}

function changeFoodsFieldWorker({ name, value }) {
	return { name, value };
}

export function* foodsSaga() {
	yield takeEvery(getFoods, bindAsyncActions(getFoodsAsync)(foodsApi.getFoodsEndpoint));
	yield takeEvery(cookFood, bindAsyncActions(cookFoodAsync)(foodsApi.cookFoodEndpoint));
	yield takeEvery(changeFoodsField, bindAsyncActions(changeFoodsFieldAsync)(changeFoodsFieldWorker));
	yield takeEvery(clearFoodsError, bindAsyncActions(clearFoodsErrorAsync)(clearErrorWorker));
}
