import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('GUILD');

export const getGuildTasks = factory.create('GET_GUILD_TASKS');
export const getGuildTasksAsync = factory.createAsync('GET_GUILD_TASKS_ASYNC');

export const getReferralsCount = factory.create('GET_REFERRALS_COUNT');
export const getReferralsCountAsync = factory.createAsync('GET_REFERRALS_COUNT_ASYNC');

export const takeGuildTask = factory.create('TAKE_GUILD_TASK');
export const takeGuildTaskAsync = factory.createAsync('TAKE_GUILD_TASK_ASYNC');

export const getDailyCheckInManger = factory.create('GET_DAILY_CHECK_IN_MANAGER');
export const getDailyCheckInMangerAsync = factory.createAsync('GET_DAILY_CHECK_IN_MANAGER_ASYNC');

export const updateDailyCheckInWallet = factory.create('UPDATE_DAILY_CHECK_IN_WALLET');
export const updateDailyCheckInWalletAsync = factory.createAsync('UPDATE_DAILY_CHECK_IN_WALLET_ASYNC');

export const confirmDailyCheckIn = factory.create('CONFIRM_DAILY_CHECK_IN');
export const confirmDailyCheckInAsync = factory.createAsync('CONFIRM_DAILY_CHECK_IN_ASYNC');

export const completeDailyCheckIn = factory.create('COMPLETE_DAILY_CHECK_IN');
export const completeDailyCheckInAsync = factory.createAsync('COMPLETE_DAILY_CHECK_IN_ASYNC');

export const clearGuildError = factory.create('CLEAR_GUILD_ERROR');
export const clearGuildErrorAsync = factory.createAsync('CLEAR_GUILD_ERROR_ASYNC');
