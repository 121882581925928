import { handleActions } from 'redux-actions';

import {
	getLocationsAsync,
	getAvatarLocationAsync,
	takeJourneyAsync,
	updateLocationTargetAsync,
	clearLocationAsync,
	clearLocationsErrorAsync,
} from '../../actions/locations';

import { defaultError } from '../../../common/errors';

const initialState = {
	current: null,
	locations: [],
	error: null,
};

export default handleActions(
	{
		[getLocationsAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			locations: responseData.success ? responseData.result.locations : [],
		}),
		[getAvatarLocationAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			current: responseData.success ? responseData.result.location : null,
		}),
		[takeJourneyAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			current: responseData.success ? responseData.result.location : null,
		}),
		[updateLocationTargetAsync.success]: (s, { payload: { response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			current: response.success ? { ...s.current, target: response.result.target } : s.current,
		}),
		[clearLocationAsync.success]: (s, a) => ({
			...s,
			current: null,
		}),
		[clearLocationsErrorAsync.success]: (s, p) => ({ ...s, error: null }),

		[getLocationsAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			locations: [],
		}),
		[getAvatarLocationAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
		[takeJourneyAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
	},
	initialState
);
