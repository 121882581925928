export default (number, min, max) => {
	if (!max) {
		return Math.max(number, min) === min ? number : min;
	} else if (Math.min(number, min) === number) {
		return min;
	} else if (Math.max(number, max) === number) {
		return max;
	}

	return number;
};
