import axios from 'axios';

import { api_url } from '../../../common/config';

axios.defaults.withCredentials = true;

export default class {
	static getAvatarInfoEndpoint = () => axios.get(`${api_url}avatars/current`);
	static getAvatarGuildTasksEndpoint = () => axios.get(`${api_url}avatars/tasks/guild`);
	static getAvatarSocialTasksEndpoint = () => axios.get(`${api_url}avatars/tasks/social`);
	static getAvatarEffectsEndpoint = () => axios.get(`${api_url}avatars/effects`);
	static createAvatarEndpoint = (data) => axios.post(`${api_url}avatars/create`, data);
	static changeAvatarEquipmentEndpoint = (data) => axios.post(`${api_url}avatars/equipment`, data);
	static upgradeAvatarLevelEndpoint = (data) => axios.post(`${api_url}avatars/upgrade-level`, data);
	static upgradeAvatarSkillEndpoint = (data) => axios.post(`${api_url}avatars/upgrade-skill`, data);
	static useTaskStepEndpoint = (data) => axios.post(`${api_url}avatars/tasks/use-step`, data);
	static checkTaskStepEndpoint = (data) => axios.post(`${api_url}avatars/tasks/check-step`, data);
	static completeTaskEndpoint = (data) => axios.post(`${api_url}avatars/tasks/complete`, data);
}
