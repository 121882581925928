import './style.sass';

import classNames from 'classnames';
import React from 'react';

export default ({
	type = 'primary',
	disabled = false,
	text = '',
	onClick = () => {},
	height = 40,
	width = '100%',
	fontSize = 18,
	background = '',
	color = '',
	flex = 0,
}) => {
	return (
		<div
			className={classNames({ button: true, disabled, secondary: type.includes('secondary') })}
			style={{ minHeight: height, ...(flex ? { flex } : { minWidth: width }), ...(background ? { background } : {}) }}
			onClick={() => (disabled ? null : onClick())}
		>
			<span
				style={{ fontSize, ...(color ? { color } : {}) }}
				className={classNames({ text: true, danger: type.includes('danger') })}
			>
				{text}
			</span>
		</div>
	);
};
