import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../components/Buttons/DefaultButton';
import DefaultInput from '../../components/Inputs/DefaultInput';
import AvatarRenderer from '../../components/AvatarRenderer';

import { changeAvatarName } from './../../store/actions/createAvatar';
import { createAvatar } from './../../store/actions/avatar';

export default connect(
	(s) => ({
		appearance: s.createAvatarManager.appearance,
		wearable: s.createAvatarManager.wearable,
		gender: s.createAvatarManager.gender,
		race: s.createAvatarManager.race,
		name: s.createAvatarManager.name,
	}),
	{ changeAvatarName, createAvatar }
)(({ appearance, wearable, gender, race, name, changeAvatarName, createAvatar }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const confirmCreateAvatar = () => {
		createAvatar({ appearance, wearable, gender, race, name });
	};

	return (
		<div className={classNames({ container: true, 'create-avatar-confirm': true })}>
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'avatar-wrapper': true })}>
					<AvatarRenderer
						appearance={appearance}
						withUnderwear={true}
						withWearable={true}
						wearable={wearable}
						gender={gender}
						race={race}
					/>
				</div>
				<div className={classNames({ 'settings-wrapper': true })}>
					<div className={classNames({ 'pick-part': true })}>
						<span className={classNames({ title: true })}>{t('create-avatar-confirm.enter-avatar-name')}</span>
						<div className={classNames({ type: true })}>
							<DefaultInput
								placeholder={t('create-avatar-confirm.avatar-name')}
								onChange={(e) => changeAvatarName(e.target.value)}
								value={name}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={classNames({ 'bottom-buttons': true })}>
				<DefaultButton
					type={'secondary'}
					text={t('create-avatar-confirm.buttons.back')}
					onClick={() => navigate('/create-avatar-wearable-leggs-boots')}
				/>
				<DefaultButton
					text={t('create-avatar-confirm.buttons.confirm')}
					onClick={confirmCreateAvatar}
				/>
			</div>
		</div>
	);
});
