import './style.sass';

import classNames from 'classnames';
import React from 'react';

export default ({ title }) => {
	return (
		<div className={classNames({ 'main-header': true })}>
			<span className={classNames({ title: true })}>{title}</span>
		</div>
	);
};
