import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import LevelProgressBar from '../../components/ProgressBars/LevelProgressBar';
import CloseIcon from '../../components/Icons/CloseIcon';
import PlusIcon from '../../components/Icons/PlusIcon';

import { openModal, closeModal } from '../../store/actions/modals';
import { upgradeAvatarSkill } from '../../store/actions/avatar';

export default connect(
	(s) => ({
		modalName: s.modals.name,
		skills: s.avatarManager.state.skills,
	}),
	{ openModal, closeModal, upgradeAvatarSkill }
)(({ skills, modalName, openModal, closeModal, upgradeAvatarSkill }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (modalName == 'confirm-level-upgrade') closeModal();
	}, [skills]);

	return (
		<div className={classNames({ container: true, 'avatar-skills': true })}>
			<div className={classNames({ 'avatar-skills-header': true })}>
				<div
					className={classNames({ 'avatar-skills-header-close': true })}
					onClick={() => navigate(-1)}
				>
					<CloseIcon
						width={16}
						height={16}
						fill={'#ffffff'}
					/>
				</div>
				<span className={classNames({ 'avatar-skills-header-title': true })}>{t('avatar-skills.header.title')}</span>
			</div>
			<div className={classNames({ content: true })}>
				<div className={classNames({ skills: true })}>
					<div className={classNames({ skill: true })}>
						<LevelProgressBar
							maximum={skills.stamina.experience.maximum}
							current={skills.stamina.experience.current}
							name={t('avatar-skills.stamina')}
							level={skills.stamina.level}
							frontColor={'#3399FF'}
							backColor={'#0058AF'}
						/>
						{skills.stamina.experience.current >= skills.stamina.experience.maximum ? (
							<div
								className={classNames({ plus: true })}
								onClick={() =>
									openModal({
										name: 'confirm-level-upgrade',
										payload: {
											type: 'center',
											skill: 'stamina',
											price: Math.round(skills.stamina.experience.maximum * 0.2),
											onConfirm: () => upgradeAvatarSkill({ skill: 'stamina' }),
										},
									})
								}
							>
								<PlusIcon
									width={24}
									height={24}
									fill={'#3399FF'}
								/>
							</div>
						) : null}
					</div>
					<div className={classNames({ skill: true })}>
						<LevelProgressBar
							maximum={skills.combat.experience.maximum}
							current={skills.combat.experience.current}
							name={t('avatar-skills.combat')}
							level={skills.combat.level}
							frontColor={'#FF3300'}
							backColor={'#821A00'}
						/>
						{skills.combat.experience.current >= skills.combat.experience.maximum ? (
							<div
								className={classNames({ plus: true })}
								onClick={() =>
									openModal({
										name: 'confirm-level-upgrade',
										payload: {
											type: 'center',
											skill: 'combat',
											price: Math.round(skills.combat.experience.maximum * 0.2),
											onConfirm: () => upgradeAvatarSkill({ skill: 'combat' }),
										},
									})
								}
							>
								<PlusIcon
									width={24}
									height={24}
									fill={'#FF3300'}
								/>
							</div>
						) : null}
					</div>
					<div className={classNames({ skill: true })}>
						<LevelProgressBar
							maximum={skills.magic.experience.maximum}
							current={skills.magic.experience.current}
							name={t('avatar-skills.magic')}
							level={skills.magic.level}
							frontColor={'#CC00CC'}
							backColor={'#990099'}
						/>
						{skills.magic.experience.current >= skills.magic.experience.maximum ? (
							<div
								className={classNames({ plus: true })}
								onClick={() =>
									openModal({
										name: 'confirm-level-upgrade',
										payload: {
											type: 'center',
											skill: 'magic',
											price: Math.round(skills.magic.experience.maximum * 0.2),
											onConfirm: () => upgradeAvatarSkill({ skill: 'magic' }),
										},
									})
								}
							>
								<PlusIcon
									width={24}
									height={24}
									fill={'#CC00CC'}
								/>
							</div>
						) : null}
					</div>
					<div className={classNames({ skill: true })}>
						<LevelProgressBar
							maximum={skills.extracting.experience.maximum}
							current={skills.extracting.experience.current}
							name={t('avatar-skills.extracting')}
							level={skills.extracting.level}
							frontColor={'#7F432B'}
							backColor={'#521D08'}
						/>
						{skills.extracting.experience.current >= skills.extracting.experience.maximum ? (
							<div
								className={classNames({ plus: true })}
								onClick={() =>
									openModal({
										name: 'confirm-level-upgrade',
										payload: {
											type: 'center',
											skill: 'extracting',
											price: Math.round(skills.extracting.experience.maximum * 0.2),
											onConfirm: () => upgradeAvatarSkill({ skill: 'extracting' }),
										},
									})
								}
							>
								<PlusIcon
									width={24}
									height={24}
									fill={'#7F432B'}
								/>
							</div>
						) : null}
					</div>
					<div className={classNames({ skill: true })}>
						<LevelProgressBar
							maximum={skills.crafting.experience.maximum}
							current={skills.crafting.experience.current}
							name={t('avatar-skills.crafting')}
							level={skills.crafting.level}
							frontColor={'#FF9999'}
							backColor={'#A15252'}
						/>
						{skills.crafting.experience.current >= skills.crafting.experience.maximum ? (
							<div
								className={classNames({ plus: true })}
								onClick={() =>
									openModal({
										name: 'confirm-level-upgrade',
										payload: {
											type: 'center',
											skill: 'crafting',
											price: Math.round(skills.crafting.experience.maximum * 0.2),
											onConfirm: () => upgradeAvatarSkill({ skill: 'crafting' }),
										},
									})
								}
							>
								<PlusIcon
									width={24}
									height={24}
									fill={'#FF9999'}
								/>
							</div>
						) : null}
					</div>
					<div className={classNames({ skill: true })}>
						<LevelProgressBar
							maximum={skills.cooking.experience.maximum}
							current={skills.cooking.experience.current}
							name={t('avatar-skills.cooking')}
							level={skills.cooking.level}
							frontColor={'#CCCCFF'}
							backColor={'#7C7CA6'}
						/>
						{skills.cooking.experience.current >= skills.cooking.experience.maximum ? (
							<div
								className={classNames({ plus: true })}
								onClick={() =>
									openModal({
										name: 'confirm-level-upgrade',
										payload: {
											type: 'center',
											skill: 'cooking',
											price: Math.round(skills.cooking.experience.maximum * 0.2),
											onConfirm: () => upgradeAvatarSkill({ skill: 'cooking' }),
										},
									})
								}
							>
								<PlusIcon
									width={24}
									height={24}
									fill={'#FF9999'}
								/>
							</div>
						) : null}
					</div>
					<div className={classNames({ skill: true })}>
						<LevelProgressBar
							maximum={skills.potionery.experience.maximum}
							current={skills.potionery.experience.current}
							name={t('avatar-skills.potionery')}
							level={skills.potionery.level}
							frontColor={'#CC00CC'}
							backColor={'#870387'}
						/>
						{skills.potionery.experience.current >= skills.potionery.experience.maximum ? (
							<div
								className={classNames({ plus: true })}
								onClick={() =>
									openModal({
										name: 'confirm-level-upgrade',
										payload: {
											type: 'center',
											skill: 'potionery',
											price: Math.round(skills.potionery.experience.maximum * 0.2),
											onConfirm: () => upgradeAvatarSkill({ skill: 'potionery' }),
										},
									})
								}
							>
								<PlusIcon
									width={24}
									height={24}
									fill={'#FF9999'}
								/>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
});
