import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import {
	getGuildTasks,
	getGuildTasksAsync,
	getReferralsCount,
	getReferralsCountAsync,
	takeGuildTask,
	takeGuildTaskAsync,
	getDailyCheckInManger,
	getDailyCheckInMangerAsync,
	updateDailyCheckInWallet,
	updateDailyCheckInWalletAsync,
	confirmDailyCheckIn,
	confirmDailyCheckInAsync,
	completeDailyCheckIn,
	completeDailyCheckInAsync,
	clearGuildError,
	clearGuildErrorAsync,
} from '../../actions/guild';

import guildApi from '../../../services/api/guild';

function clearErrorWorker() {
	return {};
}

function completeDailyCheckInWorker() {
	return {};
}

export function* guildSaga() {
	yield takeEvery(getGuildTasks, bindAsyncActions(getGuildTasksAsync)(guildApi.getGuildTasksEndpoint));
	yield takeEvery(getReferralsCount, bindAsyncActions(getReferralsCountAsync)(guildApi.getReferralsCountEndpoint));
	yield takeEvery(takeGuildTask, bindAsyncActions(takeGuildTaskAsync)(guildApi.takeGuildTaskEndpoint));
	yield takeEvery(getDailyCheckInManger, bindAsyncActions(getDailyCheckInMangerAsync)(guildApi.getDailyCheckInEndpoint));
	yield takeEvery(
		updateDailyCheckInWallet,
		bindAsyncActions(updateDailyCheckInWalletAsync)(guildApi.updateDailyCheckInWalletEndpoint)
	);
	yield takeEvery(confirmDailyCheckIn, bindAsyncActions(confirmDailyCheckInAsync)(guildApi.confirmDailyCheckInEndpoint));
	yield takeEvery(completeDailyCheckIn, bindAsyncActions(completeDailyCheckInAsync)(completeDailyCheckInWorker));
	yield takeEvery(clearGuildError, bindAsyncActions(clearGuildErrorAsync)(clearErrorWorker));
}
